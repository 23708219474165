import React, { useEffect, useState } from "react";
import BlogForm from "../Components/Blog/BlogForm";
import BlogList from "../Components/Blog/BlogList";
import SearchTree from "../Components/Layout/SearchTree";
import { Divider, Message, toaster } from "rsuite";
import blogPostApi from "../services/BlogPostApi";
import { useLocation } from "react-router-dom";
import DeleteModal from "../Components/Layout/DeleteModal";
import userApi from "../services/UserApi";

function Blog(props) {
  const navigation = useLocation();
  let currentUser;
  const practiceId = localStorage.getItem("practiceId");
  const role = localStorage.getItem("role");
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedBlogPost, setSelectedBlogPost] = useState(null);
  const [blogPostList, setBlogPostList] = useState(null);
  const [firstTime, setFirstTime] = useState(false);
  let temp = [];
  let temp2 = [];
  const handleClose = () => setOpen(false);

  let counter = 0;

  async function getBlogData() {
    currentUser = await userApi.apiUserCurrentGet();
    setLoading(true);
    setLoading(true);
    const blogData = await blogPostApi.apiBlogPostGet();

    for (let i = 0; i < blogData.data.data.length; i++) {
      if (role == 3) {
        temp.push({
          label: blogData.data.data[i].headline,
          value: counter,
          parent_id: blogData.data.data[i].id,
          text: blogData.data.data[i].text,
          practiceId: blogData.data.data[i].practiceId,
        });
        counter++;
      } else if (currentUser.data.id == blogData.data.data[i].authorId) {
        temp.push({
          label: blogData.data.data[i].headline,
          value: counter,
          parent_id: blogData.data.data[i].id,
          text: blogData.data.data[i].text,
          practiceId: blogData.data.data[i].practiceId,
        });
        counter++;
      }
    }
    setLoading(false);
    setSelectedBlogPost(null);
    setBlogPostList(temp);
  }
  useEffect(() => {
    getBlogData();
  }, [navigation.state.from]);

  function onClean() {
    setFirstTime(true);
    setSelectedBlogPost(null);
    getBlogData();
  }

  async function onEdit(data, id) {
    console.log(data);

    const dataObject = {
      backgroundImageUrl: data.backgroundImageUrl,
      headline: data.headline,
      isGlobal: data.isGlobal,
      practiceId: data.practiceId,
      publishedEnd: data.publishedEnd,
      publishedStart: data.publishedStart,
      teaser: data.teaser,
      text: data.text,
    };

    if (data.publishStartPG) {
      dataObject.publishedStart = data.publishStartPG;
    }

    if (data.publishEndPG) {
      dataObject.publishedEnd = data.publishEndPG;
    }

    const u = await blogPostApi.apiBlogPostIdPut(id, dataObject);
    if (u.status == 200) {
      toaster.push(
        <Message showIcon type="success">
          Blog Post erfolgreich editiert.
        </Message>,
        "topCenter"
      );

      getBlogData();
    }
  }

  async function onSubmitNew(data) {
    const c = await blogPostApi.apiBlogPostPost(data);
    if (c.status == 201) {
      toaster.push(
        <Message showIcon type="success">
          Blog Post erfolgreich angelegt.
        </Message>,
        "topCenter"
      );
      getBlogData();
    }
  }

  function onDelete(id, name) {
    async function deleteBlogPost() {
      var d = await blogPostApi.apiBlogPostIdDelete(id);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Blog Post erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
        handleClose();
        getBlogData();
      }
    }
    setOpen(true);
    let text =
      "Sind Sie sich sicher, dass sie den Blog Post " +
      name +
      " löschen möchten?";
    setModal(
      <DeleteModal
        header="Blog Post löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={deleteBlogPost}
      ></DeleteModal>
    );
  }

  async function onSelect(id) {
    const blogPostData = await blogPostApi.apiBlogPostIdGet(id);
    console.log(blogPostData);
    setFirstTime(false);
    setSelectedBlogPost(blogPostData.data);
  }
  function onCreateNew() {
    setFirstTime(false);
    setSelectedBlogPost(0);
  }

  function onChangeHandler(activeNode, value, event) {
    onSelect(activeNode.parent_id);
  }
  return (
    <React.Fragment>
      <Divider></Divider>
      {blogPostList && (
        <SearchTree
          selectedItem={selectedBlogPost}
          dataList={blogPostList}
          selectedList={blogPostList}
          onChangeHandler={onChangeHandler}
          onCreateNew={onCreateNew}
          onClean={onClean}
          firstTime={firstTime}
          mode="parent"
          canEdit={true}
        ></SearchTree>
      )}
      {blogPostList && selectedBlogPost == null && (
        <BlogList
          loading={loading}
          onSelect={(data) => onSelect(data)}
          onDelete={(data, name) => onDelete(data, name)}
          data={blogPostList}
        ></BlogList>
      )}
      {selectedBlogPost !== null && (
        <BlogForm
          blogPost={selectedBlogPost}
          onSubmitNew={(data) => onSubmitNew(data)}
          onEdit={(data, id) => onEdit(data, id)}
          onCancel={onClean}
        ></BlogForm>
      )}
      {open && modal}
    </React.Fragment>
  );
}
export default Blog;

import { useEffect, useRef, useState } from "react";
import blogPostApi from "../../services/BlogPostApi";
import {
  Button,
  ButtonToolbar,
  Checkbox,
  DatePicker,
  Form,
  Schema,
  SelectPicker,
  Stack,
  Uploader,
} from "rsuite";
import { getOffset } from "rsuite/esm/DOMHelper";

import practiceApi from "../../services/PracticeApi";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
var Font = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ["arial", "roboto", "raleway", "montserrat", "lato", "rubik"];
Quill.register(Font, true);

const fontSizeArr = [
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
var Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const modules = {
  toolbar: [
    [{ size: fontSizeArr }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "video"],
    ["clean"],
  ],
};

function BlogForm(props) {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const practiceId = localStorage.getItem("practiceId");
  const formRef = useRef();
  const nodeRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    headline: "",
    teaser: "",
    text: "",
    backgroundImageUrl: "",
    publishedStart: "",
    publishedEnd: "",
    isGlobal: false,
    practiceId: "",
    authorId: currentUser?.uid,
  });
  const [value, setValue] = useState(
    props.blogPost == 0 ? "" : props.blogPost.text
  );
  const [editorState, setEditorState] = useState();
  /*  props.blogPost == 0
      ? EditorState.createEmpty()
      : EditorState.createWithContent(getContentState())*/
  /*
  function getContentState() {
    //return JSON.parse('{"entityMap":{},"blockMap":{"67bk2":{"key":"67bk2","type":"unstyled","text":"hallo du arschloch","characterList":[{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null},{"style":[],"entity":null}],"depth":0,"data":{}}},"selectionBefore":{"anchorKey":"67bk2","anchorOffset":0,"focusKey":"67bk2","focusOffset":18,"isBackward":false,"hasFocus":true},"selectionAfter":{"anchorKey":"67bk2","anchorOffset":18,"focusKey":"67bk2","focusOffset":18,"isBackward":false,"hasFocus":true}}')

    const blocksFromHTML = convertFromHTML(props.blogPost.text);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return state;
  }*/
  const [practiceList, setPracticeList] = useState([]);
  const [convertedContent, setConvertedContent] = useState(null);
  const role = JSON.parse(localStorage.getItem("role"));
  let firstRender = 1;
  const model = Schema.Model({
    headline: Schema.Types.StringType().isRequired(
      "Überschrift ist ein Pflichtfeld"
    ),
    text: Schema.Types.StringType().isRequired("Blogtext ist ein Pflichtfeld"),
    backgroundImageUrl: Schema.Types.StringType(),
    practiceId: Schema.Types.StringType(),
  });

  useEffect(() => {
    if (props.blogPost === 0) {
      setFormValue({
        headline: "",
        text: "",
        teaser: "",
        backgroundImageUrl: "",
        publishedStart: "",
        publishedEnd: "",

        isGlobal: false,
        practiceId: role == 3 ? "" : practiceId,
        authorId: currentUser?.uid,
      });
    } else {
      let pubStart = "";
      let pubEnd = "";
      if (props.blogPost.publishedStart) {
        pubStart = props.blogPost.publishedStart.substring(0, 10);
      }

      if (props.blogPost.publishedEnd) {
        pubEnd = props.blogPost.publishedEnd.substring(0, 10);
      }

      setFormValue({
        headline: props.blogPost.headline,
        text: props.blogPost.text,
        backgroundImageUrl: props.blogPost.backgroundImageUrl,
        publishedStart: pubStart,
        teaser: props.blogPost.teaser,
        publishedEnd: pubEnd,

        isGlobal: props.blogPost.isGlobal,
        practiceId: props.blogPost.practiceId,
        authorId: currentUser?.uid,
      });
    }
  }, [props]);

  function onSubmitHandler(e) {
    if (!formRef.current.check()) {
      //    alert("submit");
      console.error("Form Error");
      return;
    } else {

      generateVideo(formValue.text);

      if (props.blogPost == 0) {
        props.onSubmitNew(formValue);
      } else {
        console.log(formValue);
        props.onEdit(formValue, props.blogPost.id);
      }
    }
  }
function generateVideo(asd) {
    let videoUrl = asd.split("<iframe");
    if (videoUrl.length > 1) {
      asd = "";
      for (let i = 0; i < videoUrl.length; i++) {
        if (videoUrl[i].indexOf("<") != 0) {
          videoUrl[i] =
            '<video preload="none" controls ' +
            videoUrl[i].split("iframe").join("video");

          let posterurltemp = videoUrl[i].split("src=");

          let posterurl = posterurltemp[1].split(">");

          videoUrl[i] = videoUrl[i]
            .split("<video ")
            .join(
              "<video  poster=" +
                posterurl[0]
                  .split("/uploads")
                  .join("/uploads/thumbs/")
                  .split('.mp4"')
                  .join('_1080.jpg" ')
            )
            .split('frameborder="0"')
            .join("")
            .split('allowfullscreen="true"')
            .join("");
        }
        asd += videoUrl[i];
      }
    } else {
      // alert("no vidoe");
    }
    formValue.text = asd;
  }
  useEffect(() => {
    async function getPracticeData() {
      const practiceData = await practiceApi.apiPracticeGet();

      var temp = [];
      for (var i = 0; i < practiceData.data.data.length; i++) {
        temp.push({
          value: practiceData.data.data[i].id,
          label: practiceData.data.data[i].name,
        });
      }
      setPracticeList(temp);
    }
    getPracticeData();
  }, []);

  function onSuccessHandler(e) {
    setFormValue({ ...formValue, backgroundImageUrl: e.fileUrl });
  }

  useEffect(() => console.log(formValue), [formValue]);
  console.log(formValue);
  return (
    <div
      ref={nodeRef}
      style={{
        width: "100%",
        height: window.innerHeight - getOffset(nodeRef.current).top,
        overflow: "auto",
        backgroundColor: "#F3F8F8",
      }}
    >
      <Form
        model={model}
        onCheck={setFormError}
        ref={formRef}
        onChange={setFormValue}
        formValue={formValue}
        fluid
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          marginLeft: "25%",
          marginRight: "25%",
        }}
      >
        <Form.Group controlId="headline">
          <Form.ControlLabel>Überschrift</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="headline"
            value={formValue.headline}
          />
        </Form.Group>
        <Form.Group controlId="teaser">
          <Form.ControlLabel>Teaser</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="teaser"
            value={formValue.teaser}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Blogtext (Freitexteingabe mit Formatierungsoption)
          </Form.ControlLabel>
          <ReactQuill
            theme="snow"
            modules={modules}
            value={formValue.text}
            onChange={(asd) => {
              if (firstRender == 1) {
                firstRender = 0;
              } else {
                console.log(asd);
                setFormValue({ ...formValue, text: asd });
                setValue(asd);
              }
            }}
          />
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>Bild zum Blogpost</Form.ControlLabel>
          <Form.Control
            accepter={Uploader}
            accept="image/png, image/jpeg"
            action={"https://physiodev.salestv.de/uploadBlogPostImage"}
            // action={"http://localhost:8888/uploadBlogPostImage"}
            onSuccess={onSuccessHandler}
          />
        </Form.Group>
        <Stack>
          {formValue.publishedStart !== undefined && (
            <Form.Group controlId="publishedStart" style={{ width: "250px" }}>
              <Form.ControlLabel>Blogpsot Startdatum</Form.ControlLabel>
              <Form.Control
                accepter={DatePicker}
                cleanable
                autoComplete="off"
                name="publishedStart"
                value={
                  formValue.publishedStart == ""
                    ? null
                    : new Date(formValue.publishedStart)
                }
                placement="auto"
                isoWeek
                oneTap
                style={{ width: "200px" }}
                placeholder={"Veröffentlichungsdatum"}
                format="yyyy-MM-dd"
                onClean={(event) =>
                  setFormValue({ ...formValue, publishedStart: "" })
                }
                onChange={(data) => {
                  console.log(data);
                  let datumFull = new Date(data);
                  datumFull.setHours(0, 0, 0, 0);

                  const year = datumFull.getFullYear();
                  let month = datumFull.getMonth() + 1;
                  if (month.toString().length === 1) {
                    month = "0" + month;
                  }
                  let date = datumFull.getDate();
                  if (date.toString().length === 1) {
                    date = "0" + date;
                  }
                  const pgDate =
                    year + "-" + month + "-" + date + "T00:00:00+00";
                  setFormValue((prevState) => ({
                    ...prevState,
                    publishStartPG: pgDate,
                  }));
                }}
              />
            </Form.Group>
          )}
          {formValue.publishedEnd !== undefined && (
            <Form.Group controlId="publishedEnd" style={{ width: "250px" }}>
              <Form.ControlLabel>Blogpsot Enddatum</Form.ControlLabel>
              <Form.Control
                accepter={DatePicker}
                autoComplete="off"
                cleanable
                name="publishedEnd"
                value={
                  formValue.publishedEnd == ""
                    ? null
                    : new Date(formValue.publishedEnd)
                }
                isoWeek
                placement="auto"
                oneTap
                placeholder={"Enddatum"}
                format="yyyy-MM-dd"
                style={{ width: "200px" }}
                onClean={(event) =>
                  setFormValue({ ...formValue, publishedEnd: "" })
                }
                onChange={(data) => {
                  let datumFull = new Date(data);
                  datumFull.setHours(0, 0, 0, 0);

                  const year = datumFull.getFullYear();
                  let month = datumFull.getMonth() + 1;
                  if (month.toString().length === 1) {
                    month = "0" + month;
                  }
                  let date = datumFull.getDate();
                  if (date.toString().length === 1) {
                    date = "0" + date;
                  }
                  const pgDate =
                    year + "-" + month + "-" + date + "T00:00:00+00";
                  console.log(pgDate);

                  setFormValue((prevState) => ({
                    ...prevState,
                    publishEndPG: pgDate,
                  }));
                }}
              />
            </Form.Group>
          )}
        </Stack>
        {role === 3 && (
          <>
            <Form.Group controlId="isGlobal">
              <Form.ControlLabel>Globaler Blog Post</Form.ControlLabel>
              <Form.Control
                autoComplete="off"
                name="isGlobal"
                accepter={Checkbox}
                onChange={(e) => {
                  formValue.isGlobal == true
                    ? setFormValue({ ...formValue, isGlobal: false })
                    : setFormValue({ ...formValue, isGlobal: true });
                }}
                value={formValue.isGlobal}
                checked={formValue.isGlobal}
              />
            </Form.Group>

            <Form.Group controlId="practiceId">
              <Form.ControlLabel>Stanndortzugehörigkeit</Form.ControlLabel>
              <Form.Control
                name="practiceId"
                accepter={SelectPicker}
                searchable={false}
                data={practiceList}
                value={formValue.practiceId}
                cleanable={false}
                placement="top"
              />
            </Form.Group>
          </>
        )}
        <Form.Group>
          <ButtonToolbar style={{marginTop:16}}>
            <Button
              appearance="primary"
              color="green"
              type="submit"
              onClick={onSubmitHandler}
            >
              Speichern
            </Button>
            <Button appearance="primary" color="red" onClick={props.onCancel}>
              Abbrechen
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
}

export default BlogForm;

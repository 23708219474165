import { useEffect, useState } from "react";
import { Nav } from "rsuite";
import UserForm from "./UserForm";
import UserDocuments from "./UserDocuments";

function UserNavigation(props) {
  const [active, setActive] = useState("stammdaten");

  const CustomNav = ({ active, onSelect, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="stammdaten">Persöhnliche Daten</Nav.Item>
        {props.user !== 0 && (
          <Nav.Item eventKey="documents">Dokumente</Nav.Item>
        )}
      </Nav>
    );
  };

  useEffect(() => {
    if (active == "documents" && props.user != 0) {
      props.showAddB(true);
    } else {
      props.showAddB(false);
    }
  }, [active]);

  return (
    <>
      <CustomNav
        style={{ marginTop: "8px" }}
        appearance="tabs"
        active={active}
        onSelect={setActive}
      />
      {active == "stammdaten" && (
        <UserForm
          onCancel={props.onCancel}
          currentUser={props.currentUser}
          user={props.user}
          onSubmitNew={(data, perms) => props.onSubmitNew(data, perms)}
          onEdit={(data, userId, perms) => props.onEdit(data, userId, perms)}
        ></UserForm>
      )}
      {active == "documents" && (
        <UserDocuments
          user={props.user}
          openWithHeader={props.openWithHeader}
          onCloseModal={props.onCloseModal}
        ></UserDocuments>
      )}
    </>
  );
}
export default UserNavigation;

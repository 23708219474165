import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { Redirect } from "react-router-dom";
//import { Formik, Field, Form, ErrorMessage } from "formik";
import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";
import { Message, Panel, toaster } from "rsuite";
import { Form, Button, Divider, Checkbox, Schema } from "rsuite";
import { Navigate, useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
import createToast from "../Components/Layout/Toast";
const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const formRef = useRef();
  const formRef2 = useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    username: "",
    password: "",
  });
  const [username, setUsername] = useState();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const model = Schema.Model({
    username: Schema.Types.StringType().isRequired(
      "Der Benutzername ist ein Pflichtfeld"
    ),
    password: Schema.Types.StringType().isRequired(
      "Das Passwort ist ein Pflichtfeld"
    ),
  });
  const model2 = Schema.Model({
    username: Schema.Types.StringType().isRequired(
      "Der Benutzername ist ein Pflichtfeld"
    ),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleLogin = () => {
    const { username, password } = formValue;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function forgotPassword() {
    setShowForgotPassword(true);
  }

  async function handlePasswordReset() {
    const reset = await authService.resetPasswort(username);
    createToast("Es wurde eine Nachricht an die Eingetragene Email gesendet.");
    setShowForgotPassword(false);
  }
  /*
  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }
*/
  return (
    <>
      {!showForgotPassword && (
        <Panel>
          <div
            style={{
              width: "25%",
              height: "100%",
              overflow: "hidden",
              margin: "auto",
            }}
          >
            <div>
              <img
                src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                alt="profile-img"
                className="profile-img-card"
              />

              <Form
                model={model}
                onCheck={setFormError}
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                fluid
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  marginLeft: "25%",
                  marginRight: "25%",
                }}
              >
                <Form.Group controlId="username">
                  <Form.ControlLabel>Benutzername</Form.ControlLabel>
                  <Form.Control
                    autoComplete="off"
                    name="username"
                    value={formValue.username}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.ControlLabel>Passwort</Form.ControlLabel>
                  <Form.Control
                    autoComplete="off"
                    name="password"
                    value={formValue.password}
                    type="password"
                  />
                </Form.Group>
                <Form.Group>
                  <Button
                    appearance="primary"
                    block
                    onClick={handleLogin}
                    type="submit"
                  >
                    Einloggen
                  </Button>
                  <Button
                    appearance="primary"
                    block
                    onClick={forgotPassword}
                    color="red"
                  >
                    Passwort vergessen
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Panel>
      )}
      {showForgotPassword && (
        <Panel>
          <div
            style={{
              width: "25%",
              height: "100%",
              overflow: "hidden",
              margin: "auto",
            }}
          >
            <Form
              model={model2}
              ref={formRef2}
              fluid
              style={{
                marginTop: "25px",
                marginBottom: "25px",
                marginLeft: "25%",
                marginRight: "25%",
              }}
            >
              <Form.Group controlId="username">
                <Form.ControlLabel>Benutzername</Form.ControlLabel>
                <Form.Control
                  autoComplete="off"
                  name="username"
                  value={username}
                  onChange={setUsername}
                />
              </Form.Group>
              <Form.Group>
                <Button
                  appearance="primary"
                  block
                  onClick={handlePasswordReset}
                >
                  Passwort zurücksetzen
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Panel>
      )}
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Drawer,
  Input,
  InputGroup,
  Message,
  toaster,
} from "rsuite";
import CoachingplanAddDialog from "../Components/Coachingplan/CoachingplanAddDialog";
import CoachingplanForm from "../Components/Coachingplan/CoachingplanForm";
import CouchingplanList from "../Components/Coachingplan/CouchingplanList";
import DeleteModal from "../Components/Layout/DeleteModal";
import SearchTree from "../Components/Layout/SearchTree";
import createToast from "../Components/Layout/Toast";
import coachingPlanApi from "../services/CoachingPlanApi";
import coachingPlanExerciseApi from "../services/CoachingPlanExerciseApi";

function Coachingplan(props) {
  const practiceId = localStorage.getItem("practiceId");
  const role = localStorage.getItem("role");
  const navigation = useLocation();
  const [firstTime, setFirstTime] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const [showDel, setShowDel] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [drawerRdy, setDrawerRdy] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [sendAddRequest, setSendAddRequest] = useState(false);

  let temp = [];

  function onClean() {
    setSelectedPlan(null);
    setFirstTime(true);
    getPlanData();
  }

  async function onEdit(data, id) {
    const update = await coachingPlanApi.apiCoachingPlanIdPut(id, data);
    if (update.status == 200) {
      toaster.push(
        <Message showIcon type="success">
          Coachingplan erfolgreich editiert.
        </Message>,
        "topCenter"
      );
      getPlanData();
    }
    console.log(update);
  }
  async function onSubmitNew(data) {
    const create = await coachingPlanApi.apiCoachingPlanPost(data);
    if (create.status == 201) {
      toaster.push(
        <Message showIcon type="success">
          Coachingplan erfolgreich erstellt.
        </Message>,
        "topCenter"
      );
      getPlanData();
    }
  }
  function onChangeHandler(e) {
    onSelect(e.parent_id);
  }
  async function onDelete(id, name) {
    async function deletePlan() {
      var d = await coachingPlanApi.apiCoachingPlanIdDelete(id);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Coachingplan erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
        handleClose();
        getPlanData();
      }
    }
    setOpen(true);
    let text =
      "Sind Sie sich sicher, dass sie den Coachingplan " +
      name +
      " löschen möchten?";
    setModal(
      <DeleteModal
        header="Coachingplan löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={deletePlan}
      ></DeleteModal>
    );
  }
  async function onSelect(id) {
    const plan = await coachingPlanApi.apiCoachingPlanIdGet(id);
    setSelectedPlan(plan.data);

    setFirstTime(false);
    // setPlanList(null);
  }
  async function handleOnDelete() {
    console.log(toDelete);
    let counter = 0;
    for (var i = 0; i < toDelete.length; i++) {
      const d = await coachingPlanExerciseApi.apiCoachingPlanExerciseIdDelete(
        toDelete[i]
      );
      if (d.status === 200) {
        counter++;
        if (counter == toDelete.length) {
          createToast("Übungen erfolgreich entfernt.");
          setToDelete([]);
          setShowDel(false);
          onSelect(selectedPlan.id);
        }
      }
    }
  }
  function handleOnAdd(e) {
    setOpenWithHeader(true);
  }
  function onCreateNew() {
    setSelectedPlan(0);
  }
  async function onDeleteSingle(id) {
    const d = await coachingPlanExerciseApi.apiCoachingPlanExerciseIdDelete(id);
  }
  async function getPlanData() {
    setLoading(true);

    var counter = 0;
    const planData = await coachingPlanApi.apiCoachingPlanGet();

    for (let i = 0; i < planData.data.data.length; i++) {
      if (role == 3) {
        temp.push({
          label: planData.data.data[i].title,
          value: counter,
          parent_id: planData.data.data[i].id,
          text: planData.data.data[i].title,
          exercises: planData.data.data[i].coachingPlanExercises.length,
        });
        counter++;
      } else if (practiceId == planData.data.data[i].practiceId) {
        temp.push({
          label: planData.data.data[i].title,
          value: counter,
          parent_id: planData.data.data[i].id,
          text: planData.data.data[i].title,
          exercises: planData.data.data[i].coachingPlanExercises.length,
        });
        counter++;
      }
    }
    setLoading(false);
    setPlanList(temp);
    setSelectedPlan(null);
  }

  useEffect(() => {
    getPlanData();
  }, [navigation.state.from]);

  function onHandleCheck(e) {
    e.length > 0 ? setShowDel(true) : setShowDel(false);
    setToDelete(e);
  }
  function cleanup() {
    setDrawerRdy(false);
    setSendAddRequest(false);
  }

  function handleDrawClose() {
    //setSearcher("");
    setShowConfirm(false);
    setOpenWithHeader(false);
    onSelect(selectedPlan.id);
  }
  function handleCheck(addData) {
    if (addData.length > 0) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }

  async function onCopyEntry(rowData) {
    const copy = await coachingPlanExerciseApi.apiCoachingPlanExercisePost({
      coachingPlanId: rowData.coachingPlanId,
      exerciseId: rowData.exerciseId,
      order: 99,
      iterations: rowData.iterations,
      durationInSeconds: rowData.durationInSeconds,
      pauseInSeconds: rowData.pauseInSeconds,
      repetitions: rowData.repetitions,
      sets: rowData.sets,
    });

    console.log(copy.status);
    if (copy.status === 201) {
      createToast("Übung kopiert.");
      onSelect(selectedPlan.id);
    }
  }

  return (
    <React.Fragment>
      <Divider></Divider>
      {
        <SearchTree
          selectedItem={selectedPlan}
          dataList={planList}
          selectedList={planList}
          onChangeHandler={onChangeHandler}
          onCreateNew={onCreateNew}
          onClean={onClean}
          firstTime={firstTime}
          mode="parent"
          canEdit={true}
          showDel={showDel}
          showAdd={showAdd}
          onDelete={handleOnDelete}
          onAddClick={handleOnAdd}
        ></SearchTree>
      }
      {planList && selectedPlan == null && (
        <CouchingplanList
          loading={loading}
          onSelect={(data) => onSelect(data)}
          onDelete={(data, name) => onDelete(data, name)}
          data={planList}
        ></CouchingplanList>
      )}
      {selectedPlan !== null && (
        <CoachingplanForm
          coachingplan={selectedPlan}
          showAddB={(value) => setShowAdd(value)}
          onSubmitNew={(data) => onSubmitNew(data)}
          onEdit={(data, id) => onEdit(data, id)}
          onCancel={onClean}
          onDeleteSingle={(id) => onDeleteSingle(id)}
          onCopyEntry={(rowData) => onCopyEntry(rowData)}
          onHandleCheck={(keys) => onHandleCheck(keys)}
        ></CoachingplanForm>
      )}
      {open && modal}
      <Drawer
        size="md"
        open={openWithHeader}
        placement={"right"}
        onClose={() => setOpenWithHeader(false)}
        onEntered={() => setDrawerRdy(true)}
      >
        <Drawer.Header>
          <Drawer.Title>Eintrag hinzufügen</Drawer.Title>

          <Drawer.Actions style={{ display: "inline-block" }}>
            {/*  <InputGroup
              style={{ width: 300, marginBottom: 10, display: "inline-block" }}
            >
              <Input placeholder="Suche" onChange={onSearchChangeHandler} />
      </InputGroup>{" "}*/}
            {showConfirm && (
              <Button
                style={{ display: "inline-block" }}
                onClick={() => setSendAddRequest(true)}
                appearance="primary"
              >
                Hinzufügen
              </Button>
            )}
          </Drawer.Actions>
        </Drawer.Header>

        <Drawer.Body style={{ overflow: "hidden" }}>
          {drawerRdy && (
            <CoachingplanAddDialog
              handleCheckUpper={(data) => handleCheck(data)}
              handleDrawClose={handleDrawClose}
              cleanupp={cleanup}
              send={sendAddRequest}
              plan={selectedPlan}
              //searcher={searcher}
            ></CoachingplanAddDialog>
          )}
        </Drawer.Body>
      </Drawer>{" "}
    </React.Fragment>
  );
}
export default Coachingplan;

import {
  Button,
  Divider,
  FlexboxGrid,
  IconButton,
  Message,
  toaster,
} from "rsuite";
import startTileApi from "../../services/StartTile";
import { useEffect, useRef, useState } from "react";
import { getOffset } from "rsuite/esm/DOMHelper";
import StartTileEditDrawer from "./StartTileEditDrawer";
import { StartPageTileSlot } from "../../openapi";
import TrashIcon from "@rsuite/icons/Trash";
import DeleteModal from "../Layout/DeleteModal";

let deleteTtile = 0;
function LocationStartPage(props) {
  const [tileData, setTileData] = useState();
  const [tileAssignment, setTileAssignment] = useState();
  const [selectedTile, setSelectedTile] = useState();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const nodeRef = useRef();

  const handleClose = () => setOpen(false);
  async function getStartPageData() {
    const tempTileData = [];

    const data = await startTileApi.apiStartPageTileGet();
    for (let i = 0; i < data.data.data.length; i++) {
      if (data.data.data[i].practiceId === props.location.id) {
        tempTileData.push(data.data.data[i]);
      }
    }
    let switcher = 0;
    let switcherData = [];
    for (let i = 0; i < tempTileData.length; i++) {
      if (tempTileData[i].tileSlot == "Primary") {
        switcherData.push(tempTileData[i]);
      }
    }
    for (let i = 0; i < tempTileData.length; i++) {
      if (tempTileData[i].tileSlot == "TopLeft") {
        switcherData.push(tempTileData[i]);
      }
    }
    for (let i = 0; i < tempTileData.length; i++) {
      if (tempTileData[i].tileSlot == "TopRight") {
        switcherData.push(tempTileData[i]);
      }
    }
    for (let i = 0; i < tempTileData.length; i++) {
      if (tempTileData[i].tileSlot == "BottomLeft") {
        switcherData.push(tempTileData[i]);
      }
    }
    for (let i = 0; i < tempTileData.length; i++) {
      if (tempTileData[i].tileSlot == "BottomRight") {
        switcherData.push(tempTileData[i]);
      }
    }

    setTileData(switcherData);
  }

  useEffect(() => {
    getStartPageData();
  }, []);

  function deleteTile(tileData) {
    deleteTtile = 0;
    alert("asd");
    async function deleteTileModal() {
      var d = await startTileApi.apiStartPageTileIdDelete(tileData);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Kachel erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
        handleClose();
        getStartPageData();
      }
    }
    setOpen(true);
    let text = "Sind Sie sich sicher, dass Sie die Kachel löschen möchten?";
    setModal(
      <DeleteModal
        header="Kachel löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={deleteTileModal}
      ></DeleteModal>
    );
  }

  return (
    <>
      <div
        ref={nodeRef}
        style={{
          width: "100%",
          height: window.innerHeight - getOffset(nodeRef.current).top,
          overflow: "auto",
          //  backgroundColor: "#F3F8F8",
        }}
      >
        {tileData && (
          <div className="show-grid">
            <FlexboxGrid
              align="top"
              justify="center"
              style={{ marginBottom: "12px" }}
            >
              <FlexboxGrid.Item colspan={4}>
                <div
                  style={{
                    lineHeight: 6,
                    textAlign: "center",
                    border: "1px #000000",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    if (deleteTtile == 0) {
                      setSelectedTile(tileData[0] ? tileData[0].id : "0");
                      setTileAssignment(StartPageTileSlot.Primary);
                    } else {
                      deleteTile(tileData[0].id);
                    }
                  }}
                >
                  {tileData[0] && (
                    <div>
                      <img
                        src={tileData[0]?.tileBackgroundImageUrl}
                        width="100%"
                      ></img>

                      <IconButton
                        icon={<TrashIcon />}
                        circle
                        color="red"
                        appearance="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                        onClick={() => (deleteTtile = 1)}
                      />
                    </div>
                  )}

                  {tileData[0] != undefined
                    ? tileData[0]?.tileCaption
                    : "Hauptkachel"}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid
              align="middle"
              justify="center"
              style={{ marginBottom: "12px" }}
            >
              <FlexboxGrid.Item colspan={4}>
                <div
                  onClick={() => {
                    if (deleteTtile == 0) {
                      setSelectedTile(tileData[1] ? tileData[1].id : "0");
                      setTileAssignment(StartPageTileSlot.TopLeft);
                    } else {
                      deleteTile(tileData[1].id);
                    }
                  }}
                  style={{
                    lineHeight: 6,
                    textAlign: "center",
                    border: "1px #000000",
                    borderStyle: "solid",
                  }}
                >
                  {tileData[1] && (
                    <div>
                      <img
                        src={tileData[1]?.tileBackgroundImageUrl}
                        width="100%"
                      ></img>

                      <IconButton
                        icon={<TrashIcon />}
                        circle
                        color="red"
                        appearance="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                        onClick={() => (deleteTtile = 1)}
                      />
                    </div>
                  )}

                  {tileData[1] != undefined
                    ? tileData[1]?.tileCaption
                    : "Kachel 1 1"}
                </div>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={4}>
                <div
                  style={{
                    lineHeight: 6,
                    textAlign: "center",
                    border: "1px #000000",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    if (deleteTtile == 0) {
                      setSelectedTile(tileData[2] ? tileData[2].id : "0");
                      setTileAssignment(StartPageTileSlot.TopRight);
                    } else {
                      deleteTile(tileData[2].id);
                    }
                  }}
                >
                  {tileData[2] && (
                    <div>
                      <img
                        src={tileData[2]?.tileBackgroundImageUrl}
                        width="100%"
                      ></img>

                      <IconButton
                        icon={<TrashIcon />}
                        circle
                        color="red"
                        appearance="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                        onClick={() => (deleteTtile = 1)}
                      />
                    </div>
                  )}

                  {tileData[2] != undefined
                    ? tileData[2]?.tileCaption
                    : "Kachel 1 2"}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid align="middle" justify="center">
              <FlexboxGrid.Item colspan={4}>
                <div
                  style={{
                    lineHeight: 6,
                    textAlign: "center",
                    border: "1px #000000",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    if (deleteTtile == 0) {
                      setSelectedTile(tileData[3] ? tileData[3].id : "0");
                      setTileAssignment(StartPageTileSlot.BottomLeft);
                    } else {
                      deleteTile(tileData[3].id);
                    }
                  }}
                >
                  {tileData[3] && (
                    <div>
                      <img
                        src={tileData[3]?.tileBackgroundImageUrl}
                        width="100%"
                      ></img>

                      <IconButton
                        icon={<TrashIcon />}
                        circle
                        color="red"
                        appearance="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                        onClick={() => (deleteTtile = 1)}
                      />
                    </div>
                  )}

                  {tileData[3] != undefined
                    ? tileData[3]?.tileCaption
                    : "Kachel 2 1"}
                </div>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={4}>
                <div
                  style={{
                    lineHeight: 6,
                    textAlign: "center",
                    border: "1px #000000",
                    borderStyle: "solid",
                  }}
                  onClick={() => {
                    if (deleteTtile == 0) {
                      setSelectedTile(tileData[4] ? tileData[4].id : "0");
                      setTileAssignment(StartPageTileSlot.BottomRight);
                    } else {
                      deleteTile(tileData[4].id);
                    }
                  }}
                >
                  {tileData[4] && (
                    <div>
                      <img
                        src={tileData[4]?.tileBackgroundImageUrl}
                        width="100%"
                      ></img>

                      <IconButton
                        icon={<TrashIcon />}
                        circle
                        color="red"
                        appearance="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                        onClick={() => (deleteTtile = 1)}
                      />
                    </div>
                  )}

                  {tileData[4] != undefined
                    ? tileData[4]?.tileCaption
                    : "Kachel 2 2"}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        )}
      </div>
      {selectedTile && (
        <>
          <StartTileEditDrawer
            tile={selectedTile}
            handleClose={() => setSelectedTile()}
            slot={tileAssignment}
            location={props.location.id}
          ></StartTileEditDrawer>
        </>
      )}
      {open && modal}
    </>
  );
}
export default LocationStartPage;

import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Checkbox, Divider, IconButton, Table } from "rsuite";
import { getOffset } from "rsuite/esm/DOMHelper";
import CheckCell from "../Layout/CheckCell";
import DraggableRow from "../Layout/DraggableRow";
import ImageCell from "../Layout/ImageCell";
import TextCell from "../Layout/TextCell";
import { Copy, Edit, Trash } from "@rsuite/icons";
import helper from "../../services/helper";
import coachingPlanExerciseApi from "../../services/CoachingPlanExerciseApi";
import coachingPlanApi from "../../services/CoachingPlanApi";
import practiceApi from "../../services/PracticeApi";
import CoachinplanExerciseForm from "./CoachinplanExerciseForm";
import createToast from "../Layout/Toast";

function CoachingplanExercises({ plan, onCopyEntry, onHandleCheck }) {
  const nodeRef = useRef();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [updateOrder, setUpdateOrder] = useState(false);
  const [data, setData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState();
  const [openWithHeader, setOpenWithHeader] = useState(false);

  let checked = false;
  let indeterminate = false;
  //const data = ;
  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }
  const handleDragRow = (sourceId, targetId) => {
    setData(helper.sort(data, sourceId, targetId));
    setUpdateOrder(true);
  };

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data.map((item) => item.id) : [];
    onHandleCheck(keys);
    setCheckedKeys(keys);
  };
  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    onHandleCheck(keys);
    setCheckedKeys(keys);
  };

  const handleOpen = (urli) => {
    /*  setOpen(true);
    var source = "" + urli.url.slice(0, -1);
    setUrl(source);*/
  };
  function rowClickHandler(e) {
    /*setSelectedEntry(e.id);
    setOpenWithHeader(true);*/
  }

  useEffect(() => {
    getData();
  }, [plan]);

  async function onDeleteSingle(id) {
    const d = await coachingPlanExerciseApi.apiCoachingPlanExerciseIdDelete(id);

    if (d.status === 200) {
      createToast("Übung erfolgreich entfernt.");
      getData();
    }
  }
  async function getData() {
    const planData = await coachingPlanApi.apiCoachingPlanIdGet(plan.id);
    planData.data.coachingPlanExercises.map((exercise) => {
      if (exercise.iterations !== 0 && exercise.sets === 0) {
        exercise.sets = exercise.iterations;
      }
    });
    setData(planData.data.coachingPlanExercises);
  }
  function reload() {
    getData();
  }
  const ActionCell = ({ rowData, dataKey, ...cellData }) => {
    function handleAction() {
      setSelectedEntry(rowData);
      setOpenWithHeader(true);
    }
    function handleActionCopy() {
      onCopyEntry(rowData);
    }

    function handleActionDelete() {
      onDeleteSingle(rowData.id);
    }

    return (
      <Table.Cell {...cellData} className="link-group">
        <IconButton
          appearance="subtle"
          onClick={handleAction}
          icon={<Edit />}
        />

        <>
          <Divider vertical={true}></Divider>
          <IconButton
            appearance="subtle"
            onClick={handleActionCopy}
            icon={<Copy />}
          />
          <Divider vertical={true}></Divider>
          <IconButton
            appearance="subtle"
            onClick={handleActionDelete}
            icon={<Trash />}
          />
        </>
      </Table.Cell>
    );
  };

  useEffect(() => {
    async function updateorder() {
      for (let i = 0; i < data.length; i++) {
        console.log(data);
        const u = await coachingPlanExerciseApi.apiCoachingPlanExerciseIdPut(
          data[i].id,
          {
            id: data[i].id,
            order: i,
            coachingPlanId: data[i].coachingPlanId,
            durationInSeconds: data[i].durationInSeconds,
            exerciseId: data[i].exerciseId,
            iterations: data[i].iterations,
            pauseInSeconds: data[i].pauseInSeconds,
            repetitions: data[i].repetitions,
            sets: data[i].sets,
          }
        );
      }
    }

    if (updateOrder) {
      setUpdateOrder(false);
      updateorder();
    }
  }, [updateOrder]);

  console.log(data);
  return (
    <>
      <div style={{ width: "100%", height: "100%" }} ref={nodeRef}>
        <DndProvider backend={HTML5Backend}>
          <Table
            virtualized
            className="striped-table"
            data={data}
            id="table"
            showHeader={true}
            rowHeight={75}
            height={window.innerHeight - getOffset(nodeRef.current).top - 5}
            renderRow={(children, rowData) =>
              rowData ? (
                <DraggableRow
                  canEdit={true}
                  rowData={rowData}
                  onDrag={handleDragRow}
                >
                  {children}
                </DraggableRow>
              ) : (
                children
              )
            }
          >
            <Table.Column width={50} align="center">
              <Table.HeaderCell style={{ padding: 0 }}>
                <div style={{ lineHeight: "40px" }}>
                  <Checkbox
                    inline
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={handleCheckAll}
                  />
                </div>
              </Table.HeaderCell>
              <CheckCell
                lineheight="46px"
                dataKey="id"
                checkedKeys={checkedKeys}
                onChange={handleCheck}
              />
            </Table.Column>

            <Table.Column>
              <Table.HeaderCell>Vorschau</Table.HeaderCell>
              <ImageCell
                onClickHandler={handleOpen}
                dataKey="videoThumbnailUrl"
              />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <TextCell
                dataKey="exercise.headline"
                divheight={50}
                rowClickHandler={rowClickHandler}
              />
            </Table.Column>
            <Table.Column fullText flexGrow={1}>
              <Table.HeaderCell>Sets</Table.HeaderCell>
              <TextCell
                divheight={50}
                rowClickHandler={rowClickHandler}
                dataKey="sets"
              />
            </Table.Column>
            <Table.Column fullText flexGrow={1}>
              <Table.HeaderCell>Wiederholungen</Table.HeaderCell>
              <TextCell
                divheight={50}
                rowClickHandler={rowClickHandler}
                dataKey="repetitions"
              />
            </Table.Column>
            <Table.Column fullText flexGrow={1}>
              <Table.HeaderCell>Dauer</Table.HeaderCell>
              <TextCell
                divheight={50}
                rowClickHandler={rowClickHandler}
                dataKey="durationInSeconds"
              />
            </Table.Column>
            <Table.Column fullText flexGrow={1}>
              <Table.HeaderCell>Pause (s)</Table.HeaderCell>
              <TextCell
                divheight={50}
                rowClickHandler={rowClickHandler}
                dataKey="pauseInSeconds"
              />
            </Table.Column>
            <Table.Column width={195}>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <ActionCell dataKey="id" />
            </Table.Column>
          </Table>
        </DndProvider>
      </div>
      {selectedEntry && (
        <CoachinplanExerciseForm
          canEdit={true}
          openWithHeader={openWithHeader}
          onClose={() => {
            setSelectedEntry();
          }}
          selectedEntry={selectedEntry}
          planId={plan.id}
          setOpenWithHeader={(val) => {
            setOpenWithHeader(val);
          }}
          reload={reload}
        ></CoachinplanExerciseForm>
      )}
    </>
  );
}

export default CoachingplanExercises;

import { Message, toaster } from "rsuite";

const createErrorToast = (message) => {
  toaster.push(
    <Message closable showIcon type="error">
      {message}
    </Message>,
    "topCenter"
  );
};
export default createErrorToast;



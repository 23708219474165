import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Button, ButtonToolbar, Form, IconButton, Input } from "rsuite";
import { Edit, Trash, Check } from "@rsuite/icons";

function ExerciseVideoExists({ video, handleDeleteVideo, setVideoNew }) {
  const [videoPath, setVideoPath] = useState(video.videoUrl);
  const [videoThumbPath, setVideoThumbPath] = useState(video.videoThumbnailUrl);
  const vref = useRef();
  const tref = useRef();

  function videoNew() {
    setVideoNew(vref.current.value, tref.current.value);
    setVideoPath(vref.current.value);
  }
  return (
    <React.Fragment>
      {video.videoUrl == "" && (
        <React.Fragment>
          <div style={{ marginTop: "25px" }}>
            <label>Video Url:</label>
            <Input ref={vref} autoComplete="off" name="videoPath"></Input>
          </div>
          <div style={{ marginTop: "25px" }}>
            <label>Videovorschaubild Url:</label>
            <Input ref={tref} autoComplete="off" name="videoPath"></Input>
          </div>
          <ButtonToolbar style={{ marginTop: "25px" }}>
            <IconButton
              size="sm"
              appearance="primary"
              color="green"
              onClick={videoNew}
              icon={<Check />}
            >
              Videodaten speichern
            </IconButton>
          </ButtonToolbar>
        </React.Fragment>
      )}
      {video.videoUrl != "" && (
        <div style={{ textAlign: "center", margin: 25 }}>
          <video width="480" height="270" controls>
            <source src={video.videoUrl} type="video/mp4"></source>
          </video>
          {video.videoThumbnailUrl != "" && <img src={video.videoThumbnailUrl}></img>}
          {video.videoThumbnailUrl == "" && (
            <p>Kein Vorschaubild für das Video vorhanden</p>
          )}


          <ButtonToolbar style={{ marginTop: 8 }}>
            <IconButton
              size="sm"
              appearance="primary"
              color="red"
              onClick={handleDeleteVideo}
              icon={<Trash />}
            >
              Video löschen
            </IconButton>
          </ButtonToolbar>
        </div>
      )}
    </React.Fragment>
  );
}
export default ExerciseVideoExists;
/*          <Form
            style={{
              marginLeft: "0 !important",
              marginRight: "0px !important ",
            }}
            ref={ref}
            onChange={logit}
          >
            <Form.Group controlId="videoPath">
              <Form.ControlLabel>VideoUrl</Form.ControlLabel>
              <Form.Control autoComplete="off" name="videoPath" />
            </Form.Group>{" "}
            <Form.Group controlId="videoThumbPath">
              <Form.ControlLabel>Videovorschaubild</Form.ControlLabel>
              <Form.Control autoComplete="off" name="videoThumbPath" />
            </Form.Group>
            <Form.Group>
              {" "}
            </Form.Group>
          </Form>*/

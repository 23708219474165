import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Message, toaster, Uploader } from "rsuite";
import ExerciseForm from "../Components/Exercises/ExerciseForm";
import ExercisesList from "../Components/Exercises/ExercisesList";
import DeleteModal from "../Components/Layout/DeleteModal";
import SearchTree from "../Components/Layout/SearchTree";
import { ExerciseApi } from "../openapi";
import exerciseApi from "../services/ExerciseApi";
import practiceApi from "../services/PracticeApi";
import practiceExerciseApi from "../services/PracticeExerciseApi";
import userApi from "../services/UserApi";

function Exercise(props) {
  const navigation = useLocation();
  const [firstTime, setFirstTime] = useState(false);
  const [exList, setExList] = useState([]);
  const [selectedEx, setSelectedEx] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);
  let temp = [];
  let temp2 = [];

  let counter = 0;

  const role = localStorage.getItem("role");
  let currentUser;

  async function getExercisesData() {
    currentUser = await userApi.apiUserCurrentGet();
    setLoading(true);

    const exData = await exerciseApi.apiExerciseGet();


    for (let i = 0; i < exData.data.data.length; i++) {
      console.log(currentUser.data.id ,exData.data.data[i].createdById)
      if(role==3)
      {
        temp.push({
          label: exData.data.data[i].headline,
          value: counter,
          parent_id: exData.data.data[i].id,
          text: exData.data.data[i].text,
        });
        counter++;
      }
      else if(currentUser.data.id ==exData.data.data[i].createdById)
      {
        temp.push({
          label: exData.data.data[i].headline,
          value: counter,
          parent_id: exData.data.data[i].id,
          text: exData.data.data[i].text,
        });
        counter++;
      }

    }

    console.log(temp)
    setLoading(false);
    setExList(temp);
    setSelectedEx(null);
    //const userData = await userApi.apiUserGet();
    //setFirstTime(true);
  }

  useEffect(() => {
    getExercisesData();
  }, [navigation.state.from]);
  async function onChangeHandler(activeNode, value, event) {
    onSelect(activeNode.parent_id);
  }

  function onCreateNew() {
    setFirstTime(false);
    setSelectedEx(0);
  }

  function onClean() {
    setFirstTime(true);
    setExList(null);
    setSelectedEx(null);

    getExercisesData();
  }
  async function onSelect(id) {
    const exData = await exerciseApi.apiExerciseIdGet(id);

    setFirstTime(false);
    setSelectedEx(exData.data);
  }
  async function onSubmitNew(data, practiceId) {
    const create = await exerciseApi.apiExercisePost(data);

    if (create.status == 201) {
      if (practiceId) {
        practiceId.map(async (kp) => {
          const practiceEx = await practiceExerciseApi.apiPracticeExercisePost({
            exerciseId: create.data.id,
            practiceId: kp,
          });
        });
      } else {
        console.log("nix machen");
      }
      toaster.push(
        <Message showIcon type="success">
          Übung erfolgreich erstellt.
        </Message>,
        "topCenter"
      );
      getExercisesData();
    }
  }
  async function onEdit(data, id, practiceId) {
    const update = await exerciseApi.apiExerciseIdPut(id, data);

    if (update.status == 200) {
      console.log("losche die practice zuordnung ", practiceId);
      const practiceExercise =
        await practiceExerciseApi.apiPracticeExerciseGet();

      practiceExercise.data.data.map(async (peData) => {
        if (peData.exerciseId === id) {
          //let practiceName;

          const d = await practiceExerciseApi.apiPracticeExerciseIdDelete(
            peData.id
          );
          console.log("lösche " + peData.id);
        }
      });
      if (practiceId) {
        practiceId.map(async (kp) => {
          const practiceEx = await practiceExerciseApi.apiPracticeExercisePost({
            exerciseId: id,
            practiceId: kp,
          });
        });
      } else {
        console.log("nix machen");
      }
      toaster.push(
        <Message showIcon type="success">
          Übung erfolgreich editiert.
        </Message>,
        "topCenter"
      );
      getExercisesData();
    }
  }
  async function onDelete(id, name) {
    async function deleteExercise() {
      var d = await exerciseApi.apiExerciseIdDelete(id);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Übung erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
        handleClose();
        getExercisesData();
      }
    }
    setOpen(true);
    let text =
      "Sind Sie sich sicher, dass sie die Übung " + name + " löschen möchten?";
    setModal(
      <DeleteModal
        header="Übung löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={deleteExercise}
      ></DeleteModal>
    );
  }
  return (
    <React.Fragment>
      <Divider></Divider>
      {exList && (
        <SearchTree
          selectedItem={selectedEx}
          dataList={exList}
          selectedList={exList}
          onChangeHandler={onChangeHandler}
          onCreateNew={onCreateNew}
          onClean={onClean}
          firstTime={firstTime}
          mode="parent"
          canEdit={true}
        ></SearchTree>
      )}
      {exList && selectedEx == null && (
        <ExercisesList
          loading={loading}
          onSelect={(data) => onSelect(data)}
          onDelete={(data, name) => onDelete(data, name)}
          data={exList}
        ></ExercisesList>
      )}
      {selectedEx !== null && (
        <ExerciseForm
          exercise={selectedEx}
          onSubmitNew={(data, practiceId) => onSubmitNew(data, practiceId)}
          onEdit={(data, id, practiceId) => onEdit(data, id, practiceId)}
          onCancel={onClean}
        ></ExerciseForm>
      )}
      {open && modal}
    </React.Fragment>
  );
}
export default Exercise;

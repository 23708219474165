import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Message, toaster } from "rsuite";
import SearchTree from "./../Components/Layout/SearchTree";
import practiceApi from "../services/PracticeApi";
import LocationList from "../Components/Location/LocationList";
import LocationForm from "../Components/Location/LocationForm";
import DeleteModal from "../Components/Layout/DeleteModal";
import userApi from "../services/UserApi";

function Location(props) {
  const navigation = useLocation();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationList, setLocationList] = useState(null);
  const [firstTime, setFirstTime] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);

  let temp = [];
  let temp2 = [];
  const handleClose = () => setOpen(false);

  let counter = 0;

  async function getPracticeDate() {
    setLoading(true);
    const practiceData = await practiceApi.apiPracticeGet();
    const practiceId = localStorage.getItem("practiceId");
    const role = localStorage.getItem("role");
    for (let i = 0; i < practiceData.data.data.length; i++) {
      if (role == 3) {
        temp.push({
          label: practiceData.data.data[i].name,
          value: counter,
          parent_id: practiceData.data.data[i].id,
          zip: practiceData.data.data[i].zipCode,
          street: practiceData.data.data[i].street,
          city: practiceData.data.data[i].city,
        });
        counter++;
      } else if (practiceId == practiceData.data.data[i].id) {
        temp.push({
          label: practiceData.data.data[i].name,
          value: counter,
          parent_id: practiceData.data.data[i].id,
          zip: practiceData.data.data[i].zipCode,
          street: practiceData.data.data[i].street,
          city: practiceData.data.data[i].city,
        });
        counter++;
      }
    }
    setLoading(false);
    setLocationList(temp);
    setSelectedLocation(null);
  }
  useEffect(() => {
    getPracticeDate();
  }, [navigation.state.from]);

  function onChangeHandler(activeNode, value, event) {
    onSelect(activeNode.parent_id);
  }
  function onCreateNew() {
    setFirstTime(false);
    setSelectedLocation(0);
  }
  function onClean() {
    setFirstTime(true);
    setSelectedLocation(null);
    getPracticeDate();
  }

  function onDelete(id, name) {
    async function deleteLocation() {
      var d = await practiceApi.apiPracticeIdDelete(id);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Projekt erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
        handleClose();
        getPracticeDate();
      }
    }
    setOpen(true);
    let text =
      "Sind Sie sich sicher, dass sie den Standort " +
      name +
      " löschen möchten?";
    setModal(
      <DeleteModal
        header="Standort löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={deleteLocation}
      ></DeleteModal>
    );
  }

  async function onSelect(id) {
    const practiceData = await practiceApi.apiPracticeIdGet(id);
    setFirstTime(false);
    setSelectedLocation(practiceData.data);
  }

  async function onSubmitNew(data) {
    const c = await practiceApi.apiPracticePost(data);
    console.log(c);
    if (c.status == 201) {
      toaster.push(
        <Message showIcon type="success">
          Standort erfolgreich angelegt.
        </Message>,
        "topCenter"
      );
      getPracticeDate();
    }
  }
  async function onEdit(data, id) {
    const u = await practiceApi.apiPracticeIdPut(id, data);

    if (u.status == 200) {
      toaster.push(
        <Message showIcon type="success">
          Standort erfolgreich editiert.
        </Message>,
        "topCenter"
      );
      getPracticeDate();
    }
  }
  return (
    <React.Fragment>
      <Divider></Divider>
      {locationList && (
        <SearchTree
          selectedItem={selectedLocation}
          dataList={locationList}
          selectedList={locationList}
          onChangeHandler={onChangeHandler}
          onCreateNew={onCreateNew}
          onClean={onClean}
          firstTime={firstTime}
          mode="parent"
          canEdit={JSON.parse(localStorage.getItem("role")) == 3 ? true : false}
        ></SearchTree>
      )}
      {locationList && selectedLocation == null && (
        <LocationList
          loading={loading}
          onSelect={(data) => onSelect(data)}
          onDelete={(data, name) => onDelete(data, name)}
          data={locationList}
        ></LocationList>
      )}
      {selectedLocation !== null && (
        <LocationForm
          location={selectedLocation}
          onSubmitNew={(data) => onSubmitNew(data)}
          onEdit={(data, id) => onEdit(data, id)}
          onCancel={onClean}
        ></LocationForm>
      )}
      {open && modal}
    </React.Fragment>
  );
}
export default Location;

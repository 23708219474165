import { useEffect, useRef, useState } from "react";
import { Button, Divider, Modal, SelectPicker, Stack, Uploader } from "rsuite";
import documentCategoryApi from "../../services/DocumentCategoryApi";
import authService from "../../services/auth.service";
import userApi from "../../services/UserApi";
import documentApi from "../../services/DocumentApi";
import createToast from "../Layout/Toast";
let token = "";
function UserDocumentUpload(props) {
  const [catData, setCatData] = useState();
  const [selectedCat, setSelectedCat] = useState();
  const [udata, setUdata] = useState();
  const [fileList, setFileList] = useState([]);
  const uploader = useRef();
  async function getCats() {
    token = await authService.getUserToken();
    const userData = await userApi.apiUserCurrentGet();
    setUdata(userData.data.id);
    console.log(userData);
    const cats = await documentCategoryApi.apiDocumentCategoryGet();

    const temp = [];
    for (let i = 0; i < cats.data.data.length; i++) {
      temp.push({ label: cats.data.data[i].name, value: cats.data.data[i].id });
    }

    setCatData(temp);
  }

  useEffect(() => {
    getCats();
  }, []);

  return (
    <Modal open={true} onClose={props.onCloseModal}>
      <Modal.Header>
        <Modal.Title>Dokument hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Kategorie auswählen
        <SelectPicker
          block
          cleanable
          data={catData}
          onChange={setSelectedCat}
        ></SelectPicker>
        <br></br>
        <br></br>
        <br></br>
        {selectedCat && (
          <>
            <Uploader
              locale={{ upload: "Datei auswählen" }}
              disabled={fileList.length == 0 ? false : true}
              fileList={fileList}
              autoUpload={false}
              onChange={(e) => {
                setFileList(e);
              }}
              ref={uploader}
              multiple={false}
              accept="image/png,image/jpeg,image/jpg,application/pdf"
            >
              {fileList.length == 0 && (
                <Button color="red" appearance="primary" >Datei auswählen</Button>
              )}
            </Uploader>{" "}
            <hr />
            <Button
              appearance="primary"
              disabled={!fileList.length}
              onClick={async () => {
                console.log(fileList[0].blobFile);
                const datei = await documentApi.apiDocumentPost({
                  documentCategoryId: selectedCat,
                  documentName: fileList[0].name,
                  filename: fileList[0].name,
                  userId: props.user,
                  uploaderId: udata,
                });
                console.log(fileList);
                const upload =
                  await documentApi.apiDocumentUploadDocumentIdPost(
                    datei.data.id,
                    fileList[0].blobFile
                  );

                  if(upload.status=="200")
                  {
                    createToast("Dokument erfolgreich hinzugefügt.")
                    props.reload()
                  }
                // uploader.current.start();
              }}
            >
              Absenden
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default UserDocumentUpload;

import React, { useEffect, useCallback, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import EventBus from "./common/EventBus";
import { logout } from "./slices/auth";
import axios from "axios";
import "rsuite/dist/rsuite.css";

import { UserApi, Configuration } from "./openapi";
import Login from "./Sites/Login.site";
import Navigation_rs from "./Components/Layout/Navigation_rs";
import ProfilePage from "./Sites/Profile.site";
import Location from "./Sites/Location.Site";
import User from "./Sites/User.site";
import Exercise from "./Sites/Exercise";
import { Container } from "rsuite";
import Coachingplan from "./Sites/Coachingplan.Site";
import createToast from "./Components/Layout/Toast";
import authService from "./services/auth.service";
import Blog from "./Sites/Blog";

function App() {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState();
  const [isLookingForUser, setIsLookingForUser] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    console.log("hier   ");
    setCurrentUser(await authService.getUser());
    setIsLookingForUser(false);
  }

  const logOut = useCallback(() => {
    dispatch(logout());
    window.location.reload();
  }, [dispatch]);

  return (
    <React.Fragment>
      {!currentUser && (
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login></Login>}></Route>
            {!isLookingForUser && (
              <Route
                path="/*"
                element={<Navigate replace to={"/login"} />}
              ></Route>
            )}
          </Routes>
        </BrowserRouter>
      )}
      {currentUser && (
        <BrowserRouter>
          <Container style={{ height: "100%" }}>
            <Navigation_rs onLogout={logOut}></Navigation_rs>

            <Routes>
              <Route path="/profile" element={<ProfilePage></ProfilePage>} />

              <Route path="/login" element={<Login></Login>} />
              <Route path="/location" element={<Location />} />
              <Route path="/user" element={<User />} />
              <Route path="/exercise" element={<Exercise />} />
              <Route
                path="/plans"
                element={<Coachingplan currentUser={currentUser} />}
              />
              <Route path="/blog" element={<Blog />} />
            </Routes>
          </Container>
        </BrowserRouter>
      )}
    </React.Fragment>
  );
}

export default App;

/* tslint:disable */
/* eslint-disable */
/**
 * Physio.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityCreateDto
 */
export interface ActivityCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {ActivityType}
     * @memberof ActivityCreateDto
     */
    'activityType'?: ActivityType;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'activityPlace'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityCreateDto
     */
    'durationInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityCreateDto
     */
    'pauseInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityCreateDto
     */
    'repetitions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {ActivityPerception}
     * @memberof ActivityCreateDto
     */
    'perception'?: ActivityPerception;
    /**
     * 
     * @type {ActivityIntensity}
     * @memberof ActivityCreateDto
     */
    'intensity'?: ActivityIntensity;
    /**
     * 
     * @type {number}
     * @memberof ActivityCreateDto
     */
    'sets'?: number | null;
    /**
     * 
     * @type {ActivityInterval}
     * @memberof ActivityCreateDto
     */
    'interval'?: ActivityInterval;
    /**
     * 
     * @type {string}
     * @memberof ActivityCreateDto
     */
    'activitiyGroupId'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityGroupReadDto
 */
export interface ActivityGroupReadDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupReadDto
     */
    'id': string;
    /**
     * 
     * @type {Array<ActivityReadDto>}
     * @memberof ActivityGroupReadDto
     */
    'activities'?: Array<ActivityReadDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupReadDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityGroupReadDto
     */
    'isDeleted'?: boolean;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface ActivityGroupReadDtoPagedDtoList
 */
export interface ActivityGroupReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<ActivityGroupReadDto>}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'data': Array<ActivityGroupReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof ActivityGroupReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityIntensity = {
    Less: 'less',
    Medium: 'medium',
    More: 'more'
} as const;

export type ActivityIntensity = typeof ActivityIntensity[keyof typeof ActivityIntensity];


/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityInterval = {
    Never: 'never',
    Daily: 'daily',
    Weekly: 'weekly',
    Biweekly: 'biweekly',
    Monthly: 'monthly'
} as const;

export type ActivityInterval = typeof ActivityInterval[keyof typeof ActivityInterval];


/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityPerception = {
    Least: 'least',
    Less: 'less',
    Medium: 'medium',
    More: 'more',
    Most: 'most'
} as const;

export type ActivityPerception = typeof ActivityPerception[keyof typeof ActivityPerception];


/**
 * 
 * @export
 * @interface ActivityReadDto
 */
export interface ActivityReadDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {ActivityType}
     * @memberof ActivityReadDto
     */
    'activityType'?: ActivityType;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'activityPlace'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityReadDto
     */
    'durationInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityReadDto
     */
    'pauseInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityReadDto
     */
    'repetitions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {ActivityPerception}
     * @memberof ActivityReadDto
     */
    'perception'?: ActivityPerception;
    /**
     * 
     * @type {ActivityIntensity}
     * @memberof ActivityReadDto
     */
    'intensity'?: ActivityIntensity;
    /**
     * 
     * @type {number}
     * @memberof ActivityReadDto
     */
    'sets'?: number | null;
    /**
     * 
     * @type {ActivityInterval}
     * @memberof ActivityReadDto
     */
    'interval'?: ActivityInterval;
    /**
     * 
     * @type {string}
     * @memberof ActivityReadDto
     */
    'activitiyGroupId'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface ActivityReadDtoPagedDtoList
 */
export interface ActivityReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<ActivityReadDto>}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'data': Array<ActivityReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof ActivityReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityType = {
    Appointment: 'Appointment',
    Coaching: 'Coaching',
    Training: 'Training',
    Course: 'Course'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];


/**
 * 
 * @export
 * @interface ActivityUpdateDto
 */
export interface ActivityUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {ActivityType}
     * @memberof ActivityUpdateDto
     */
    'activityType'?: ActivityType;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'activityPlace'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateDto
     */
    'durationInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateDto
     */
    'pauseInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateDto
     */
    'repetitions'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {ActivityPerception}
     * @memberof ActivityUpdateDto
     */
    'perception'?: ActivityPerception;
    /**
     * 
     * @type {ActivityIntensity}
     * @memberof ActivityUpdateDto
     */
    'intensity'?: ActivityIntensity;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateDto
     */
    'sets'?: number | null;
    /**
     * 
     * @type {ActivityInterval}
     * @memberof ActivityUpdateDto
     */
    'interval'?: ActivityInterval;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateDto
     */
    'activitiyGroupId'?: string | null;
}
/**
 * 
 * @export
 * @interface BlogPostCreateDto
 */
export interface BlogPostCreateDto {
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'headline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'teaser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogPostCreateDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'publishedStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostCreateDto
     */
    'publishedEnd'?: string | null;
}
/**
 * 
 * @export
 * @interface BlogPostReadDto
 */
export interface BlogPostReadDto {
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'authorId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogPostReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'headline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'teaser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogPostReadDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'publishedStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostReadDto
     */
    'publishedEnd'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface BlogPostReadDtoPagedDtoList
 */
export interface BlogPostReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<BlogPostReadDto>}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'data': Array<BlogPostReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof BlogPostReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface BlogPostUpdateDto
 */
export interface BlogPostUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'headline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'teaser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlogPostUpdateDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'backgroundImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'publishedStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlogPostUpdateDto
     */
    'publishedEnd'?: string | null;
}
/**
 * 
 * @export
 * @interface BodyDataPointCreateDto
 */
export interface BodyDataPointCreateDto {
    /**
     * 
     * @type {number}
     * @memberof BodyDataPointCreateDto
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof BodyDataPointCreateDto
     */
    'weight': number;
    /**
     * 
     * @type {string}
     * @memberof BodyDataPointCreateDto
     */
    'measuredAt': string;
}
/**
 * 
 * @export
 * @interface BodyDataPointReadDto
 */
export interface BodyDataPointReadDto {
    /**
     * 
     * @type {string}
     * @memberof BodyDataPointReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BodyDataPointReadDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyDataPointReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BodyDataPointReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BodyDataPointReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BodyDataPointReadDto
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof BodyDataPointReadDto
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof BodyDataPointReadDto
     */
    'weight': number;
    /**
     * 
     * @type {string}
     * @memberof BodyDataPointReadDto
     */
    'measuredAt': string;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface BodyDataPointReadDtoPagedDtoList
 */
export interface BodyDataPointReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<BodyDataPointReadDto>}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'data': Array<BodyDataPointReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof BodyDataPointReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CoachingPlanCategory = {
    Mental: 'mental',
    Social: 'social',
    Movement: 'movement'
} as const;

export type CoachingPlanCategory = typeof CoachingPlanCategory[keyof typeof CoachingPlanCategory];


/**
 * 
 * @export
 * @interface CoachingPlanCreateDto
 */
export interface CoachingPlanCreateDto {
    /**
     * 
     * @type {CoachingPlanCategory}
     * @memberof CoachingPlanCreateDto
     */
    'coachingPlanCategory': CoachingPlanCategory;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanCreateDto
     */
    'tileTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanCreateDto
     */
    'tileSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanCreateDto
     */
    'tileImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanCreateDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanCreateDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanCreateDto
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanCreateDto
     */
    'practiceId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CoachingPlanCreateDto
     */
    'isPublic'?: boolean;
}
/**
 * 
 * @export
 * @interface CoachingPlanExerciseCreateDto
 */
export interface CoachingPlanExerciseCreateDto {
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'coachingPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'exerciseId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'iterations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'durationInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'pauseInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'repetitions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'sets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseCreateDto
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface CoachingPlanExerciseReadDto
 */
export interface CoachingPlanExerciseReadDto {
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseReadDto
     */
    'id': string;
    /**
     * 
     * @type {CoachingPlanReadDto}
     * @memberof CoachingPlanExerciseReadDto
     */
    'coachingPlan'?: CoachingPlanReadDto;
    /**
     * 
     * @type {ExerciseReadDto}
     * @memberof CoachingPlanExerciseReadDto
     */
    'exercise'?: ExerciseReadDto;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseReadDto
     */
    'coachingPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseReadDto
     */
    'exerciseId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseReadDto
     */
    'iterations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseReadDto
     */
    'durationInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseReadDto
     */
    'pauseInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseReadDto
     */
    'repetitions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseReadDto
     */
    'sets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseReadDto
     */
    'order'?: number | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface CoachingPlanExerciseReadDtoPagedDtoList
 */
export interface CoachingPlanExerciseReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<CoachingPlanExerciseReadDto>}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'data': Array<CoachingPlanExerciseReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof CoachingPlanExerciseReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface CoachingPlanExerciseUpdateDto
 */
export interface CoachingPlanExerciseUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'coachingPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'exerciseId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'iterations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'durationInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'pauseInSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'repetitions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'sets'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanExerciseUpdateDto
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface CoachingPlanReadDto
 */
export interface CoachingPlanReadDto {
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CoachingPlanReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {Array<CoachingPlanExerciseReadDto>}
     * @memberof CoachingPlanReadDto
     */
    'coachingPlanExercises': Array<CoachingPlanExerciseReadDto>;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'tileTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'tileSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'tileImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanReadDto
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanReadDto
     */
    'practiceId'?: string | null;
    /**
     * 
     * @type {CoachingPlanCategory}
     * @memberof CoachingPlanReadDto
     */
    'coachingPlanCategory'?: CoachingPlanCategory;
    /**
     * 
     * @type {boolean}
     * @memberof CoachingPlanReadDto
     */
    'isPublic'?: boolean;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface CoachingPlanReadDtoPagedDtoList
 */
export interface CoachingPlanReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<CoachingPlanReadDto>}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'data': Array<CoachingPlanReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof CoachingPlanReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface CoachingPlanUpdateDto
 */
export interface CoachingPlanUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanUpdateDto
     */
    'tileTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanUpdateDto
     */
    'tileSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanUpdateDto
     */
    'tileImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanUpdateDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanUpdateDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof CoachingPlanUpdateDto
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CoachingPlanUpdateDto
     */
    'practiceId'?: string | null;
    /**
     * 
     * @type {CoachingPlanCategory}
     * @memberof CoachingPlanUpdateDto
     */
    'coachingPlanCategory'?: CoachingPlanCategory;
    /**
     * 
     * @type {boolean}
     * @memberof CoachingPlanUpdateDto
     */
    'isPublic'?: boolean;
}
/**
 * 
 * @export
 * @interface CurrentUserUpdateDto
 */
export interface CurrentUserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CurrentUserUpdateDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserUpdateDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserUpdateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserUpdateDto
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentCategoryCreateDto
 */
export interface DocumentCategoryCreateDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryCreateDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DocumentCategoryReadDto
 */
export interface DocumentCategoryReadDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentCategoryReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryReadDto
     */
    'name': string;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface DocumentCategoryReadDtoPagedDtoList
 */
export interface DocumentCategoryReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<DocumentCategoryReadDto>}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'data': Array<DocumentCategoryReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof DocumentCategoryReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface DocumentCategoryUpdateDto
 */
export interface DocumentCategoryUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentCategoryUpdateDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DocumentCreateDto
 */
export interface DocumentCreateDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'uploaderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'issuedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'documentCategoryId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentCreateDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentReadDto
 */
export interface DocumentReadDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'issuedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'documentCategoryId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'uploaderId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadDto
     */
    'notes'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface DocumentReadDtoPagedDtoList
 */
export interface DocumentReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<DocumentReadDto>}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'data': Array<DocumentReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof DocumentReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface DocumentUpdateDto
 */
export interface DocumentUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'documentName': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'issuedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'documentCategoryId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'uploaderId': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUpdateDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface ExerciseCreateDto
 */
export interface ExerciseCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'tileTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'tileSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'tileImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'videoThumbnailUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'videoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'headline': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseCreateDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof ExerciseCreateDto
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface ExerciseReadDto
 */
export interface ExerciseReadDto {
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'createdById'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExerciseReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'tileTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'tileSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'tileImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'videoThumbnailUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'videoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'headline': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseReadDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof ExerciseReadDto
     */
    'order'?: number | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface ExerciseReadDtoPagedDtoList
 */
export interface ExerciseReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<ExerciseReadDto>}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'data': Array<ExerciseReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof ExerciseReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface ExerciseUpdateDto
 */
export interface ExerciseUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'tileTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'tileSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'tileImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'videoThumbnailUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'videoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'headline': string;
    /**
     * 
     * @type {string}
     * @memberof ExerciseUpdateDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof ExerciseUpdateDto
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface FlavorCreateDto
 */
export interface FlavorCreateDto {
    /**
     * 
     * @type {string}
     * @memberof FlavorCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FlavorCreateDto
     */
    'myCoachingText'?: string | null;
}
/**
 * 
 * @export
 * @interface FlavorReadDto
 */
export interface FlavorReadDto {
    /**
     * 
     * @type {string}
     * @memberof FlavorReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FlavorReadDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FlavorReadDto
     */
    'myCoachingText'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface FlavorReadDtoPagedDtoList
 */
export interface FlavorReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<FlavorReadDto>}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'data': Array<FlavorReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof FlavorReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface FlavorUpdateDto
 */
export interface FlavorUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof FlavorUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FlavorUpdateDto
     */
    'myCoachingText'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageCreateDto
 */
export interface ImageCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ImageCreateDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageCreateDto
     */
    'caption'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageReadDto
 */
export interface ImageReadDto {
    /**
     * 
     * @type {string}
     * @memberof ImageReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImageReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ImageReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImageReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImageReadDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageReadDto
     */
    'caption'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface ImageReadDtoPagedDtoList
 */
export interface ImageReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<ImageReadDto>}
     * @memberof ImageReadDtoPagedDtoList
     */
    'data': Array<ImageReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof ImageReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof ImageReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof ImageReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof ImageReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof ImageReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof ImageReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface ImageUpdateDto
 */
export interface ImageUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    'caption'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageCreateDto
 */
export interface MessageCreateDto {
    /**
     * 
     * @type {MessageDirection}
     * @memberof MessageCreateDto
     */
    'direction'?: MessageDirection;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateDto
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateDto
     */
    'sendAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageCreateDto
     */
    'readAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageCreateDto
     */
    'isRead'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MessageDirection = {
    Sent: 'Sent',
    Received: 'Received'
} as const;

export type MessageDirection = typeof MessageDirection[keyof typeof MessageDirection];


/**
 * 
 * @export
 * @interface MessageReadDto
 */
export interface MessageReadDto {
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {MessageDirection}
     * @memberof MessageReadDto
     */
    'direction'?: MessageDirection;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'sendAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageReadDto
     */
    'readAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageReadDto
     */
    'isRead'?: boolean;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface MessageReadDtoPagedDtoList
 */
export interface MessageReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<MessageReadDto>}
     * @memberof MessageReadDtoPagedDtoList
     */
    'data': Array<MessageReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof MessageReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof MessageReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof MessageReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof MessageReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof MessageReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof MessageReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface MessageUpdateDto
 */
export interface MessageUpdateDto {
    /**
     * 
     * @type {MessageDirection}
     * @memberof MessageUpdateDto
     */
    'direction'?: MessageDirection;
    /**
     * 
     * @type {string}
     * @memberof MessageUpdateDto
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageUpdateDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageUpdateDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageUpdateDto
     */
    'sendAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageUpdateDto
     */
    'readAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageUpdateDto
     */
    'isRead'?: boolean;
}
/**
 * 
 * @export
 * @interface PracticeCreateDto
 */
export interface PracticeCreateDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'contact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeCreateDto
     */
    'website'?: string | null;
}
/**
 * 
 * @export
 * @interface PracticeExerciseCreateDto
 */
export interface PracticeExerciseCreateDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseCreateDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseCreateDto
     */
    'exerciseId'?: string;
}
/**
 * 
 * @export
 * @interface PracticeExerciseReadDto
 */
export interface PracticeExerciseReadDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseReadDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseReadDto
     */
    'exerciseId'?: string;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface PracticeExerciseReadDtoPagedDtoList
 */
export interface PracticeExerciseReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<PracticeExerciseReadDto>}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'data': Array<PracticeExerciseReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof PracticeExerciseReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface PracticeExerciseUpdateDto
 */
export interface PracticeExerciseUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseUpdateDto
     */
    'practiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeExerciseUpdateDto
     */
    'exerciseId'?: string;
}
/**
 * 
 * @export
 * @interface PracticeReadDto
 */
export interface PracticeReadDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'createdById'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PracticeReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {Array<BlogPostReadDto>}
     * @memberof PracticeReadDto
     */
    'blogPosts': Array<BlogPostReadDto>;
    /**
     * 
     * @type {Array<CoachingPlanReadDto>}
     * @memberof PracticeReadDto
     */
    'coachingPlans'?: Array<CoachingPlanReadDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PracticeReadDto
     */
    'coachingPlanIds': Array<string>;
    /**
     * 
     * @type {Array<CoachingPlanReadDto>}
     * @memberof PracticeReadDto
     */
    'publicCoachingPlans': Array<CoachingPlanReadDto>;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'contact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeReadDto
     */
    'website'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface PracticeReadDtoPagedDtoList
 */
export interface PracticeReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<PracticeReadDto>}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'data': Array<PracticeReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof PracticeReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface PracticeUpdateDto
 */
export interface PracticeUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'contact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PracticeUpdateDto
     */
    'website'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface StaffCreateDto
 */
export interface StaffCreateDto {
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StaffCreateDto
     */
    'practiceId'?: string | null;
}
/**
 * 
 * @export
 * @interface StartPageTileCreateDto
 */
export interface StartPageTileCreateDto {
    /**
     * 
     * @type {string}
     * @memberof StartPageTileCreateDto
     */
    'tileCaption': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileCreateDto
     */
    'tileBackgroundImageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof StartPageTileCreateDto
     */
    'tileOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileCreateDto
     */
    'practiceId': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileCreateDto
     */
    'headline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileCreateDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileCreateDto
     */
    'externalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartPageTileCreateDto
     */
    'isLeading': boolean;
    /**
     * 
     * @type {StartPageTileSlot}
     * @memberof StartPageTileCreateDto
     */
    'tileSlot'?: StartPageTileSlot;
}
/**
 * 
 * @export
 * @interface StartPageTileImageCreateDto
 */
export interface StartPageTileImageCreateDto {
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageCreateDto
     */
    'startPageTileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageCreateDto
     */
    'imageId'?: string;
}
/**
 * 
 * @export
 * @interface StartPageTileImageReadDto
 */
export interface StartPageTileImageReadDto {
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageReadDto
     */
    'startPageTileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageReadDto
     */
    'imageId'?: string;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface StartPageTileImageReadDtoPagedDtoList
 */
export interface StartPageTileImageReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<StartPageTileImageReadDto>}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'data': Array<StartPageTileImageReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof StartPageTileImageReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface StartPageTileImageUpdateDto
 */
export interface StartPageTileImageUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageUpdateDto
     */
    'startPageTileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileImageUpdateDto
     */
    'imageId'?: string;
}
/**
 * 
 * @export
 * @interface StartPageTileReadDto
 */
export interface StartPageTileReadDto {
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartPageTileReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {Array<ImageReadDto>}
     * @memberof StartPageTileReadDto
     */
    'images': Array<ImageReadDto>;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'tileCaption': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'tileBackgroundImageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof StartPageTileReadDto
     */
    'tileOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'practiceId': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'headline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileReadDto
     */
    'externalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartPageTileReadDto
     */
    'isLeading': boolean;
    /**
     * 
     * @type {StartPageTileSlot}
     * @memberof StartPageTileReadDto
     */
    'tileSlot'?: StartPageTileSlot;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface StartPageTileReadDtoPagedDtoList
 */
export interface StartPageTileReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<StartPageTileReadDto>}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'data': Array<StartPageTileReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof StartPageTileReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StartPageTileSlot = {
    Unasigned: 'Unasigned',
    Primary: 'Primary',
    TopLeft: 'TopLeft',
    TopRight: 'TopRight',
    BottomLeft: 'BottomLeft',
    BottomRight: 'BottomRight'
} as const;

export type StartPageTileSlot = typeof StartPageTileSlot[keyof typeof StartPageTileSlot];


/**
 * 
 * @export
 * @interface StartPageTileUpdateDto
 */
export interface StartPageTileUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof StartPageTileUpdateDto
     */
    'tileCaption': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileUpdateDto
     */
    'tileBackgroundImageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof StartPageTileUpdateDto
     */
    'tileOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileUpdateDto
     */
    'practiceId': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileUpdateDto
     */
    'headline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileUpdateDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof StartPageTileUpdateDto
     */
    'externalUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StartPageTileUpdateDto
     */
    'isLeading': boolean;
    /**
     * 
     * @type {StartPageTileSlot}
     * @memberof StartPageTileUpdateDto
     */
    'tileSlot'?: StartPageTileSlot;
}
/**
 * 
 * @export
 * @interface UserCreateDto
 */
export interface UserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    'practiceId'?: string | null;
}
/**
 * 
 * @export
 * @interface UserReadDto
 */
export interface UserReadDto {
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserReadDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {Array<DocumentReadDto>}
     * @memberof UserReadDto
     */
    'documents': Array<DocumentReadDto>;
    /**
     * 
     * @type {Array<ActivityReadDto>}
     * @memberof UserReadDto
     */
    'activities'?: Array<ActivityReadDto> | null;
    /**
     * 
     * @type {Array<BodyDataPointReadDto>}
     * @memberof UserReadDto
     */
    'bodyDataPoints': Array<BodyDataPointReadDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserReadDto
     */
    'coachingPlanIds': Array<string>;
    /**
     * 
     * @type {PracticeReadDto}
     * @memberof UserReadDto
     */
    'practice'?: PracticeReadDto;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    'practiceId'?: string | null;
}
/**
 * Wrapper Dto to hold the requested data and pagination information
 * @export
 * @interface UserReadDtoPagedDtoList
 */
export interface UserReadDtoPagedDtoList {
    /**
     * The paged payload
     * @type {Array<UserReadDto>}
     * @memberof UserReadDtoPagedDtoList
     */
    'data': Array<UserReadDto>;
    /**
     * The total count of the requested source.  <br>I.e.: SELECT COUNT(*) FROM TEntity
     * @type {number}
     * @memberof UserReadDtoPagedDtoList
     */
    'totalCount': number;
    /**
     * The requested page
     * @type {number}
     * @memberof UserReadDtoPagedDtoList
     */
    'currentPage': number;
    /**
     * The number of items shown on a page
     * @type {number}
     * @memberof UserReadDtoPagedDtoList
     */
    'pageSize': number;
    /**
     * The total number of pages, depending on Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.PageSize and Physio.WebApi.Controllers.Wrappers.PagedDtoList`1.TotalCount
     * @type {number}
     * @memberof UserReadDtoPagedDtoList
     */
    'totalPages': number;
    /**
     * True if there is at least one page before the requested page
     * @type {boolean}
     * @memberof UserReadDtoPagedDtoList
     */
    'hasPreviousPage': boolean;
    /**
     * True if there is at least one page after the requested page
     * @type {boolean}
     * @memberof UserReadDtoPagedDtoList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'practiceId'?: string | null;
}

/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activity GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivityIdDelete', 'id', id)
            const localVarPath = `/api/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivityIdGet', 'id', id)
            const localVarPath = `/api/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity update (PUT)
         * @param {string} id 
         * @param {ActivityUpdateDto} [activityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityIdPut: async (id: string, activityUpdateDto?: ActivityUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivityIdPut', 'id', id)
            const localVarPath = `/api/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activity create (POST)
         * @param {ActivityCreateDto} [activityCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityPost: async (activityCreateDto?: ActivityCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activity GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity update (PUT)
         * @param {string} id 
         * @param {ActivityUpdateDto} [activityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityIdPut(id: string, activityUpdateDto?: ActivityUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityIdPut(id, activityUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activity create (POST)
         * @param {ActivityCreateDto} [activityCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityPost(activityCreateDto?: ActivityCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityPost(activityCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Activity GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<ActivityReadDtoPagedDtoList> {
            return localVarFp.apiActivityGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiActivityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityIdGet(id: string, options?: any): AxiosPromise<ActivityReadDto> {
            return localVarFp.apiActivityIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity update (PUT)
         * @param {string} id 
         * @param {ActivityUpdateDto} [activityUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityIdPut(id: string, activityUpdateDto?: ActivityUpdateDto, options?: any): AxiosPromise<ActivityReadDto> {
            return localVarFp.apiActivityIdPut(id, activityUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activity create (POST)
         * @param {ActivityCreateDto} [activityCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityPost(activityCreateDto?: ActivityCreateDto, options?: any): AxiosPromise<ActivityReadDto> {
            return localVarFp.apiActivityPost(activityCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @summary Activity GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiActivityGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiActivityGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiActivityIdDelete(id: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiActivityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiActivityIdGet(id: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiActivityIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity update (PUT)
     * @param {string} id 
     * @param {ActivityUpdateDto} [activityUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiActivityIdPut(id: string, activityUpdateDto?: ActivityUpdateDto, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiActivityIdPut(id, activityUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activity create (POST)
     * @param {ActivityCreateDto} [activityCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public apiActivityPost(activityCreateDto?: ActivityCreateDto, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).apiActivityPost(activityCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityGroupApi - axios parameter creator
 * @export
 */
export const ActivityGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ActivityGroup GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ActivityGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ActivityGroup DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivityGroupIdDelete', 'id', id)
            const localVarPath = `/api/ActivityGroup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ActivityGroup GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivityGroupIdGet', 'id', id)
            const localVarPath = `/api/ActivityGroup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a TEntity and saves it to the repository
         * @param {string} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupIdPut: async (id: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivityGroupIdPut', 'id', id)
            const localVarPath = `/api/ActivityGroup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ActivityGroup create (POST)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ActivityGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityGroupApi - functional programming interface
 * @export
 */
export const ActivityGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ActivityGroup GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityGroupGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityGroupReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityGroupGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ActivityGroup DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityGroupIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityGroupIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ActivityGroup GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityGroupIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityGroupReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityGroupIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a TEntity and saves it to the repository
         * @param {string} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityGroupIdPut(id: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityGroupIdPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ActivityGroup create (POST)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivityGroupPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityGroupReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivityGroupPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityGroupApi - factory interface
 * @export
 */
export const ActivityGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary ActivityGroup GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<ActivityGroupReadDtoPagedDtoList> {
            return localVarFp.apiActivityGroupGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ActivityGroup DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiActivityGroupIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ActivityGroup GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupIdGet(id: string, options?: any): AxiosPromise<ActivityGroupReadDto> {
            return localVarFp.apiActivityGroupIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a TEntity and saves it to the repository
         * @param {string} id 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupIdPut(id: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.apiActivityGroupIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ActivityGroup create (POST)
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityGroupPost(body?: object, options?: any): AxiosPromise<ActivityGroupReadDto> {
            return localVarFp.apiActivityGroupPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityGroupApi - object-oriented interface
 * @export
 * @class ActivityGroupApi
 * @extends {BaseAPI}
 */
export class ActivityGroupApi extends BaseAPI {
    /**
     * 
     * @summary ActivityGroup GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public apiActivityGroupGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return ActivityGroupApiFp(this.configuration).apiActivityGroupGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ActivityGroup DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public apiActivityGroupIdDelete(id: string, options?: AxiosRequestConfig) {
        return ActivityGroupApiFp(this.configuration).apiActivityGroupIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ActivityGroup GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public apiActivityGroupIdGet(id: string, options?: AxiosRequestConfig) {
        return ActivityGroupApiFp(this.configuration).apiActivityGroupIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a TEntity and saves it to the repository
     * @param {string} id 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public apiActivityGroupIdPut(id: string, body?: object, options?: AxiosRequestConfig) {
        return ActivityGroupApiFp(this.configuration).apiActivityGroupIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ActivityGroup create (POST)
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public apiActivityGroupPost(body?: object, options?: AxiosRequestConfig) {
        return ActivityGroupApiFp(this.configuration).apiActivityGroupPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlogPostApi - axios parameter creator
 * @export
 */
export const BlogPostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary BlogPost GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BlogPost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BlogPost DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlogPostIdDelete', 'id', id)
            const localVarPath = `/api/BlogPost/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BlogPost GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlogPostIdGet', 'id', id)
            const localVarPath = `/api/BlogPost/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BlogPost update (PUT)
         * @param {string} id 
         * @param {BlogPostUpdateDto} [blogPostUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostIdPut: async (id: string, blogPostUpdateDto?: BlogPostUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlogPostIdPut', 'id', id)
            const localVarPath = `/api/BlogPost/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blogPostUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BlogPost create (POST)
         * @param {BlogPostCreateDto} [blogPostCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostPost: async (blogPostCreateDto?: BlogPostCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BlogPost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blogPostCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlogPostApi - functional programming interface
 * @export
 */
export const BlogPostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlogPostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary BlogPost GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlogPostGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlogPostGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BlogPost DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlogPostIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlogPostIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BlogPost GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlogPostIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlogPostIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BlogPost update (PUT)
         * @param {string} id 
         * @param {BlogPostUpdateDto} [blogPostUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlogPostIdPut(id: string, blogPostUpdateDto?: BlogPostUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlogPostIdPut(id, blogPostUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BlogPost create (POST)
         * @param {BlogPostCreateDto} [blogPostCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlogPostPost(blogPostCreateDto?: BlogPostCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlogPostPost(blogPostCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlogPostApi - factory interface
 * @export
 */
export const BlogPostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlogPostApiFp(configuration)
    return {
        /**
         * 
         * @summary BlogPost GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<BlogPostReadDtoPagedDtoList> {
            return localVarFp.apiBlogPostGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BlogPost DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBlogPostIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BlogPost GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostIdGet(id: string, options?: any): AxiosPromise<BlogPostReadDto> {
            return localVarFp.apiBlogPostIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BlogPost update (PUT)
         * @param {string} id 
         * @param {BlogPostUpdateDto} [blogPostUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostIdPut(id: string, blogPostUpdateDto?: BlogPostUpdateDto, options?: any): AxiosPromise<BlogPostReadDto> {
            return localVarFp.apiBlogPostIdPut(id, blogPostUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BlogPost create (POST)
         * @param {BlogPostCreateDto} [blogPostCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlogPostPost(blogPostCreateDto?: BlogPostCreateDto, options?: any): AxiosPromise<BlogPostReadDto> {
            return localVarFp.apiBlogPostPost(blogPostCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlogPostApi - object-oriented interface
 * @export
 * @class BlogPostApi
 * @extends {BaseAPI}
 */
export class BlogPostApi extends BaseAPI {
    /**
     * 
     * @summary BlogPost GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostApi
     */
    public apiBlogPostGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return BlogPostApiFp(this.configuration).apiBlogPostGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BlogPost DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostApi
     */
    public apiBlogPostIdDelete(id: string, options?: AxiosRequestConfig) {
        return BlogPostApiFp(this.configuration).apiBlogPostIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BlogPost GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostApi
     */
    public apiBlogPostIdGet(id: string, options?: AxiosRequestConfig) {
        return BlogPostApiFp(this.configuration).apiBlogPostIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BlogPost update (PUT)
     * @param {string} id 
     * @param {BlogPostUpdateDto} [blogPostUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostApi
     */
    public apiBlogPostIdPut(id: string, blogPostUpdateDto?: BlogPostUpdateDto, options?: AxiosRequestConfig) {
        return BlogPostApiFp(this.configuration).apiBlogPostIdPut(id, blogPostUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BlogPost create (POST)
     * @param {BlogPostCreateDto} [blogPostCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlogPostApi
     */
    public apiBlogPostPost(blogPostCreateDto?: BlogPostCreateDto, options?: AxiosRequestConfig) {
        return BlogPostApiFp(this.configuration).apiBlogPostPost(blogPostCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoachingPlanApi - axios parameter creator
 * @export
 */
export const CoachingPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CoachingPlan GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CoachingPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlan DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanIdDelete', 'id', id)
            const localVarPath = `/api/CoachingPlan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exercise GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdExercisesGet: async (id: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanIdExercisesGet', 'id', id)
            const localVarPath = `/api/CoachingPlan/{id}/Exercises`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlan GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanIdGet', 'id', id)
            const localVarPath = `/api/CoachingPlan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlan update (PUT)
         * @param {string} id 
         * @param {CoachingPlanUpdateDto} [coachingPlanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdPut: async (id: string, coachingPlanUpdateDto?: CoachingPlanUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanIdPut', 'id', id)
            const localVarPath = `/api/CoachingPlan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coachingPlanUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdUsersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanIdUsersGet', 'id', id)
            const localVarPath = `/api/CoachingPlan/{id}/Users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlan create (POST)
         * @param {CoachingPlanCreateDto} [coachingPlanCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanPost: async (coachingPlanCreateDto?: CoachingPlanCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CoachingPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coachingPlanCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoachingPlanApi - functional programming interface
 * @export
 */
export const CoachingPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoachingPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CoachingPlan GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlan DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exercise GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanIdExercisesGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanIdExercisesGet(id, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlan GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlan update (PUT)
         * @param {string} id 
         * @param {CoachingPlanUpdateDto} [coachingPlanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanIdPut(id: string, coachingPlanUpdateDto?: CoachingPlanUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanIdPut(id, coachingPlanUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanIdUsersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanIdUsersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlan create (POST)
         * @param {CoachingPlanCreateDto} [coachingPlanCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanPost(coachingPlanCreateDto?: CoachingPlanCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanPost(coachingPlanCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoachingPlanApi - factory interface
 * @export
 */
export const CoachingPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoachingPlanApiFp(configuration)
    return {
        /**
         * 
         * @summary CoachingPlan GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<CoachingPlanReadDtoPagedDtoList> {
            return localVarFp.apiCoachingPlanGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlan DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoachingPlanIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exercise GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdExercisesGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<ExerciseReadDtoPagedDtoList> {
            return localVarFp.apiCoachingPlanIdExercisesGet(id, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlan GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdGet(id: string, options?: any): AxiosPromise<CoachingPlanReadDto> {
            return localVarFp.apiCoachingPlanIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlan update (PUT)
         * @param {string} id 
         * @param {CoachingPlanUpdateDto} [coachingPlanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdPut(id: string, coachingPlanUpdateDto?: CoachingPlanUpdateDto, options?: any): AxiosPromise<CoachingPlanReadDto> {
            return localVarFp.apiCoachingPlanIdPut(id, coachingPlanUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanIdUsersGet(id: string, options?: any): AxiosPromise<UserReadDtoPagedDtoList> {
            return localVarFp.apiCoachingPlanIdUsersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlan create (POST)
         * @param {CoachingPlanCreateDto} [coachingPlanCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanPost(coachingPlanCreateDto?: CoachingPlanCreateDto, options?: any): AxiosPromise<CoachingPlanReadDto> {
            return localVarFp.apiCoachingPlanPost(coachingPlanCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoachingPlanApi - object-oriented interface
 * @export
 * @class CoachingPlanApi
 * @extends {BaseAPI}
 */
export class CoachingPlanApi extends BaseAPI {
    /**
     * 
     * @summary CoachingPlan GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlan DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanIdDelete(id: string, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exercise GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanIdExercisesGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanIdExercisesGet(id, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlan GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanIdGet(id: string, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlan update (PUT)
     * @param {string} id 
     * @param {CoachingPlanUpdateDto} [coachingPlanUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanIdPut(id: string, coachingPlanUpdateDto?: CoachingPlanUpdateDto, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanIdPut(id, coachingPlanUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User GET all by CoachingPlanId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanIdUsersGet(id: string, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanIdUsersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlan create (POST)
     * @param {CoachingPlanCreateDto} [coachingPlanCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanApi
     */
    public apiCoachingPlanPost(coachingPlanCreateDto?: CoachingPlanCreateDto, options?: AxiosRequestConfig) {
        return CoachingPlanApiFp(this.configuration).apiCoachingPlanPost(coachingPlanCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoachingPlanExerciseApi - axios parameter creator
 * @export
 */
export const CoachingPlanExerciseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CoachingPlanExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CoachingPlanExercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlanExercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanExerciseIdDelete', 'id', id)
            const localVarPath = `/api/CoachingPlanExercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlanExercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanExerciseIdGet', 'id', id)
            const localVarPath = `/api/CoachingPlanExercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlanExercise update (PUT)
         * @param {string} id 
         * @param {CoachingPlanExerciseUpdateDto} [coachingPlanExerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseIdPut: async (id: string, coachingPlanExerciseUpdateDto?: CoachingPlanExerciseUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoachingPlanExerciseIdPut', 'id', id)
            const localVarPath = `/api/CoachingPlanExercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coachingPlanExerciseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CoachingPlanExercise create (POST)
         * @param {CoachingPlanExerciseCreateDto} [coachingPlanExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExercisePost: async (coachingPlanExerciseCreateDto?: CoachingPlanExerciseCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CoachingPlanExercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coachingPlanExerciseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoachingPlanExerciseApi - functional programming interface
 * @export
 */
export const CoachingPlanExerciseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoachingPlanExerciseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CoachingPlanExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanExerciseGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlanExercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanExerciseIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanExerciseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlanExercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanExerciseIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanExerciseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlanExercise update (PUT)
         * @param {string} id 
         * @param {CoachingPlanExerciseUpdateDto} [coachingPlanExerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanExerciseIdPut(id: string, coachingPlanExerciseUpdateDto?: CoachingPlanExerciseUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanExerciseIdPut(id, coachingPlanExerciseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CoachingPlanExercise create (POST)
         * @param {CoachingPlanExerciseCreateDto} [coachingPlanExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoachingPlanExercisePost(coachingPlanExerciseCreateDto?: CoachingPlanExerciseCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoachingPlanExercisePost(coachingPlanExerciseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoachingPlanExerciseApi - factory interface
 * @export
 */
export const CoachingPlanExerciseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoachingPlanExerciseApiFp(configuration)
    return {
        /**
         * 
         * @summary CoachingPlanExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<CoachingPlanExerciseReadDtoPagedDtoList> {
            return localVarFp.apiCoachingPlanExerciseGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlanExercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoachingPlanExerciseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlanExercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseIdGet(id: string, options?: any): AxiosPromise<CoachingPlanExerciseReadDto> {
            return localVarFp.apiCoachingPlanExerciseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlanExercise update (PUT)
         * @param {string} id 
         * @param {CoachingPlanExerciseUpdateDto} [coachingPlanExerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExerciseIdPut(id: string, coachingPlanExerciseUpdateDto?: CoachingPlanExerciseUpdateDto, options?: any): AxiosPromise<CoachingPlanExerciseReadDto> {
            return localVarFp.apiCoachingPlanExerciseIdPut(id, coachingPlanExerciseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CoachingPlanExercise create (POST)
         * @param {CoachingPlanExerciseCreateDto} [coachingPlanExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoachingPlanExercisePost(coachingPlanExerciseCreateDto?: CoachingPlanExerciseCreateDto, options?: any): AxiosPromise<CoachingPlanExerciseReadDto> {
            return localVarFp.apiCoachingPlanExercisePost(coachingPlanExerciseCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoachingPlanExerciseApi - object-oriented interface
 * @export
 * @class CoachingPlanExerciseApi
 * @extends {BaseAPI}
 */
export class CoachingPlanExerciseApi extends BaseAPI {
    /**
     * 
     * @summary CoachingPlanExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanExerciseApi
     */
    public apiCoachingPlanExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return CoachingPlanExerciseApiFp(this.configuration).apiCoachingPlanExerciseGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlanExercise DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanExerciseApi
     */
    public apiCoachingPlanExerciseIdDelete(id: string, options?: AxiosRequestConfig) {
        return CoachingPlanExerciseApiFp(this.configuration).apiCoachingPlanExerciseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlanExercise GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanExerciseApi
     */
    public apiCoachingPlanExerciseIdGet(id: string, options?: AxiosRequestConfig) {
        return CoachingPlanExerciseApiFp(this.configuration).apiCoachingPlanExerciseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlanExercise update (PUT)
     * @param {string} id 
     * @param {CoachingPlanExerciseUpdateDto} [coachingPlanExerciseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanExerciseApi
     */
    public apiCoachingPlanExerciseIdPut(id: string, coachingPlanExerciseUpdateDto?: CoachingPlanExerciseUpdateDto, options?: AxiosRequestConfig) {
        return CoachingPlanExerciseApiFp(this.configuration).apiCoachingPlanExerciseIdPut(id, coachingPlanExerciseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CoachingPlanExercise create (POST)
     * @param {CoachingPlanExerciseCreateDto} [coachingPlanExerciseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachingPlanExerciseApi
     */
    public apiCoachingPlanExercisePost(coachingPlanExerciseCreateDto?: CoachingPlanExerciseCreateDto, options?: AxiosRequestConfig) {
        return CoachingPlanExerciseApiFp(this.configuration).apiCoachingPlanExercisePost(coachingPlanExerciseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Document File download (GET)
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDownloadDocumentIdGet: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiDocumentDownloadDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/api/Document/Download/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} [uploaderId] Filter by Physio.WebApi.Models.Entities.TransactionData.Document.UploaderId
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGet: async (uploaderId?: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (uploaderId !== undefined) {
                localVarQueryParameter['UploaderId'] = uploaderId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentIdDelete', 'id', id)
            const localVarPath = `/api/Document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentIdGet', 'id', id)
            const localVarPath = `/api/Document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document update (PUT)
         * @param {string} id 
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentIdPut: async (id: string, documentUpdateDto?: DocumentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentIdPut', 'id', id)
            const localVarPath = `/api/Document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document create (POST)
         * @param {DocumentCreateDto} [documentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentPost: async (documentCreateDto?: DocumentCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document File upload (POST)
         * @param {string} documentId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUploadDocumentIdPost: async (documentId: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiDocumentUploadDocumentIdPost', 'documentId', documentId)
            const localVarPath = `/api/Document/Upload/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Document GET by <seealso cref=\"T:Physio.WebApi.Models.Entities.TransactionData.User\" /> id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUserUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiDocumentUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/Document/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Document File download (GET)
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentDownloadDocumentIdGet(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentDownloadDocumentIdGet(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} [uploaderId] Filter by Physio.WebApi.Models.Entities.TransactionData.Document.UploaderId
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGet(uploaderId?: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGet(uploaderId, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document update (PUT)
         * @param {string} id 
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentIdPut(id: string, documentUpdateDto?: DocumentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentIdPut(id, documentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document create (POST)
         * @param {DocumentCreateDto} [documentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentPost(documentCreateDto?: DocumentCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentPost(documentCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document File upload (POST)
         * @param {string} documentId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentUploadDocumentIdPost(documentId: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentUploadDocumentIdPost(documentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Document GET by <seealso cref=\"T:Physio.WebApi.Models.Entities.TransactionData.User\" /> id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentUserUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Document File download (GET)
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDownloadDocumentIdGet(documentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiDocumentDownloadDocumentIdGet(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} [uploaderId] Filter by Physio.WebApi.Models.Entities.TransactionData.Document.UploaderId
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGet(uploaderId?: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<DocumentReadDtoPagedDtoList> {
            return localVarFp.apiDocumentGet(uploaderId, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentIdGet(id: string, options?: any): AxiosPromise<DocumentReadDto> {
            return localVarFp.apiDocumentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document update (PUT)
         * @param {string} id 
         * @param {DocumentUpdateDto} [documentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentIdPut(id: string, documentUpdateDto?: DocumentUpdateDto, options?: any): AxiosPromise<DocumentReadDto> {
            return localVarFp.apiDocumentIdPut(id, documentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document create (POST)
         * @param {DocumentCreateDto} [documentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentPost(documentCreateDto?: DocumentCreateDto, options?: any): AxiosPromise<DocumentReadDto> {
            return localVarFp.apiDocumentPost(documentCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document File upload (POST)
         * @param {string} documentId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUploadDocumentIdPost(documentId: string, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentUploadDocumentIdPost(documentId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Document GET by <seealso cref=\"T:Physio.WebApi.Models.Entities.TransactionData.User\" /> id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUserUserIdGet(userId: string, options?: any): AxiosPromise<DocumentReadDtoPagedDtoList> {
            return localVarFp.apiDocumentUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Document File download (GET)
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentDownloadDocumentIdGet(documentId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentDownloadDocumentIdGet(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {string} [uploaderId] Filter by Physio.WebApi.Models.Entities.TransactionData.Document.UploaderId
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGet(uploaderId?: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentGet(uploaderId, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentIdDelete(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentIdGet(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document update (PUT)
     * @param {string} id 
     * @param {DocumentUpdateDto} [documentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentIdPut(id: string, documentUpdateDto?: DocumentUpdateDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentIdPut(id, documentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document create (POST)
     * @param {DocumentCreateDto} [documentCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentPost(documentCreateDto?: DocumentCreateDto, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentPost(documentCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document File upload (POST)
     * @param {string} documentId 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentUploadDocumentIdPost(documentId: string, file?: File, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentUploadDocumentIdPost(documentId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Document GET by <seealso cref=\"T:Physio.WebApi.Models.Entities.TransactionData.User\" /> id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentUserUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).apiDocumentUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentCategoryApi - axios parameter creator
 * @export
 */
export const DocumentCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary DocumentCategory GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DocumentCategory DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentCategoryIdDelete', 'id', id)
            const localVarPath = `/api/DocumentCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DocumentCategory GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentCategoryIdGet', 'id', id)
            const localVarPath = `/api/DocumentCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DocumentCategory update (PUT)
         * @param {string} id 
         * @param {DocumentCategoryUpdateDto} [documentCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryIdPut: async (id: string, documentCategoryUpdateDto?: DocumentCategoryUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDocumentCategoryIdPut', 'id', id)
            const localVarPath = `/api/DocumentCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCategoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DocumentCategory create (POST)
         * @param {DocumentCategoryCreateDto} [documentCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryPost: async (documentCategoryCreateDto?: DocumentCategoryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCategoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentCategoryApi - functional programming interface
 * @export
 */
export const DocumentCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary DocumentCategory GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCategoryGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCategoryReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCategoryGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DocumentCategory DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCategoryIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCategoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DocumentCategory GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCategoryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCategoryReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCategoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DocumentCategory update (PUT)
         * @param {string} id 
         * @param {DocumentCategoryUpdateDto} [documentCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCategoryIdPut(id: string, documentCategoryUpdateDto?: DocumentCategoryUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCategoryReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCategoryIdPut(id, documentCategoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DocumentCategory create (POST)
         * @param {DocumentCategoryCreateDto} [documentCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCategoryPost(documentCategoryCreateDto?: DocumentCategoryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCategoryReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCategoryPost(documentCategoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentCategoryApi - factory interface
 * @export
 */
export const DocumentCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentCategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary DocumentCategory GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<DocumentCategoryReadDtoPagedDtoList> {
            return localVarFp.apiDocumentCategoryGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DocumentCategory DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocumentCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DocumentCategory GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryIdGet(id: string, options?: any): AxiosPromise<DocumentCategoryReadDto> {
            return localVarFp.apiDocumentCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DocumentCategory update (PUT)
         * @param {string} id 
         * @param {DocumentCategoryUpdateDto} [documentCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryIdPut(id: string, documentCategoryUpdateDto?: DocumentCategoryUpdateDto, options?: any): AxiosPromise<DocumentCategoryReadDto> {
            return localVarFp.apiDocumentCategoryIdPut(id, documentCategoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DocumentCategory create (POST)
         * @param {DocumentCategoryCreateDto} [documentCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCategoryPost(documentCategoryCreateDto?: DocumentCategoryCreateDto, options?: any): AxiosPromise<DocumentCategoryReadDto> {
            return localVarFp.apiDocumentCategoryPost(documentCategoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentCategoryApi - object-oriented interface
 * @export
 * @class DocumentCategoryApi
 * @extends {BaseAPI}
 */
export class DocumentCategoryApi extends BaseAPI {
    /**
     * 
     * @summary DocumentCategory GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCategoryApi
     */
    public apiDocumentCategoryGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return DocumentCategoryApiFp(this.configuration).apiDocumentCategoryGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DocumentCategory DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCategoryApi
     */
    public apiDocumentCategoryIdDelete(id: string, options?: AxiosRequestConfig) {
        return DocumentCategoryApiFp(this.configuration).apiDocumentCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DocumentCategory GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCategoryApi
     */
    public apiDocumentCategoryIdGet(id: string, options?: AxiosRequestConfig) {
        return DocumentCategoryApiFp(this.configuration).apiDocumentCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DocumentCategory update (PUT)
     * @param {string} id 
     * @param {DocumentCategoryUpdateDto} [documentCategoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCategoryApi
     */
    public apiDocumentCategoryIdPut(id: string, documentCategoryUpdateDto?: DocumentCategoryUpdateDto, options?: AxiosRequestConfig) {
        return DocumentCategoryApiFp(this.configuration).apiDocumentCategoryIdPut(id, documentCategoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DocumentCategory create (POST)
     * @param {DocumentCategoryCreateDto} [documentCategoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCategoryApi
     */
    public apiDocumentCategoryPost(documentCategoryCreateDto?: DocumentCategoryCreateDto, options?: AxiosRequestConfig) {
        return DocumentCategoryApiFp(this.configuration).apiDocumentCategoryPost(documentCategoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExerciseApi - axios parameter creator
 * @export
 */
export const ExerciseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExerciseIdDelete', 'id', id)
            const localVarPath = `/api/Exercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExerciseIdGet', 'id', id)
            const localVarPath = `/api/Exercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Practices GET all by ExerciseId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdPracticesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExerciseIdPracticesGet', 'id', id)
            const localVarPath = `/api/Exercise/{id}/Practices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exercise update (PUT)
         * @param {string} id 
         * @param {ExerciseUpdateDto} [exerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdPut: async (id: string, exerciseUpdateDto?: ExerciseUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiExerciseIdPut', 'id', id)
            const localVarPath = `/api/Exercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exerciseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exercise create (POST)
         * @param {ExerciseCreateDto} [exerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisePost: async (exerciseCreateDto?: ExerciseCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Exercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exerciseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExerciseApi - functional programming interface
 * @export
 */
export const ExerciseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExerciseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExerciseGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExerciseIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExerciseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExerciseIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExerciseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Practices GET all by ExerciseId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExerciseIdPracticesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExerciseIdPracticesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exercise update (PUT)
         * @param {string} id 
         * @param {ExerciseUpdateDto} [exerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExerciseIdPut(id: string, exerciseUpdateDto?: ExerciseUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExerciseIdPut(id, exerciseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exercise create (POST)
         * @param {ExerciseCreateDto} [exerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExercisePost(exerciseCreateDto?: ExerciseCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExercisePost(exerciseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExerciseApi - factory interface
 * @export
 */
export const ExerciseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExerciseApiFp(configuration)
    return {
        /**
         * 
         * @summary Exercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<ExerciseReadDtoPagedDtoList> {
            return localVarFp.apiExerciseGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiExerciseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdGet(id: string, options?: any): AxiosPromise<ExerciseReadDto> {
            return localVarFp.apiExerciseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Practices GET all by ExerciseId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdPracticesGet(id: string, options?: any): AxiosPromise<PracticeReadDtoPagedDtoList> {
            return localVarFp.apiExerciseIdPracticesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exercise update (PUT)
         * @param {string} id 
         * @param {ExerciseUpdateDto} [exerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExerciseIdPut(id: string, exerciseUpdateDto?: ExerciseUpdateDto, options?: any): AxiosPromise<ExerciseReadDto> {
            return localVarFp.apiExerciseIdPut(id, exerciseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exercise create (POST)
         * @param {ExerciseCreateDto} [exerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisePost(exerciseCreateDto?: ExerciseCreateDto, options?: any): AxiosPromise<ExerciseReadDto> {
            return localVarFp.apiExercisePost(exerciseCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExerciseApi - object-oriented interface
 * @export
 * @class ExerciseApi
 * @extends {BaseAPI}
 */
export class ExerciseApi extends BaseAPI {
    /**
     * 
     * @summary Exercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExerciseApi
     */
    public apiExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return ExerciseApiFp(this.configuration).apiExerciseGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exercise DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExerciseApi
     */
    public apiExerciseIdDelete(id: string, options?: AxiosRequestConfig) {
        return ExerciseApiFp(this.configuration).apiExerciseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exercise GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExerciseApi
     */
    public apiExerciseIdGet(id: string, options?: AxiosRequestConfig) {
        return ExerciseApiFp(this.configuration).apiExerciseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Practices GET all by ExerciseId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExerciseApi
     */
    public apiExerciseIdPracticesGet(id: string, options?: AxiosRequestConfig) {
        return ExerciseApiFp(this.configuration).apiExerciseIdPracticesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exercise update (PUT)
     * @param {string} id 
     * @param {ExerciseUpdateDto} [exerciseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExerciseApi
     */
    public apiExerciseIdPut(id: string, exerciseUpdateDto?: ExerciseUpdateDto, options?: AxiosRequestConfig) {
        return ExerciseApiFp(this.configuration).apiExerciseIdPut(id, exerciseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exercise create (POST)
     * @param {ExerciseCreateDto} [exerciseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExerciseApi
     */
    public apiExercisePost(exerciseCreateDto?: ExerciseCreateDto, options?: AxiosRequestConfig) {
        return ExerciseApiFp(this.configuration).apiExercisePost(exerciseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlavorApi - axios parameter creator
 * @export
 */
export const FlavorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Flavor GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Flavor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Flavor DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFlavorIdDelete', 'id', id)
            const localVarPath = `/api/Flavor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Flavor GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFlavorIdGet', 'id', id)
            const localVarPath = `/api/Flavor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Flavor update (PUT)
         * @param {string} id 
         * @param {FlavorUpdateDto} [flavorUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorIdPut: async (id: string, flavorUpdateDto?: FlavorUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFlavorIdPut', 'id', id)
            const localVarPath = `/api/Flavor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flavorUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Flavor create (POST)
         * @param {FlavorCreateDto} [flavorCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorPost: async (flavorCreateDto?: FlavorCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Flavor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(flavorCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlavorApi - functional programming interface
 * @export
 */
export const FlavorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlavorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Flavor GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlavorGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlavorReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlavorGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Flavor DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlavorIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlavorIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Flavor GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlavorIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlavorReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlavorIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Flavor update (PUT)
         * @param {string} id 
         * @param {FlavorUpdateDto} [flavorUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlavorIdPut(id: string, flavorUpdateDto?: FlavorUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlavorReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlavorIdPut(id, flavorUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Flavor create (POST)
         * @param {FlavorCreateDto} [flavorCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFlavorPost(flavorCreateDto?: FlavorCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlavorReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlavorPost(flavorCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlavorApi - factory interface
 * @export
 */
export const FlavorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlavorApiFp(configuration)
    return {
        /**
         * 
         * @summary Flavor GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<FlavorReadDtoPagedDtoList> {
            return localVarFp.apiFlavorGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Flavor DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFlavorIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Flavor GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorIdGet(id: string, options?: any): AxiosPromise<FlavorReadDto> {
            return localVarFp.apiFlavorIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Flavor update (PUT)
         * @param {string} id 
         * @param {FlavorUpdateDto} [flavorUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorIdPut(id: string, flavorUpdateDto?: FlavorUpdateDto, options?: any): AxiosPromise<FlavorReadDto> {
            return localVarFp.apiFlavorIdPut(id, flavorUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Flavor create (POST)
         * @param {FlavorCreateDto} [flavorCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFlavorPost(flavorCreateDto?: FlavorCreateDto, options?: any): AxiosPromise<FlavorReadDto> {
            return localVarFp.apiFlavorPost(flavorCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlavorApi - object-oriented interface
 * @export
 * @class FlavorApi
 * @extends {BaseAPI}
 */
export class FlavorApi extends BaseAPI {
    /**
     * 
     * @summary Flavor GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlavorApi
     */
    public apiFlavorGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return FlavorApiFp(this.configuration).apiFlavorGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Flavor DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlavorApi
     */
    public apiFlavorIdDelete(id: string, options?: AxiosRequestConfig) {
        return FlavorApiFp(this.configuration).apiFlavorIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Flavor GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlavorApi
     */
    public apiFlavorIdGet(id: string, options?: AxiosRequestConfig) {
        return FlavorApiFp(this.configuration).apiFlavorIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Flavor update (PUT)
     * @param {string} id 
     * @param {FlavorUpdateDto} [flavorUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlavorApi
     */
    public apiFlavorIdPut(id: string, flavorUpdateDto?: FlavorUpdateDto, options?: AxiosRequestConfig) {
        return FlavorApiFp(this.configuration).apiFlavorIdPut(id, flavorUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Flavor create (POST)
     * @param {FlavorCreateDto} [flavorCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlavorApi
     */
    public apiFlavorPost(flavorCreateDto?: FlavorCreateDto, options?: AxiosRequestConfig) {
        return FlavorApiFp(this.configuration).apiFlavorPost(flavorCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Image GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Image DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdDelete', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Image GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdGet', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Image update (PUT)
         * @param {string} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPut: async (id: string, imageUpdateDto?: ImageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdPut', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Image create (POST)
         * @param {ImageCreateDto} [imageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImagePost: async (imageCreateDto?: ImageCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Image GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Image DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Image GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Image update (PUT)
         * @param {string} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdPut(id: string, imageUpdateDto?: ImageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdPut(id, imageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Image create (POST)
         * @param {ImageCreateDto} [imageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImagePost(imageCreateDto?: ImageCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImagePost(imageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * 
         * @summary Image GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<ImageReadDtoPagedDtoList> {
            return localVarFp.apiImageGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Image DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiImageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Image GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdGet(id: string, options?: any): AxiosPromise<ImageReadDto> {
            return localVarFp.apiImageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Image update (PUT)
         * @param {string} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPut(id: string, imageUpdateDto?: ImageUpdateDto, options?: any): AxiosPromise<ImageReadDto> {
            return localVarFp.apiImageIdPut(id, imageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Image create (POST)
         * @param {ImageCreateDto} [imageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImagePost(imageCreateDto?: ImageCreateDto, options?: any): AxiosPromise<ImageReadDto> {
            return localVarFp.apiImagePost(imageCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @summary Image GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiImageGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Image DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdDelete(id: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiImageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Image GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdGet(id: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiImageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Image update (PUT)
     * @param {string} id 
     * @param {ImageUpdateDto} [imageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdPut(id: string, imageUpdateDto?: ImageUpdateDto, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiImageIdPut(id, imageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Image create (POST)
     * @param {ImageCreateDto} [imageCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImagePost(imageCreateDto?: ImageCreateDto, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).apiImagePost(imageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Message GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Message DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessageIdDelete', 'id', id)
            const localVarPath = `/api/Message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Message GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessageIdGet', 'id', id)
            const localVarPath = `/api/Message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Message update (PUT)
         * @param {string} id 
         * @param {MessageUpdateDto} [messageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageIdPut: async (id: string, messageUpdateDto?: MessageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessageIdPut', 'id', id)
            const localVarPath = `/api/Message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Message create (POST)
         * @param {MessageCreateDto} [messageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagePost: async (messageCreateDto?: MessageCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Message GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Message DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Message GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Message update (PUT)
         * @param {string} id 
         * @param {MessageUpdateDto} [messageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessageIdPut(id: string, messageUpdateDto?: MessageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessageIdPut(id, messageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Message create (POST)
         * @param {MessageCreateDto} [messageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagePost(messageCreateDto?: MessageCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagePost(messageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Message GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<MessageReadDtoPagedDtoList> {
            return localVarFp.apiMessageGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Message DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMessageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Message GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageIdGet(id: string, options?: any): AxiosPromise<MessageReadDto> {
            return localVarFp.apiMessageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Message update (PUT)
         * @param {string} id 
         * @param {MessageUpdateDto} [messageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessageIdPut(id: string, messageUpdateDto?: MessageUpdateDto, options?: any): AxiosPromise<MessageReadDto> {
            return localVarFp.apiMessageIdPut(id, messageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Message create (POST)
         * @param {MessageCreateDto} [messageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagePost(messageCreateDto?: MessageCreateDto, options?: any): AxiosPromise<MessageReadDto> {
            return localVarFp.apiMessagePost(messageCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Message GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Message DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageIdDelete(id: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Message GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageIdGet(id: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Message update (PUT)
     * @param {string} id 
     * @param {MessageUpdateDto} [messageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessageIdPut(id: string, messageUpdateDto?: MessageUpdateDto, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessageIdPut(id, messageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Message create (POST)
     * @param {MessageCreateDto} [messageCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagePost(messageCreateDto?: MessageCreateDto, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagePost(messageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PracticeApi - axios parameter creator
 * @export
 */
export const PracticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Current Practice (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeCurrentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Practice/Current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an assigned Exercise from the Practice\'s Exercises (DELETE)
         * @param {string} id 
         * @param {string} [practiceExerciseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdDelete: async (id: string, practiceExerciseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeExerciseIdDelete', 'id', id)
            const localVarPath = `/api/Practice/Exercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (practiceExerciseId !== undefined) {
                localVarQueryParameter['practiceExerciseId'] = practiceExerciseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds an existing Exercise to the Practice\'s Exercises (POST)
         * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExercisePost: async (practiceExerciseCreateDto?: PracticeExerciseCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Practice/Exercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(practiceExerciseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Practice GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Practice DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeIdDelete', 'id', id)
            const localVarPath = `/api/Practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exercise GET all by PracticeId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdExercisesGet: async (id: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeIdExercisesGet', 'id', id)
            const localVarPath = `/api/Practice/{id}/Exercises`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Practice GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeIdGet', 'id', id)
            const localVarPath = `/api/Practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Practice update (PUT)
         * @param {string} id 
         * @param {PracticeUpdateDto} [practiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdPut: async (id: string, practiceUpdateDto?: PracticeUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeIdPut', 'id', id)
            const localVarPath = `/api/Practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(practiceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users GET by Practice id
         * @param {string} practiceId 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdUsersGet: async (practiceId: string, id: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            assertParamExists('apiPracticeIdUsersGet', 'practiceId', practiceId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeIdUsersGet', 'id', id)
            const localVarPath = `/api/Practice/{id}/Users`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Practice create (POST)
         * @param {PracticeCreateDto} [practiceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticePost: async (practiceCreateDto?: PracticeCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(practiceCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeApi - functional programming interface
 * @export
 */
export const PracticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Current Practice (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeCurrentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeCurrentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an assigned Exercise from the Practice\'s Exercises (DELETE)
         * @param {string} id 
         * @param {string} [practiceExerciseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExerciseIdDelete(id: string, practiceExerciseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExerciseIdDelete(id, practiceExerciseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds an existing Exercise to the Practice\'s Exercises (POST)
         * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExercisePost(practiceExerciseCreateDto?: PracticeExerciseCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExercisePost(practiceExerciseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Practice GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Practice DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exercise GET all by PracticeId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeIdExercisesGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeIdExercisesGet(id, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Practice GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Practice update (PUT)
         * @param {string} id 
         * @param {PracticeUpdateDto} [practiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeIdPut(id: string, practiceUpdateDto?: PracticeUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeIdPut(id, practiceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users GET by Practice id
         * @param {string} practiceId 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeIdUsersGet(practiceId: string, id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeIdUsersGet(practiceId, id, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Practice create (POST)
         * @param {PracticeCreateDto} [practiceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticePost(practiceCreateDto?: PracticeCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticePost(practiceCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PracticeApi - factory interface
 * @export
 */
export const PracticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticeApiFp(configuration)
    return {
        /**
         * 
         * @summary Current Practice (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeCurrentGet(options?: any): AxiosPromise<PracticeReadDto> {
            return localVarFp.apiPracticeCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an assigned Exercise from the Practice\'s Exercises (DELETE)
         * @param {string} id 
         * @param {string} [practiceExerciseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdDelete(id: string, practiceExerciseId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPracticeExerciseIdDelete(id, practiceExerciseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds an existing Exercise to the Practice\'s Exercises (POST)
         * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExercisePost(practiceExerciseCreateDto?: PracticeExerciseCreateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiPracticeExercisePost(practiceExerciseCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Practice GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<PracticeReadDtoPagedDtoList> {
            return localVarFp.apiPracticeGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Practice DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPracticeIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exercise GET all by PracticeId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdExercisesGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<ExerciseReadDtoPagedDtoList> {
            return localVarFp.apiPracticeIdExercisesGet(id, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Practice GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdGet(id: string, options?: any): AxiosPromise<PracticeReadDto> {
            return localVarFp.apiPracticeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Practice update (PUT)
         * @param {string} id 
         * @param {PracticeUpdateDto} [practiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdPut(id: string, practiceUpdateDto?: PracticeUpdateDto, options?: any): AxiosPromise<PracticeReadDto> {
            return localVarFp.apiPracticeIdPut(id, practiceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users GET by Practice id
         * @param {string} practiceId 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeIdUsersGet(practiceId: string, id: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<UserReadDtoPagedDtoList> {
            return localVarFp.apiPracticeIdUsersGet(practiceId, id, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Practice create (POST)
         * @param {PracticeCreateDto} [practiceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticePost(practiceCreateDto?: PracticeCreateDto, options?: any): AxiosPromise<PracticeReadDto> {
            return localVarFp.apiPracticePost(practiceCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeApi - object-oriented interface
 * @export
 * @class PracticeApi
 * @extends {BaseAPI}
 */
export class PracticeApi extends BaseAPI {
    /**
     * 
     * @summary Current Practice (GET)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeCurrentGet(options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an assigned Exercise from the Practice\'s Exercises (DELETE)
     * @param {string} id 
     * @param {string} [practiceExerciseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeExerciseIdDelete(id: string, practiceExerciseId?: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeExerciseIdDelete(id, practiceExerciseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds an existing Exercise to the Practice\'s Exercises (POST)
     * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeExercisePost(practiceExerciseCreateDto?: PracticeExerciseCreateDto, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeExercisePost(practiceExerciseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Practice GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Practice DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeIdDelete(id: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exercise GET all by PracticeId  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeIdExercisesGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeIdExercisesGet(id, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Practice GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeIdGet(id: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Practice update (PUT)
     * @param {string} id 
     * @param {PracticeUpdateDto} [practiceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeIdPut(id: string, practiceUpdateDto?: PracticeUpdateDto, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeIdPut(id, practiceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users GET by Practice id
     * @param {string} practiceId 
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticeIdUsersGet(practiceId: string, id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticeIdUsersGet(practiceId, id, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Practice create (POST)
     * @param {PracticeCreateDto} [practiceCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public apiPracticePost(practiceCreateDto?: PracticeCreateDto, options?: AxiosRequestConfig) {
        return PracticeApiFp(this.configuration).apiPracticePost(practiceCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PracticeExerciseApi - axios parameter creator
 * @export
 */
export const PracticeExerciseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary PracticeExercise GET by Exercise id
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseExerciseIdGet: async (id: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeExerciseExerciseIdGet', 'id', id)
            const localVarPath = `/api/PracticeExercise/Exercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PracticeExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PracticeExercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PracticeExercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeExerciseIdDelete', 'id', id)
            const localVarPath = `/api/PracticeExercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PracticeExercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeExerciseIdGet', 'id', id)
            const localVarPath = `/api/PracticeExercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PracticeExercise update (PUT)
         * @param {string} id 
         * @param {PracticeExerciseUpdateDto} [practiceExerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdPut: async (id: string, practiceExerciseUpdateDto?: PracticeExerciseUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeExerciseIdPut', 'id', id)
            const localVarPath = `/api/PracticeExercise/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(practiceExerciseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PracticeExercise create (POST)
         * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExercisePost: async (practiceExerciseCreateDto?: PracticeExerciseCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PracticeExercise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(practiceExerciseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary PracticeExercise GET by Practice id
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExercisePracticeIdGet: async (id: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPracticeExercisePracticeIdGet', 'id', id)
            const localVarPath = `/api/PracticeExercise/Practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeExerciseApi - functional programming interface
 * @export
 */
export const PracticeExerciseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticeExerciseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary PracticeExercise GET by Exercise id
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExerciseExerciseIdGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExerciseExerciseIdGet(id, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PracticeExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExerciseGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PracticeExercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExerciseIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExerciseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PracticeExercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExerciseIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExerciseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PracticeExercise update (PUT)
         * @param {string} id 
         * @param {PracticeExerciseUpdateDto} [practiceExerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExerciseIdPut(id: string, practiceExerciseUpdateDto?: PracticeExerciseUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExerciseIdPut(id, practiceExerciseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PracticeExercise create (POST)
         * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExercisePost(practiceExerciseCreateDto?: PracticeExerciseCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeExerciseReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExercisePost(practiceExerciseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary PracticeExercise GET by Practice id
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPracticeExercisePracticeIdGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeExerciseReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPracticeExercisePracticeIdGet(id, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PracticeExerciseApi - factory interface
 * @export
 */
export const PracticeExerciseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticeExerciseApiFp(configuration)
    return {
        /**
         * 
         * @summary PracticeExercise GET by Exercise id
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseExerciseIdGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<PracticeExerciseReadDtoPagedDtoList> {
            return localVarFp.apiPracticeExerciseExerciseIdGet(id, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PracticeExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<PracticeExerciseReadDtoPagedDtoList> {
            return localVarFp.apiPracticeExerciseGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PracticeExercise DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPracticeExerciseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PracticeExercise GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdGet(id: string, options?: any): AxiosPromise<PracticeExerciseReadDto> {
            return localVarFp.apiPracticeExerciseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PracticeExercise update (PUT)
         * @param {string} id 
         * @param {PracticeExerciseUpdateDto} [practiceExerciseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExerciseIdPut(id: string, practiceExerciseUpdateDto?: PracticeExerciseUpdateDto, options?: any): AxiosPromise<PracticeExerciseReadDto> {
            return localVarFp.apiPracticeExerciseIdPut(id, practiceExerciseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PracticeExercise create (POST)
         * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExercisePost(practiceExerciseCreateDto?: PracticeExerciseCreateDto, options?: any): AxiosPromise<PracticeExerciseReadDto> {
            return localVarFp.apiPracticeExercisePost(practiceExerciseCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary PracticeExercise GET by Practice id
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPracticeExercisePracticeIdGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<PracticeExerciseReadDtoPagedDtoList> {
            return localVarFp.apiPracticeExercisePracticeIdGet(id, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeExerciseApi - object-oriented interface
 * @export
 * @class PracticeExerciseApi
 * @extends {BaseAPI}
 */
export class PracticeExerciseApi extends BaseAPI {
    /**
     * 
     * @summary PracticeExercise GET by Exercise id
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExerciseExerciseIdGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExerciseExerciseIdGet(id, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PracticeExercise GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExerciseGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExerciseGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PracticeExercise DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExerciseIdDelete(id: string, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExerciseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PracticeExercise GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExerciseIdGet(id: string, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExerciseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PracticeExercise update (PUT)
     * @param {string} id 
     * @param {PracticeExerciseUpdateDto} [practiceExerciseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExerciseIdPut(id: string, practiceExerciseUpdateDto?: PracticeExerciseUpdateDto, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExerciseIdPut(id, practiceExerciseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PracticeExercise create (POST)
     * @param {PracticeExerciseCreateDto} [practiceExerciseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExercisePost(practiceExerciseCreateDto?: PracticeExerciseCreateDto, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExercisePost(practiceExerciseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary PracticeExercise GET by Practice id
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeExerciseApi
     */
    public apiPracticeExercisePracticeIdGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return PracticeExerciseApiFp(this.configuration).apiPracticeExercisePracticeIdGet(id, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StartPageTileApi - axios parameter creator
 * @export
 */
export const StartPageTileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary StartPageTile GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StartPageTile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTile DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStartPageTileIdDelete', 'id', id)
            const localVarPath = `/api/StartPageTile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTile GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStartPageTileIdGet', 'id', id)
            const localVarPath = `/api/StartPageTile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTile update (PUT)
         * @param {string} id 
         * @param {StartPageTileUpdateDto} [startPageTileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileIdPut: async (id: string, startPageTileUpdateDto?: StartPageTileUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStartPageTileIdPut', 'id', id)
            const localVarPath = `/api/StartPageTile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPageTileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTile create (POST)
         * @param {StartPageTileCreateDto} [startPageTileCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTilePost: async (startPageTileCreateDto?: StartPageTileCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StartPageTile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPageTileCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StartPageTileApi - functional programming interface
 * @export
 */
export const StartPageTileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StartPageTileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary StartPageTile GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTile DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTile GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTile update (PUT)
         * @param {string} id 
         * @param {StartPageTileUpdateDto} [startPageTileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileIdPut(id: string, startPageTileUpdateDto?: StartPageTileUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileIdPut(id, startPageTileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTile create (POST)
         * @param {StartPageTileCreateDto} [startPageTileCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTilePost(startPageTileCreateDto?: StartPageTileCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTilePost(startPageTileCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StartPageTileApi - factory interface
 * @export
 */
export const StartPageTileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StartPageTileApiFp(configuration)
    return {
        /**
         * 
         * @summary StartPageTile GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<StartPageTileReadDtoPagedDtoList> {
            return localVarFp.apiStartPageTileGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTile DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiStartPageTileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTile GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileIdGet(id: string, options?: any): AxiosPromise<StartPageTileReadDto> {
            return localVarFp.apiStartPageTileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTile update (PUT)
         * @param {string} id 
         * @param {StartPageTileUpdateDto} [startPageTileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileIdPut(id: string, startPageTileUpdateDto?: StartPageTileUpdateDto, options?: any): AxiosPromise<StartPageTileReadDto> {
            return localVarFp.apiStartPageTileIdPut(id, startPageTileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTile create (POST)
         * @param {StartPageTileCreateDto} [startPageTileCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTilePost(startPageTileCreateDto?: StartPageTileCreateDto, options?: any): AxiosPromise<StartPageTileReadDto> {
            return localVarFp.apiStartPageTilePost(startPageTileCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StartPageTileApi - object-oriented interface
 * @export
 * @class StartPageTileApi
 * @extends {BaseAPI}
 */
export class StartPageTileApi extends BaseAPI {
    /**
     * 
     * @summary StartPageTile GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileApi
     */
    public apiStartPageTileGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return StartPageTileApiFp(this.configuration).apiStartPageTileGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTile DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileApi
     */
    public apiStartPageTileIdDelete(id: string, options?: AxiosRequestConfig) {
        return StartPageTileApiFp(this.configuration).apiStartPageTileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTile GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileApi
     */
    public apiStartPageTileIdGet(id: string, options?: AxiosRequestConfig) {
        return StartPageTileApiFp(this.configuration).apiStartPageTileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTile update (PUT)
     * @param {string} id 
     * @param {StartPageTileUpdateDto} [startPageTileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileApi
     */
    public apiStartPageTileIdPut(id: string, startPageTileUpdateDto?: StartPageTileUpdateDto, options?: AxiosRequestConfig) {
        return StartPageTileApiFp(this.configuration).apiStartPageTileIdPut(id, startPageTileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTile create (POST)
     * @param {StartPageTileCreateDto} [startPageTileCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileApi
     */
    public apiStartPageTilePost(startPageTileCreateDto?: StartPageTileCreateDto, options?: AxiosRequestConfig) {
        return StartPageTileApiFp(this.configuration).apiStartPageTilePost(startPageTileCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StartPageTileImageApi - axios parameter creator
 * @export
 */
export const StartPageTileImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary StartPageTileImage GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StartPageTileImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTileImage DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStartPageTileImageIdDelete', 'id', id)
            const localVarPath = `/api/StartPageTileImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTileImage GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStartPageTileImageIdGet', 'id', id)
            const localVarPath = `/api/StartPageTileImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a TEntity and saves it to the repository
         * @param {string} id 
         * @param {StartPageTileImageUpdateDto} [startPageTileImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageIdPut: async (id: string, startPageTileImageUpdateDto?: StartPageTileImageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStartPageTileImageIdPut', 'id', id)
            const localVarPath = `/api/StartPageTileImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPageTileImageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary StartPageTileImage create (POST)
         * @param {StartPageTileImageCreateDto} [startPageTileImageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImagePost: async (startPageTileImageCreateDto?: StartPageTileImageCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StartPageTileImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPageTileImageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StartPageTileImageApi - functional programming interface
 * @export
 */
export const StartPageTileImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StartPageTileImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary StartPageTileImage GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileImageGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileImageReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileImageGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTileImage DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileImageIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileImageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTileImage GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileImageIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileImageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileImageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a TEntity and saves it to the repository
         * @param {string} id 
         * @param {StartPageTileImageUpdateDto} [startPageTileImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileImageIdPut(id: string, startPageTileImageUpdateDto?: StartPageTileImageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileImageIdPut(id, startPageTileImageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary StartPageTileImage create (POST)
         * @param {StartPageTileImageCreateDto} [startPageTileImageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStartPageTileImagePost(startPageTileImageCreateDto?: StartPageTileImageCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPageTileImageReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStartPageTileImagePost(startPageTileImageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StartPageTileImageApi - factory interface
 * @export
 */
export const StartPageTileImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StartPageTileImageApiFp(configuration)
    return {
        /**
         * 
         * @summary StartPageTileImage GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<StartPageTileImageReadDtoPagedDtoList> {
            return localVarFp.apiStartPageTileImageGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTileImage DELETE by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiStartPageTileImageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTileImage GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageIdGet(id: string, options?: any): AxiosPromise<StartPageTileImageReadDto> {
            return localVarFp.apiStartPageTileImageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a TEntity and saves it to the repository
         * @param {string} id 
         * @param {StartPageTileImageUpdateDto} [startPageTileImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImageIdPut(id: string, startPageTileImageUpdateDto?: StartPageTileImageUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiStartPageTileImageIdPut(id, startPageTileImageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary StartPageTileImage create (POST)
         * @param {StartPageTileImageCreateDto} [startPageTileImageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStartPageTileImagePost(startPageTileImageCreateDto?: StartPageTileImageCreateDto, options?: any): AxiosPromise<StartPageTileImageReadDto> {
            return localVarFp.apiStartPageTileImagePost(startPageTileImageCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StartPageTileImageApi - object-oriented interface
 * @export
 * @class StartPageTileImageApi
 * @extends {BaseAPI}
 */
export class StartPageTileImageApi extends BaseAPI {
    /**
     * 
     * @summary StartPageTileImage GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileImageApi
     */
    public apiStartPageTileImageGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return StartPageTileImageApiFp(this.configuration).apiStartPageTileImageGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTileImage DELETE by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileImageApi
     */
    public apiStartPageTileImageIdDelete(id: string, options?: AxiosRequestConfig) {
        return StartPageTileImageApiFp(this.configuration).apiStartPageTileImageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTileImage GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileImageApi
     */
    public apiStartPageTileImageIdGet(id: string, options?: AxiosRequestConfig) {
        return StartPageTileImageApiFp(this.configuration).apiStartPageTileImageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a TEntity and saves it to the repository
     * @param {string} id 
     * @param {StartPageTileImageUpdateDto} [startPageTileImageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileImageApi
     */
    public apiStartPageTileImageIdPut(id: string, startPageTileImageUpdateDto?: StartPageTileImageUpdateDto, options?: AxiosRequestConfig) {
        return StartPageTileImageApiFp(this.configuration).apiStartPageTileImageIdPut(id, startPageTileImageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary StartPageTileImage create (POST)
     * @param {StartPageTileImageCreateDto} [startPageTileImageCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartPageTileImageApi
     */
    public apiStartPageTileImagePost(startPageTileImageCreateDto?: StartPageTileImageCreateDto, options?: AxiosRequestConfig) {
        return StartPageTileImageApiFp(this.configuration).apiStartPageTileImagePost(startPageTileImageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User update activity\'s intensity (PUT)
         * @param {string} activityId 
         * @param {ActivityIntensity} intensity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserActivityActivityIdIntensityIntensityPut: async (activityId: string, intensity: ActivityIntensity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('apiUserActivityActivityIdIntensityIntensityPut', 'activityId', activityId)
            // verify required parameter 'intensity' is not null or undefined
            assertParamExists('apiUserActivityActivityIdIntensityIntensityPut', 'intensity', intensity)
            const localVarPath = `/api/User/Activity/{activityId}/Intensity/{intensity}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"intensity"}}`, encodeURIComponent(String(intensity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User update activity\'s perception (PUT)
         * @param {string} activityId 
         * @param {ActivityPerception} perception 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserActivityActivityIdPerceptionPerceptionPut: async (activityId: string, perception: ActivityPerception, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('apiUserActivityActivityIdPerceptionPerceptionPut', 'activityId', activityId)
            // verify required parameter 'perception' is not null or undefined
            assertParamExists('apiUserActivityActivityIdPerceptionPerceptionPut', 'perception', perception)
            const localVarPath = `/api/User/Activity/{activityId}/Perception/{perception}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"perception"}}`, encodeURIComponent(String(perception)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BodyDataPoint (GET) by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBodyDataPointIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserBodyDataPointIdGet', 'id', id)
            const localVarPath = `/api/User/BodyDataPoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BodyDataPoint create (POST)
         * @param {BodyDataPointCreateDto} [bodyDataPointCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBodyDataPointPost: async (bodyDataPointCreateDto?: BodyDataPointCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/BodyDataPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyDataPointCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary BodyDataPoints (GET)
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBodyDataPointsGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/BodyDataPoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Staff create (POST)
         * @param {StaffCreateDto} [staffCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateStaffPost: async (staffCreateDto?: StaffCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateStaff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(staffCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CurrentUserCoachingPlans (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentCoachingPlansGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Current/CoachingPlans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CurrentUser (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CurrentUserPractice (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentPracticeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Current/Practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Current User update (PUT)
         * @param {CurrentUserUpdateDto} [currentUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentPut: async (currentUserUpdateDto?: CurrentUserUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(currentUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Users Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDocumentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User exists (GET) checks weather or not a user is already registerd against this API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExistsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} coachingPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdCoachingPlanCoachingPlanIdDelete: async (id: string, coachingPlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdCoachingPlanCoachingPlanIdDelete', 'id', id)
            // verify required parameter 'coachingPlanId' is not null or undefined
            assertParamExists('apiUserIdCoachingPlanCoachingPlanIdDelete', 'coachingPlanId', coachingPlanId)
            const localVarPath = `/api/User/{id}/CoachingPlan/{coachingPlanId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"coachingPlanId"}}`, encodeURIComponent(String(coachingPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} coachingPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdCoachingPlanCoachingPlanIdPost: async (id: string, coachingPlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdCoachingPlanCoachingPlanIdPost', 'id', id)
            // verify required parameter 'coachingPlanId' is not null or undefined
            assertParamExists('apiUserIdCoachingPlanCoachingPlanIdPost', 'coachingPlanId', coachingPlanId)
            const localVarPath = `/api/User/{id}/CoachingPlan/{coachingPlanId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"coachingPlanId"}}`, encodeURIComponent(String(coachingPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User GET CoachingPlans by User.Id  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdCoachingPlansGet: async (id: string, page?: number, pageSize?: number, orderByString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdCoachingPlansGet', 'id', id)
            const localVarPath = `/api/User/{id}/CoachingPlans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderByString !== undefined) {
                localVarQueryParameter['OrderByString'] = orderByString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User DELETE by id, for users this route also anonymizes sensible user data fields. referenced data used for statistics remain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User update (PUT)
         * @param {string} id 
         * @param {UserUpdateDto} [userUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, userUpdateDto?: UserUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            const localVarPath = `/api/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User create (POST)
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (userCreateDto?: UserCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Users Practice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPracticeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User register (POST)
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost: async (userCreateDto?: UserCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User update activity\'s intensity (PUT)
         * @param {string} activityId 
         * @param {ActivityIntensity} intensity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserActivityActivityIdIntensityIntensityPut(activityId: string, intensity: ActivityIntensity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserActivityActivityIdIntensityIntensityPut(activityId, intensity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User update activity\'s perception (PUT)
         * @param {string} activityId 
         * @param {ActivityPerception} perception 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserActivityActivityIdPerceptionPerceptionPut(activityId: string, perception: ActivityPerception, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserActivityActivityIdPerceptionPerceptionPut(activityId, perception, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BodyDataPoint (GET) by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserBodyDataPointIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BodyDataPointReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserBodyDataPointIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BodyDataPoint create (POST)
         * @param {BodyDataPointCreateDto} [bodyDataPointCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserBodyDataPointPost(bodyDataPointCreateDto?: BodyDataPointCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BodyDataPointReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserBodyDataPointPost(bodyDataPointCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary BodyDataPoints (GET)
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserBodyDataPointsGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BodyDataPointReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserBodyDataPointsGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Staff create (POST)
         * @param {StaffCreateDto} [staffCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateStaffPost(staffCreateDto?: StaffCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateStaffPost(staffCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CurrentUserCoachingPlans (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCurrentCoachingPlansGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCurrentCoachingPlansGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CurrentUser (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCurrentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCurrentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CurrentUserPractice (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCurrentPracticeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCurrentPracticeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Current User update (PUT)
         * @param {CurrentUserUpdateDto} [currentUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCurrentPut(currentUserUpdateDto?: CurrentUserUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCurrentPut(currentUserUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Users Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDocumentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDocumentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User exists (GET) checks weather or not a user is already registerd against this API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserExistsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserExistsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} coachingPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdCoachingPlanCoachingPlanIdDelete(id: string, coachingPlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdCoachingPlanCoachingPlanIdDelete(id, coachingPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} coachingPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdCoachingPlanCoachingPlanIdPost(id: string, coachingPlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdCoachingPlanCoachingPlanIdPost(id, coachingPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User GET CoachingPlans by User.Id  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdCoachingPlansGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachingPlanReadDtoPagedDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdCoachingPlansGet(id, page, pageSize, orderByString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User DELETE by id, for users this route also anonymizes sensible user data fields. referenced data used for statistics remain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User update (PUT)
         * @param {string} id 
         * @param {UserUpdateDto} [userUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, userUpdateDto?: UserUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, userUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User create (POST)
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(userCreateDto?: UserCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(userCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Users Practice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPracticeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPracticeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User register (POST)
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRegisterPost(userCreateDto?: UserCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRegisterPost(userCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary User update activity\'s intensity (PUT)
         * @param {string} activityId 
         * @param {ActivityIntensity} intensity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserActivityActivityIdIntensityIntensityPut(activityId: string, intensity: ActivityIntensity, options?: any): AxiosPromise<ActivityReadDto> {
            return localVarFp.apiUserActivityActivityIdIntensityIntensityPut(activityId, intensity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User update activity\'s perception (PUT)
         * @param {string} activityId 
         * @param {ActivityPerception} perception 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserActivityActivityIdPerceptionPerceptionPut(activityId: string, perception: ActivityPerception, options?: any): AxiosPromise<ActivityReadDto> {
            return localVarFp.apiUserActivityActivityIdPerceptionPerceptionPut(activityId, perception, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BodyDataPoint (GET) by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBodyDataPointIdGet(id: string, options?: any): AxiosPromise<BodyDataPointReadDto> {
            return localVarFp.apiUserBodyDataPointIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BodyDataPoint create (POST)
         * @param {BodyDataPointCreateDto} [bodyDataPointCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBodyDataPointPost(bodyDataPointCreateDto?: BodyDataPointCreateDto, options?: any): AxiosPromise<BodyDataPointReadDto> {
            return localVarFp.apiUserBodyDataPointPost(bodyDataPointCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary BodyDataPoints (GET)
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBodyDataPointsGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<BodyDataPointReadDtoPagedDtoList> {
            return localVarFp.apiUserBodyDataPointsGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Staff create (POST)
         * @param {StaffCreateDto} [staffCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateStaffPost(staffCreateDto?: StaffCreateDto, options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserCreateStaffPost(staffCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CurrentUserCoachingPlans (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentCoachingPlansGet(options?: any): AxiosPromise<CoachingPlanReadDtoPagedDtoList> {
            return localVarFp.apiUserCurrentCoachingPlansGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CurrentUser (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentGet(options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CurrentUserPractice (GET)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentPracticeGet(options?: any): AxiosPromise<PracticeReadDto> {
            return localVarFp.apiUserCurrentPracticeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Current User update (PUT)
         * @param {CurrentUserUpdateDto} [currentUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCurrentPut(currentUserUpdateDto?: CurrentUserUpdateDto, options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserCurrentPut(currentUserUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Users Documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDocumentsGet(options?: any): AxiosPromise<DocumentReadDtoPagedDtoList> {
            return localVarFp.apiUserDocumentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User exists (GET) checks weather or not a user is already registerd against this API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExistsGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserExistsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<UserReadDtoPagedDtoList> {
            return localVarFp.apiUserGet(page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} coachingPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdCoachingPlanCoachingPlanIdDelete(id: string, coachingPlanId: string, options?: any): AxiosPromise<CoachingPlanReadDtoPagedDtoList> {
            return localVarFp.apiUserIdCoachingPlanCoachingPlanIdDelete(id, coachingPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} coachingPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdCoachingPlanCoachingPlanIdPost(id: string, coachingPlanId: string, options?: any): AxiosPromise<CoachingPlanReadDtoPagedDtoList> {
            return localVarFp.apiUserIdCoachingPlanCoachingPlanIdPost(id, coachingPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User GET CoachingPlans by User.Id  <br>Optionally this can be paginated, when page and pageSize are part of the request.
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderByString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdCoachingPlansGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: any): AxiosPromise<CoachingPlanReadDtoPagedDtoList> {
            return localVarFp.apiUserIdCoachingPlansGet(id, page, pageSize, orderByString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User DELETE by id, for users this route also anonymizes sensible user data fields. referenced data used for statistics remain
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User GET by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: string, options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User update (PUT)
         * @param {string} id 
         * @param {UserUpdateDto} [userUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, userUpdateDto?: UserUpdateDto, options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserIdPut(id, userUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User create (POST)
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(userCreateDto?: UserCreateDto, options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserPost(userCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Users Practice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPracticeGet(options?: any): AxiosPromise<PracticeReadDto> {
            return localVarFp.apiUserPracticeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User register (POST)
         * @param {UserCreateDto} [userCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost(userCreateDto?: UserCreateDto, options?: any): AxiosPromise<UserReadDto> {
            return localVarFp.apiUserRegisterPost(userCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary User update activity\'s intensity (PUT)
     * @param {string} activityId 
     * @param {ActivityIntensity} intensity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserActivityActivityIdIntensityIntensityPut(activityId: string, intensity: ActivityIntensity, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserActivityActivityIdIntensityIntensityPut(activityId, intensity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User update activity\'s perception (PUT)
     * @param {string} activityId 
     * @param {ActivityPerception} perception 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserActivityActivityIdPerceptionPerceptionPut(activityId: string, perception: ActivityPerception, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserActivityActivityIdPerceptionPerceptionPut(activityId, perception, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BodyDataPoint (GET) by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserBodyDataPointIdGet(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserBodyDataPointIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BodyDataPoint create (POST)
     * @param {BodyDataPointCreateDto} [bodyDataPointCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserBodyDataPointPost(bodyDataPointCreateDto?: BodyDataPointCreateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserBodyDataPointPost(bodyDataPointCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary BodyDataPoints (GET)
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserBodyDataPointsGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserBodyDataPointsGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Staff create (POST)
     * @param {StaffCreateDto} [staffCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateStaffPost(staffCreateDto?: StaffCreateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateStaffPost(staffCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CurrentUserCoachingPlans (GET)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCurrentCoachingPlansGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCurrentCoachingPlansGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CurrentUser (GET)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCurrentGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CurrentUserPractice (GET)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCurrentPracticeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCurrentPracticeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Current User update (PUT)
     * @param {CurrentUserUpdateDto} [currentUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCurrentPut(currentUserUpdateDto?: CurrentUserUpdateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCurrentPut(currentUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Users Documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDocumentsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDocumentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User exists (GET) checks weather or not a user is already registerd against this API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserExistsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserExistsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User GET all  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGet(page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} coachingPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdCoachingPlanCoachingPlanIdDelete(id: string, coachingPlanId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdCoachingPlanCoachingPlanIdDelete(id, coachingPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} coachingPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdCoachingPlanCoachingPlanIdPost(id: string, coachingPlanId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdCoachingPlanCoachingPlanIdPost(id, coachingPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User GET CoachingPlans by User.Id  <br>Optionally this can be paginated, when page and pageSize are part of the request.
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderByString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdCoachingPlansGet(id: string, page?: number, pageSize?: number, orderByString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdCoachingPlansGet(id, page, pageSize, orderByString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User DELETE by id, for users this route also anonymizes sensible user data fields. referenced data used for statistics remain
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User GET by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdGet(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User update (PUT)
     * @param {string} id 
     * @param {UserUpdateDto} [userUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdPut(id: string, userUpdateDto?: UserUpdateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdPut(id, userUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User create (POST)
     * @param {UserCreateDto} [userCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPost(userCreateDto?: UserCreateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserPost(userCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Users Practice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPracticeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserPracticeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User register (POST)
     * @param {UserCreateDto} [userCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRegisterPost(userCreateDto?: UserCreateDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRegisterPost(userCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



import { Edit, Trash } from "@rsuite/icons";
import React from "react";
import { Divider, IconButton, Table } from "rsuite";

function PosNegTable(props) {
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    function handleAction() {
      onDelete(rowData[dataKey]);
    }
    return (
      <Table.Cell {...props} className="link-group">
        <IconButton
          appearance="subtle"
          onClick={handleAction}
          icon={<Trash />}
        />
      </Table.Cell>
    );
  };
  const data = props.posNegData.data;

  function onDelete(datax) {
   
      props.onDelete(datax);
 
  }


  
  if(data)
  {
    for (var i = 0; i < data.length; i++) {
      data[i].fullName = data[i].firstName + " " + data[i].lastName;
      data[i].practiceName = data[i].practice.name;
    }
  }


  return (
    <React.Fragment>
      <Table data={data} id="table" rowHeight={56} className="striped-table">
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="fullName" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Stanndort</Table.HeaderCell>
          <Table.Cell dataKey="practiceName" />
        </Table.Column>
        {props.canEdit && (
          <Table.Column>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <ActionCell dataKey="id" />
          </Table.Column>
        )}
      </Table>
    </React.Fragment>
  );
}

export default PosNegTable;

import React from "react";
import { Divider, IconButton, Table } from "rsuite";
import { Edit, Trash } from "@rsuite/icons";
import { getOffset } from "rsuite/esm/DOMHelper";
function LocationList(props) {
  const data = props.data;
  const role = localStorage.getItem("role");
  const nodeRef = React.useRef();

  function onSelect(data) {
    props.onSelect(data);
  }

  function onDelete(id, name) {
    props.onDelete(id, name);
  }

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    function handleAction() {
      onSelect(rowData[dataKey]);
    }
    function handleDelete() {
      onDelete(rowData[dataKey], rowData.label);
    }
    return (
      <Table.Cell {...props} className="link-group">
        <IconButton
          size="sm"
          appearance="primary"
          color="green"
          onClick={handleAction}
          icon={<Edit />}
        />
        {role == 3 ? (
          <>
            <Divider vertical={true}></Divider>
            <IconButton
              size="sm"
              appearance="primary"
              color="red"
              onClick={handleDelete}
              icon={<Trash />}
            />
          </>
        ) : null}
      </Table.Cell>
    );
  };
  return (
    <div
      id={props.id}
      ref={nodeRef}
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Table
        loading={props.loading}
        height={window.innerHeight - getOffset(nodeRef.current).top}
        bordered={true}
        virtualized
        showHeader={true}
        className="striped-table"
        data={data}
        id="table"
        rowHeight={56}
        // onRowClick={(data) => onSelect(data.parent_id)}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Standort</Table.HeaderCell>
          <Table.Cell dataKey="label" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Straße</Table.HeaderCell>
          <Table.Cell dataKey="street" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>PLZ</Table.HeaderCell>
          <Table.Cell dataKey="zip" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Stadt</Table.HeaderCell>
          <Table.Cell dataKey="city" />
        </Table.Column>
        <Table.Column width={131}>
          <Table.HeaderCell>Aktion</Table.HeaderCell>
          <ActionCell dataKey="parent_id" />
        </Table.Column>
      </Table>
    </div>
  );
}
export default LocationList;

import { useEffect, useRef, useState } from "react";
import flavorApi from "../../services/FlavorApi";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Form } from "rsuite";
import { getOffset } from "rsuite/esm/DOMHelper";
import createToast from "../Layout/Toast";
var Font = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ["arial", "roboto", "raleway", "montserrat", "lato", "rubik"];
Quill.register(Font, true);

const fontSizeArr = [
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
var Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const modules = {
  toolbar: [
    [{ size: fontSizeArr }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "video"],
    ["clean"],
  ],
};

function MyCoahingEdit({ location }) {
  let firstRender = 1;
  const nodeRef = useRef();
  const [flavorData, setFlavorData] = useState();
  const [flavorText, setFlavorText] = useState();

  async function getData() {
    let searcher = "";
    if (location.id == "10c4f70c-7a71-4445-9b1b-6bc1401805ad") {
      searcher = "a8d2c830-62f2-4110-875b-21eef038f37b";
      const data = await flavorApi.apiFlavorIdGet(searcher);
      setFlavorText(data.data.myCoachingText);
      setFlavorData(data);
    }
  }
  async function onSubmitHandler(e) {
    generateVideo(flavorText);

    const u = await flavorApi.apiFlavorIdPut(flavorData.data.id, {
      myCoachingText: flavorText,
      name: flavorData.data.name,
    });

    createToast("MyCoaching Text erfolgreich editiert.")
  }


  function generateVideo(asd) {
    let videoUrl = asd.split("<iframe");
    if (videoUrl.length > 1) {
      asd = "";
      for (let i = 0; i < videoUrl.length; i++) {
        if (videoUrl[i].indexOf("<") != 0) {
          videoUrl[i] =
            '<video preload="none" controls ' +
            videoUrl[i].split("iframe").join("video");

          let posterurltemp = videoUrl[i].split("src=");

          let posterurl = posterurltemp[1].split(">");

          videoUrl[i] = videoUrl[i]
            .split("<video ")
            .join(
              "<video  poster=" +
                posterurl[0]
                  .split("/uploads")
                  .join("/uploads/thumbs/")
                  .split('.mp4"')
                  .join('_1080.jpg" ')
            )
            .split('frameborder="0"')
            .join("")
            .split('allowfullscreen="true"')
            .join("");
        }
        asd += videoUrl[i];
      }
    } else {
      // alert("no vidoe");
    }
    setFlavorText(asd) ;
  }




  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div
        ref={nodeRef}
        style={{
          width: "100%",
          height: window.innerHeight - getOffset(nodeRef.current).top,
          overflow: "auto",
          backgroundColor: "#F3F8F8",
          
        }}
      >
        <Form

        fluid
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          marginLeft: "25%",
          marginRight: "25%",
        }}
      >
        {flavorData && (
          <>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={flavorText}
              onChange={(asd) => {
                if (firstRender == 1) {
                  firstRender = 0;
                } else {
                  let videoUrl = asd.split("<iframe");
                  if (videoUrl.length > 1) {
                    asd = "";
                    for (let i = 0; i < videoUrl.length; i++) {
                      if (videoUrl[i].indexOf("<") != 0) {
                        videoUrl[i] =
                          '<video preload="none" controls ' +
                          videoUrl[i].split("iframe").join('video');

                        let posterurltemp = videoUrl[i].split("src=");

                        let posterurl = posterurltemp[1].split(">");

                        videoUrl[i] = videoUrl[i]
                          .split("<video ")
                          .join(
                            "<video  poster=" +
                              posterurl[0]
                                .split("/uploads")
                                .join("/uploads/thumbs/")
                                .split('.mp4"')
                                .join('_1080.jpg" ')
                          )
                          .split('frameborder="0"')
                          .join("")
                          .split('allowfullscreen="true"')
                          .join("");
                      }
                      asd += videoUrl[i];
                    }
                  } else {
                   // alert("no vidoe");
                  }
                  setFlavorText(asd);
                }
              }}
            />
            <br></br>
            <Button
              appearance="primary"
              color="green"
              onClick={onSubmitHandler}
            >
              Speichern
            </Button>

            
          </>
        )}
        </Form>
      </div>
    </>
  );
}

export default MyCoahingEdit;

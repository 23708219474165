import { async } from "@firebase/util";
import { sendEmailVerification } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Checkbox,
  DatePicker,
  Form,
  Loader,
  Schema,
  SelectPicker,
} from "rsuite";
import authService from "../../services/auth.service";
import helper from "../../services/helper";
import practiceApi from "../../services/PracticeApi";
import { getOffset } from "rsuite/esm/DOMHelper";
import axios from "axios";

function UserForm(props) {
  const role = JSON.parse(localStorage.getItem("role"));
  const practiceId = localStorage.getItem("practiceId");
  const formRef = useRef();
  const pwRef = useRef();
  const nodeRef = React.useRef();
  const [loaderModal, setLoaderModal] = useState(false);
  const [practiceList, setPracticeList] = useState([]);
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    dateOfBirth: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    practiceId: "",
    password: "",
  });

  const [createStuff, setCreateStuff] = useState(false);
  const model = Schema.Model({
    dateOfBirth: Schema.Types.StringType(),
    email: Schema.Types.StringType()
      .isRequired("Die E-Mail ist ein Pflichtfeld")
      .isEmail("Bitte geben Sie eine gültige Emailadresse ein."),
    firstName: Schema.Types.StringType().isRequired(
      "Der Vorname ist ein Pflichtfeld"
    ),
    lastName: Schema.Types.StringType().isRequired(
      "Der Nachname ist ein Pflichtfeld"
    ),
    phone: Schema.Types.StringType().isRequired(
      "Die Telefonnummer ist ein Pflichtfeld"
    ),
    practiceId: Schema.Types.StringType().isRequired(
      "Die Standortzuweisung ist ein Pflichtfeld"
    ),

    password:
      props.user == 0
        ? Schema.Types.StringType()
            .minLength(6)
            .isRequired(
              "Das Passwort ist ein Pflichtfeld und muss mindestens 6 Zeichen lang sein."
            )
        : Schema.Types.StringType().minLength(6),
  });
  function onSubmitHandler(e) {
    if (!formRef.current.check()) {
      console.error("Form Error");
      return;
    } else {
      if (props.user == 0) {
        signUp();

        //props.onSubmitNew(formValue);
      } else {
        delete formValue.dateOfBirthxx;
        props.onEdit(formValue, props.user.id);
      }
    }
  }

  async function signUp() {
    setLoaderModal(true);
    const signUpSender = await axios.post(
      "https://physiodev.salestv.de/register",
      {
        email: formValue.email,
        password: formValue.password,
      }
    ); /*.signUp(
      formValue.email,
      formValue.password
    );*/

    if (!signUpSender.data) {
      setLoaderModal(false);
      return;
    }

    //await sendEmailVerification(signUpSender.data);
    props.onSubmitNew(
      {
        email: formValue.email,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        phone: formValue.phone,
        practiceId: formValue.practiceId,
        id: signUpSender.data.uid,
        dateOfBirth: formValue.dateOfBirth,
      },
      createStuff
    );
    setLoaderModal(false);
  }

  useEffect(() => {
    if (props.user === 0) {
      setFormValue({
        dateOfBirth: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        practiceId: role == 3 ? "" : practiceId,
        password: "",
      });
    } else {
      setFormValue({
        dateOfBirth: props.user.dateOfBirth,
        email: props.user.email,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        phone: props.user.phone,
        practiceId: props.user.practiceId,
        password: props.user.password,
      });
    }
  }, [props]);
  useEffect(() => {
    async function getPracticeData() {
      const practiceData = await practiceApi.apiPracticeGet();

      var temp = [];
      for (var i = 0; i < practiceData.data.data.length; i++) {
        temp.push({
          value: practiceData.data.data[i].id,
          label: practiceData.data.data[i].name,
        });
      }
      setPracticeList(temp);
    }
    getPracticeData();
  }, []);

  return (
    <div
      ref={nodeRef}
      style={{
        width: "100%",
        height: window.innerHeight - getOffset(nodeRef.current).top,
        overflow: "auto",
        backgroundColor: "#F3F8F8",
      }}
    >
      <Form
        model={model}
        onCheck={setFormError}
        ref={formRef}
        onChange={setFormValue}
        formValue={formValue}
        fluid
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          marginLeft: "25%",
          marginRight: "25%",
        }}
      >
        {role === 3 && props.user == 0 && (
          <Form.Group controlId="createStuff">
            <Form.ControlLabel>Mitarbeiter erstellen</Form.ControlLabel>
            <Form.Control
              accepter={Checkbox}
              value={createStuff}
              onChange={function stuffchange(e) {
                setCreateStuff(e == false ? true : false);
              }}
            />
          </Form.Group>
        )}
        <Form.Group controlId="firstName">
          <Form.ControlLabel>Vorname</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="firstName"
            value={formValue.firstName}
          />
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.ControlLabel>Nachname</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="lastName"
            value={formValue.lastName}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Geburtstag</Form.ControlLabel>
          <Form.Control
            accepter={DatePicker}
            block
            format="yyyy-MM-dd"
            value={
              formValue.dateOfBirth == ""
                ? null
                : new Date(formValue.dateOfBirth)
            }
            oneTap
            onChange={(data) => {
              var datum =
                String(data.getDate()).length == 1
                  ? "0" + data.getDate()
                  : data.getDate();

              var monat =
                String(data.getMonth()).length == 1
                  ? "0" + (data.getMonth() + 1)
                  : data.getMonth() + 1;
              var jahr = data.getFullYear();
              var datumFull = jahr + "-" + monat + "-" + datum;

              setFormValue((prevState) => ({
                ...prevState,
                dateOfBirth: datumFull,
              }));
            }}
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.ControlLabel>E-Mailadresse</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="email"
            value={formValue.email}
          />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.ControlLabel>Telefonnummer</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="phone"
            value={formValue.phone}
          />
        </Form.Group>
        {props.user == 0 && (
          <Form.Group controlId="password">
            <Form.ControlLabel>Passwort</Form.ControlLabel>
            <Form.Control autoComplete="off" name="password" type="password" />
          </Form.Group>
        )}
        {role == 3 && (
          <Form.Group controlId="practiceId">
            <Form.ControlLabel>Standortzuweisung:</Form.ControlLabel>
            <Form.Control
              name="practiceId"
              accepter={SelectPicker}
              block
              searchable={false}
              data={practiceList}
              value={formValue.practiceId}
              cleanable={false}
              placement="top"
            />
          </Form.Group>
        )}

        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              color="green"
              type="submit"
              onClick={onSubmitHandler}
            >
              Speichern
            </Button>
            <Button appearance="primary" color="red" onClick={props.onCancel}>
              Abbrechen
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      {loaderModal && (
        <Loader backdrop content="erstelle User" vertical></Loader>
      )}
    </div>
  );
}
export default UserForm;

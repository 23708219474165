import { Plus } from "@rsuite/icons";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  IconButton,
  InputNumber,
  Panel,
  Schema,
  SelectPicker,
} from "rsuite";
import coachingPlanExerciseApi from "../../services/CoachingPlanExerciseApi";

import createToast from "../Layout/Toast";

function CoachinplanExerciseForm({
  canEdit,
  planId,
  selectedEntry,
  openWithHeader,
  reload,
  onClose,
  setOpenWithHeader,
}) {
  const formRef = useRef();
  const selectData = [
    { label: "Wiederholungen", value: "reps" },
    { label: "Zeit", value: "time" },
  ];

  const [entry, setEntry] = useState(0);
  const [posneg, setPosneg] = useState();
  const [modal, setModal] = useState(false);
  const [entryData, setEntryData] = useState();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    durationInSeconds: 0,
    iterations: 0,
    pauseInSeconds: 0,
    repetitions: 0,
    sets: 0,
    mode: "reps",
  });

  console.log(selectedEntry);
  const model = Schema.Model({
    durationInSeconds: Schema.Types.NumberType(),
    iterations: Schema.Types.NumberType(),
    pauseInSeconds: Schema.Types.NumberType(),
    repetitions: Schema.Types.NumberType(),
    sets: Schema.Types.NumberType(),
    mode: Schema.Types.StringType().isRequired("Der Typ ist ein Pflichtfeld"),
  });

  useEffect(() => {
    if (selectedEntry) {
      getEntryData();
    } else {
    }
  }, [selectedEntry]);
  async function getEntryData() {
    const entry = await coachingPlanExerciseApi.apiCoachingPlanExerciseIdGet(
      selectedEntry.id
    );

    var modes = "reps";

    if (entry.data.durationInSeconds !== 0) {
      modes = "time";
    }
    setFormValue({
      durationInSeconds: entry.data.durationInSeconds,
      iterations: entry.data.iterations,
      pauseInSeconds: entry.data.pauseInSeconds,
      repetitions: entry.data.repetitions,
      sets: entry.data.sets,
      mode: modes,
    });
    setEntry(1);
    setEntryData(entry.data);
  }
  function submitHandler() {
    async function submitChange() {
      var u = await coachingPlanExerciseApi.apiCoachingPlanExerciseIdPut(
        selectedEntry.id,
        {
          coachingPlanId: planId,
          durationInSeconds:
            formValue.mode === "time" ? Number(formValue.durationInSeconds) : 0,
          exerciseId: selectedEntry.exerciseId,
          iterations:
            formValue.mode === "time" ? Number(formValue.iterations) : 0,
          order: selectedEntry.order,
          pauseInSeconds: Number(formValue.pauseInSeconds),
          repetitions:
            formValue.mode === "reps" ? Number(formValue.repetitions) : 0,
          sets: formValue.mode === "reps" ? Number(formValue.sets) : 0,
        }
      );
      console.log(u.status);
      if (u.status === 200) {
        createToast("Eintrag erfolgreich editiert.");
        setEntry(0);
        reload();
        onClose();
        setOpenWithHeader(false);
      }
    }
    if (!formRef.current.check()) {
      return;
    } else {
      submitChange();
    }
  }
  function handleCreateClick(art) {
    setPosneg(art);
    setOpenWithHeader(false);
    setModal(true);
  }

  let inhalt = <p></p>;
  if (entry == 1) {
    inhalt = (
      <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
        <Form
          disabled={!canEdit}
          model={model}
          onCheck={setFormError}
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <Form.Group controlId="mode">
            <Form.ControlLabel>Ausführungsart</Form.ControlLabel>
            <Form.Control
              name="mode"
              accepter={SelectPicker}
              data={selectData}
              searchable={false}
              block={true}
              cleanable={false}
            ></Form.Control>
          </Form.Group>
          {formValue.mode == "time" && (
            <>
              <Form.Group controlId="iterations">
                <Form.ControlLabel>Sätze</Form.ControlLabel>
                <Form.Control
                  name="iterations"
                  accepter={InputNumber}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="durationInSeconds">
                <Form.ControlLabel>Satzdauer in Sekunden</Form.ControlLabel>
                <Form.Control
                  name="durationInSeconds"
                  accepter={InputNumber}
                ></Form.Control>
              </Form.Group>
            </>
          )}
          {formValue.mode == "reps" && (
            <>
              <Form.Group controlId="sets">
                <Form.ControlLabel>Sätze</Form.ControlLabel>
                <Form.Control name="sets" accepter={InputNumber}></Form.Control>
              </Form.Group>
              <Form.Group controlId="repetitions">
                <Form.ControlLabel>Wiederholungen pro Satz</Form.ControlLabel>
                <Form.Control
                  name="repetitions"
                  accepter={InputNumber}
                ></Form.Control>
              </Form.Group>
            </>
          )}
          <Form.Group controlId="pauseInSeconds">
            <Form.ControlLabel>
              Pause zwischen den Sätzen in Sekunden
            </Form.ControlLabel>
            <Form.Control
              name="pauseInSeconds"
              accepter={InputNumber}
            ></Form.Control>
          </Form.Group>
        </Form>
      </div>
    );
  }

  function closeHalf() {
    setOpenWithHeader(false);
  }

  function closeComplete() {
    setEntry(0);
    setFormValue({
      datestart: "",
      dateend: "",
      timestart: "",
      timeend: "",
      valid_days: "",
      pos: [],
      neg: [],
    });
    onClose();
    setOpenWithHeader(false);
  }
  return (
    <React.Fragment>
      <Drawer
        size="sm"
        open={openWithHeader}
        onClose={() => {
          setOpenWithHeader(false);
        }}
        onExited={() => {
          modal ? closeHalf() : closeComplete();
        }}
      >
        <Drawer.Header>
          <Drawer.Title>Eintrag bearbeiten</Drawer.Title>
          {canEdit && (
            <Drawer.Actions>
              <Button onClick={() => setOpenWithHeader(false)}>
                Abbrechen
              </Button>
              <Button onClick={() => submitHandler()} appearance="primary">
                Speichern
              </Button>
            </Drawer.Actions>
          )}
        </Drawer.Header>
        <Drawer.Body>{inhalt}</Drawer.Body>
      </Drawer>
    </React.Fragment>
  );
}
export default CoachinplanExerciseForm;

import { Table } from "rsuite";
//import url from "../../../services/url";

function ImageCell({
  rowData,
  dataKey,
  onClickHandler,
  dataEnding,
  ...cellValues
}) {
  function onClick() {
    onClickHandler(rowData.prev ? rowData.prev : rowData);
  }
  return (
    <Table.Cell {...cellValues} onClick={onClick}>
      <img src={rowData.exercise[dataKey]} height="50px" />
    </Table.Cell>
  );
}
export default ImageCell;

import React from "react";
//import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Configuration, UserApi } from "../openapi";
import openApiConfig from "../services/http_service";
import userApi from "../services/UserApi";

const ProfilePage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
//const token= "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNmNjcyNDYxOTk4YjJiMzMyYWQ4MTY0ZTFiM2JlN2VkYTY4NDZiMzciLCJ0eXAiOiJKV1QifQ.eyJyb2xlIjozLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbGItcGh5c2lvLXRlc3QiLCJhdWQiOiJsYi1waHlzaW8tdGVzdCIsImF1dGhfdGltZSI6MTY2Njg3MjkzOCwidXNlcl9pZCI6ImlHWFpyQklRVGRPbmtPRloyNHc1bmxjSUFKRTMiLCJzdWIiOiJpR1hackJJUVRkT25rT0ZaMjR3NW5sY0lBSkUzIiwiaWF0IjoxNjY2ODcyOTM4LCJleHAiOjE2NjY4NzY1MzgsImVtYWlsIjoibXRAc2FsZXN0di5kZSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJtdEBzYWxlc3R2LmRlIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.PuoXdetK-M1NCLo55uwl7cCMCb2FycwT4Z9Kw3zcwVRD0IojsDePSAO5LfQsRfkWlxUeMFMo4QpRfl61o1Mh968BCy7bOJq8fAsv6MifDI5XmRETk4Eexzs7G4F12WIQSsToShaOP22Z-COEZH63M3Aif_KEON9RRbwUNgPvmKrGz8QapzzFhcaSQZtPwO1N03Q-vn0xph5LM6t-pYyPoGp8_m5F0Ctr1cgfuChkmJaEVzcR8dCiuSRMAt3wkCKUa1phXwGXJLXmeP6LXEzHMq8ujNFjlo5utNdG08-NUE5Hkk7IrZ1VyX5bzP_irviQeTM25AkLBt9FvzSSvTeL7g"

  async function getUserData()
  {
    
    const userData= await userApi.apiUserCurrentGet()
   
    console.log(userData)
  }

getUserData()
  console.log("sad")
  /*
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
*/
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.email}</strong> Profile
        </h3>
      </header>
      <p>
        <strong>Token:</strong> {currentUser.stsTokenManager.accessToken.substring(0, 20)} ...{" "}
        {currentUser.stsTokenManager.accessToken.substr(currentUser.stsTokenManager.accessToken.length - 20)}
      </p>
      <p>
        <strong>Id:</strong> {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>
      <strong>Authorities:</strong>
      <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul>
    </div>
  );
};

export default ProfilePage;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "rsuite";

function Navigation_rs(props) {
 
  const [forcer, setForcer] = useState(new Date());
  function getForcer() {
    setForcer(new Date());
  }

  return (
    <Navbar style={{ backgroundColor: "#5A9496", color: "white" }}>
      <Nav style={{ color: "white" }}>
        <Nav.Item
          as={Link}
          to={"/location"}
          onClick={getForcer}
          state={{ from: forcer }}
        >
          Standorte
        </Nav.Item>
        <Nav.Item
          as={Link}
          to={"/user"}
          onClick={getForcer}
          state={{ from: forcer }}
        >
          Benutzer
        </Nav.Item>
        <Nav.Item
          as={Link}
          to={"/exercise"}
          onClick={getForcer}
          state={{ from: forcer }}
        >
          Übungsübersicht
        </Nav.Item>
        <Nav.Item
          as={Link}
          to={"/plans"}
          onClick={getForcer}
          state={{ from: forcer }}
        >
          Coachingpläne
        </Nav.Item>
        <Nav.Item
          as={Link}
          to={"/blog"}
          onClick={getForcer}
          state={{ from: forcer }}
        >
          Blog
        </Nav.Item>
      </Nav>

      {/*}
        

        {canViewSequence && (
          <Nav.Item
            as={Link}
            to="/sequence"
            onClick={getForcer}
            state={{ from: forcer }}
          >
            Sequenzen
          </Nav.Item>
        )}
        {canViewContent && (
          <Nav.Item
            as={Link}
            to="/videos"
            onClick={getForcer}
            state={{ from: forcer }}
          >
            Videos
          </Nav.Item>
        )}
        {showContentDD && (
          <Dropdown title="Content">
            {canViewUpload && (
              <Dropdown.Item
                as={Link}
                to="/upload"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Hochladen
              </Dropdown.Item>
            )}
            {canViewFundus && (
              <Dropdown.Item
                as={Link}
                to="/pictures"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Bilder
              </Dropdown.Item>
            )}
            {canViewCreateVideo && (
              <Dropdown.Item
                as={Link}
                to="/createVideo"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Video erstellen
              </Dropdown.Item>
            )}
          </Dropdown>
        )}
        {showAdminDD && (
          <Dropdown title="Administration">
            <Dropdown.Item
              as={Link}
              to="/users"
              onClick={getForcer}
              state={{ from: forcer }}
            >
              Benutzerverwaltung
            </Dropdown.Item>
            {currentUser.roles.includes("GLOBAL_ADMIN") && (
              <Dropdown.Item
                as={Link}
                to="/projects"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Projekte
              </Dropdown.Item>
            )}
            {canViewCountry && (
              <Dropdown.Item
                as={Link}
                to="/countries"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Länder
              </Dropdown.Item>
            )}
            {canViewState && (
              <Dropdown.Item
                as={Link}
                to="/states"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Bundesländer
              </Dropdown.Item>
            )}
            {canViewLocation && (
              <Dropdown.Item
                as={Link}
                to="/locations"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Standorte
              </Dropdown.Item>
            )}
            {currentUser.roles.includes("GLOBAL_ADMIN") && (
              <Dropdown.Item
                as={Link}
                to="/monitor"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Monitore
              </Dropdown.Item>
            )}{" "}
            {canViewTemplate && (
              <Dropdown.Item
                as={Link}
                to="/templates"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Vorlagen
              </Dropdown.Item>
            )}{" "}
            {canViewWG && (
              <Dropdown.Item
                as={Link}
                to="/playwindowgroups"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Fenstergruppen
              </Dropdown.Item>
            )}
            {canViewTimings && (
              <Dropdown.Item
                as={Link}
                to="/timings"
                onClick={getForcer}
                state={{ from: forcer }}
              >
                Zeitpläne
              </Dropdown.Item>
            )}
          </Dropdown>
        )}
      </Nav>*/}
      <Nav></Nav>
      <Nav pullRight>
        <Nav.Item as={Link} to="/profile">
          
        </Nav.Item>
        <Nav.Item onClick={props.onLogout}>LogOut</Nav.Item>
      </Nav>
    </Navbar>
  );
}

export default Navigation_rs;

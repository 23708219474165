import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  ButtonToolbar,
  Button,
  Schema,
  Input,
  Nav,
  Panel,
  SelectPicker,
  TagPicker,
} from "rsuite";
import { getOffset } from "rsuite/esm/DOMHelper";
import practiceApi from "../../services/PracticeApi";
import practiceExerciseApi from "../../services/PracticeExerciseApi";
import ExerciseUpload from "./ExerciseUpload";
import ExerciseVideoExists from "./ExerciseVideoExists";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} rows={5} />
));

function ExcerciseForm(props) {
  const [active, setActive] = useState(
    props.exercise.videoUrl != "" ? "exists" : "upload"
  );
  const [practiceList, setPracticeList] = useState();
  const [selectedPractice, setSelectedPractice] = useState();

  const formRef = useRef();
  const nodeRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    headline: "",
    text: "",
    videoUrl: "",
    videoThumbnailUrl: "",
    tileTitle: "test",
    tileImageUrl: "",
    tileSubtitle: "",
  });

  useEffect(() => {
    async function getPracticeData() {
      const practiceData = await practiceApi.apiPracticeGet();
      const temp = [/*{ label: "öffentliche Übung", value:"00000000-0000-0000-0000-000000000000" }*/];
      practiceData.data.data.forEach((practice) => {
        temp.push({ label: practice.name, value: practice.id });
      });
      let practiceExerciseSortArray = [];
      try {
        const practiceExercise =
          await practiceExerciseApi.apiPracticeExerciseGet();
         
        practiceExercise.data.data.map((peData) => {
          if (peData.exerciseId === props.exercise.id) {
            let practiceName;

            for (let i = 0; i < temp.length; i++) {
              if (temp[i].value == peData.practiceId) {
                practiceName = temp[i].label;
                break;
              }
            }
            practiceExerciseSortArray.push(peData.practiceId);
          }
        });

        setSelectedPractice(practiceExerciseSortArray);
      } catch (err) {
        console.log(err);
      }
      setPracticeList(temp);
    }
    getPracticeData();
  }, []);

  const model = Schema.Model({
    headline: Schema.Types.StringType().isRequired(
      "Der Name ist ein Pflichtfeld"
    ),
    text: Schema.Types.StringType().isRequired(
      "Der Beschreibungstext ist ein Pflichtfeld"
    ),
    videoUrl: Schema.Types.StringType().isRequired(
      "Das Video ist ein Pflichtfeld"
    ),
    videoThumbnailUrl: Schema.Types.StringType(),
    tileTitle:Schema.Types.StringType().isRequired(
      "Der Anzeigename in der App ist ein Pflichtfeld"
    ),
  });

  function onSubmitHandler(e) {
    if (!formRef.current.check()) {
      return;
    } else {
     
      if (props.exercise == 0) {
        props.onSubmitNew(formValue, selectedPractice);
      } else {
      
        props.onEdit(formValue, props.exercise.id, selectedPractice);
      }
    }
  }

  useEffect(() => {
    if (props.exercise === 0) {
      setFormValue({
        headline: "",
        text: "",
        videoUrl: "",
        videoThumbnailUrl: "",
        tileTitle: "",
        tileImageUrl: "",
        tileSubtitle: "",
      });
    } else {
      setFormValue({
        headline: props.exercise.headline,
        text: props.exercise.text,
        videoUrl: props.exercise.videoUrl,
        videoThumbnailUrl: props.exercise.videoThumbnailUrl,
        tileTitle: props.exercise.tileTitle,
        tileImageUrl: "",
        tileSubtitle: "",
      });
    }
  }, [props]);

  function onHandleDeleteVideo() {
    setFormValue((prev) => ({
      ...prev,
      videoThumbnailUrl: "",
      videoUrl: "",
    }));
  }

  function setVideoNew(vUrl, tUrl) {
    setFormValue((prev) => ({
      ...prev,
      videoThumbnailUrl: tUrl,
      videoUrl: vUrl,
    }));
  }

  return (
    <div
      ref={nodeRef}
      style={{
        width: "100%",
        height: window.innerHeight - getOffset(nodeRef.current).top,
        overflow: "auto",
        backgroundColor: "#F3F8F8",
      }}
    >
      <Form
        model={model}
        onCheck={setFormError}
        ref={formRef}
        onChange={setFormValue}
        formValue={formValue}
        fluid
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          marginLeft: "25%",
          marginRight: "25%",
        }}
      >
        <Form.Group controlId="video">
          <Form.ControlLabel>Video</Form.ControlLabel>
          <Panel bordered>
            <Nav appearance="tabs" activeKey={active} onSelect={setActive}>
              <Nav.Item eventKey="upload">Hochladen</Nav.Item>{" "}
              <Nav.Item eventKey="exists">Bereits vorhanden</Nav.Item>
            </Nav>
            {active == "exists" && (
              <ExerciseVideoExists
                video={formValue}
                handleDeleteVideo={onHandleDeleteVideo}
                setVideoNew={setVideoNew}
              ></ExerciseVideoExists>
            )}
            {active == "upload" && (
              <ExerciseUpload
                setActiveTab={setActive}
                setVideoNew={setVideoNew}
              ></ExerciseUpload>
            )}
          </Panel>
        </Form.Group>

        <Form.Group controlId="headline">
          <Form.ControlLabel>Übungsname</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="headline"
            value={formValue.headline}
          />
        </Form.Group>
        <Form.Group controlId="text">
          <Form.ControlLabel>Übungsbeschreibung</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="text"
            value={formValue.text}
            accepter={Textarea}
          />
        </Form.Group>
        <Form.Group controlId="tileTitle">
          <Form.ControlLabel>Anzeigename in der App</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="tileTitle"
            value={formValue.tileTitle}
          />
        </Form.Group>
        <Form.Group controlId="practiceId"></Form.Group>
        <Form.ControlLabel>Stanndortzugehörigkeit</Form.ControlLabel>
        {selectedPractice && (
          <Form.Control
            accepter={TagPicker}
            block
            searchable={false}
            placement="topEnd"
            data={practiceList}
            onChange={(e) => {
              setSelectedPractice(e);
            }}
            cleanable={false}
              defaultValue={selectedPractice}
          ></Form.Control>
        )}

        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              color="green"
              type="submit"
              onClick={onSubmitHandler}
            >
              Speichern
            </Button>
            <Button appearance="primary" color="red" onClick={props.onCancel}>
              Abbrechen
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ExcerciseForm;

import { Plus, Trash } from "@rsuite/icons";
import { useEffect, useState } from "react";
import {
  Navbar,
  TreePicker,
  Button,
  IconButton,
  Nav,
  InputGroup,
  Input,
} from "rsuite";

function SearchTree(props) {

 
  const [defaultValue, setDefaultValue] = useState("");
  const [tempSelected, setTempSelected] = useState("");
  useEffect(() => {
    if (props.mode === "children") {
      if (props.selectedItem && props.firstTime === false) {
        for (let i = 0; i < props.dataList.length; i++) {
          if (
            props.selectedItem.project_id === props.dataList[i].parent_id ||
            props.selectedItem.projects_id === props.dataList[i].parent_id ||
            props.selectedItem.projectid === props.dataList[i].parent_id||
            props.selectedItem.practiceId === props.dataList[i].parent_id
          ) {
            for (let j = 0; j < props.dataList[i].children.length; j++) {
              if (
                props.dataList[i].children[j].child_id === props.selectedItem.id
              ) {
                setDefaultValue(props.dataList[i].children[j].value);
                break;
              }
            }
            break;
          }
        }
      } else if (props.selectedList && props.firstTime === false) {
        if (props.selectedList.length > 0) {
          for (let i = 0; i < props.dataList.length; i++) {
            if (
              props.selectedList[0].project_id === props.dataList[i].parent_id
            ) {
              setDefaultValue(props.dataList[i].value);
              break;
            }
          }
        } else {
          setDefaultValue(tempSelected);
        }
      } else setDefaultValue("");
    } else if (props.mode === "parent") {
      if (props.selectedItem && props.firstTime === false) {
        for (let i = 0; i < props.dataList.length; i++) {
          if (props.selectedItem.id === props.dataList[i].parent_id) {
            setDefaultValue(props.dataList[i].value);
            break;
          }
        }
      } else setDefaultValue("");
    }
  }, [props.selectedItem, props.firstTime, props.selectedList]);

  function onSelectHandler(e) {
    setTempSelected(e.value);
    props.onChangeHandler(e);
  }

  return (
    <Navbar style={{ backgroundColor: "#C0D9DA" }}>
      <TreePicker
        data={props.dataList}
        appearance="default"
        style={{ width: 246 }}
        childrenKey="children"
        onSelect={onSelectHandler}
        value={defaultValue}
        onClean={props.onClean}
        // height={props.height?props.height:360}
        virtualized
      />
      {props.canEdit && (
        <IconButton
          icon={<Plus></Plus>}
          style={{ marginLeft: 20 }}
          size="xs"
          onClick={props.onCreateNew}
          appearance="primary"
          color="green"
        >
          Neu Anlegen
        </IconButton>
      )}

      <Nav pullRight>
        {props.showDel && (
          <Nav.Item panel>
            <IconButton
              onClick={props.onDelete}
              color="red"
              appearance="primary"
              style={{ marginTop: "6px", marginRight: "25px" }}
              size="xs"
              icon={<Trash></Trash>}
            >
              Auswahl löschen
            </IconButton>
          </Nav.Item>
        )}
      </Nav>
      {props.showAdd && (
        <Nav pullRight>
          <Nav.Item panel>
            <IconButton
              onClick={props.onAddClick}
              color="green"
              appearance="primary"
              style={{ marginTop: "6px", marginRight: "25px" }}
              size="xs"
              icon={<Plus></Plus>}
            >
              Datei hochladen
            </IconButton>
          </Nav.Item>
        </Nav>
      )}
      {props.showFilter && (
        <Nav pullRight>
          <InputGroup
            style={{ width: 200, marginRight: 10, display: "inline-block" }}
          >
            <Input placeholder="Filter:" onChange={props.onFilter} />
          </InputGroup>
        </Nav>
      )}
    </Navbar>
  );
}

export default SearchTree;

import React, { useEffect, useState } from "react";
import { Nav } from "rsuite";
import CoachingplanBaseData from "./CoachingplanBaseData";
import CoachingplanExercises from "./CoachingplanExercises";

function CoachingplanForm(props) {
  const plan = props.coachingplan;
  const currentUser = props.currentUser;

  const [active, setActive] = React.useState("stammdaten");

  useEffect(() => {
    if (active == "entry" && props.coachingplan != 0) {
      props.showAddB(true);
    } else {
      props.showAddB(false);
    }
  }, [active]);

  function onDeleteSingle(id) {
    props.onDeleteSingle(id);
  }
  function onCopyEntry(id) {
    props.onCopyEntry(id);
  }

  const CustomNav = ({ active, onSelect, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="stammdaten">Stammdaten</Nav.Item>
        {plan != 0 && <Nav.Item eventKey="entry">Übungen</Nav.Item>}
        {plan == 0 && setActive("stammdaten")}
      </Nav>
    );
  };

  return (
    <React.Fragment>
      <CustomNav
        style={{ marginTop: "25px" }}
        appearance="tabs"
        active={active}
        onSelect={setActive}
      />
      {active == "stammdaten" && (
        <CoachingplanBaseData
          plan={plan}
          onEdit={(data, id) => props.onEdit(data, id)}
          onSubmitNew={(data) => props.onSubmitNew(data)}
          onCancel={props.onCancel}
          /* canEdit={canEdit}
          canEditPos={canEditPos}
          canEditNeg={canEditNeg}
          user={currentUser}
          onSelect={() => props.onSelect(props.sequence.id)}
          */
        ></CoachingplanBaseData>
      )}
      {active == "entry" && props.plan !== 0 && (
        <CoachingplanExercises
          plan={plan}
          onDeleteSingle={(id) => onDeleteSingle(id)}
          onCopyEntry={(rowData) => props.onCopyEntry(rowData)}
         
          onHandleCheck={(checked) => props.onHandleCheck(checked)}
          /*
          onCopyEntry={(id) => onCopyEntry(id)}
          onSelect={() => props.onSelect(props.sequence.id)}*/
        ></CoachingplanExercises>
      )}
    </React.Fragment>
  );
}

export default CoachingplanForm;

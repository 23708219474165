import React, { useEffect, useRef } from "react";
import { Form, ButtonToolbar, Button, Schema, Nav } from "rsuite";
import { getOffset } from "rsuite/esm/DOMHelper";
import LocationBaseData from "./LocationBaseData";
import LocationStartPage from "./LocationStartPage";
import MyCoahingEdit from "./MyCoahingEdit";

function LocationForm(props) {
  const location = props.location;
  const currentUser = props.currentUser;
  const [active, setActive] = React.useState("stammdaten");

  const CustomNav = ({ active, onSelect, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="stammdaten">Stammdaten</Nav.Item>
        {location != 0 && (
          <>
            <Nav.Item eventKey="startPage">App Startseite</Nav.Item>
            <Nav.Item eventKey="myCoaching">myCoaching</Nav.Item>
          </>
        )}
        {location == 0 && setActive("stammdaten")}
      </Nav>
    );
  };

  return (
    <>
      <CustomNav
        style={{ marginTop: "25px" }}
        appearance="tabs"
        active={active}
        onSelect={setActive}
      />
      {active == "stammdaten" && (
        <LocationBaseData
          location={location}
          onSubmitNew={(data) => props.onSubmitNew(data)}
          onEdit={(data, id) => props.onEdit(data, id)}
          onCancel={props.onCancel}
        ></LocationBaseData>
      )}
      {active == "startPage" && (
        <LocationStartPage location={location}></LocationStartPage>
      )}
      {active == "myCoaching" && <MyCoahingEdit  location={location}></MyCoahingEdit>}
    </>
  );
}
export default LocationForm;

/*
  const formRef = useRef();
  const nodeRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    city: "",
    contact: "",
    email: "",
    name: "",
    phone: "",
    street: "",
    website: "",
    zipCode: "",
  });

  const model = Schema.Model({
    city: Schema.Types.StringType().isRequired("Die Stadt ist ein Pflichtfeld"),
    contact: Schema.Types.StringType().isRequired(
      "Die Kontaktperson ist ein Pflichtfeld"
    ),

    email: Schema.Types.StringType()
      .isRequired("Die E-Mail ist ein Pflichtfeld")
      .isEmail("Bitte geben Sie eine gültige Emailadresse ein."),
    name: Schema.Types.StringType().isRequired("Der Name ist ein Pflichtfeld"),
    phone: Schema.Types.StringType().isRequired(
      "Die Telefonnummer ist ein Pflichtfeld"
    ),
    street: Schema.Types.StringType().isRequired(
      "Die Straße ist ein Pflichtfeld"
    ),
    website: Schema.Types.StringType(),
    zipCode: Schema.Types.NumberType().isRequired(
      "Die Postleitzahl ist ein Pflichtfeld"
    ),
  });

  function onSubmitHandler(e) {
    if (!formRef.current.check()) {
      console.error("Form Error");
      return;
    } else {
      if (props.location == 0) {
        props.onSubmitNew(formValue);
      } else {
        props.onEdit(formValue, props.location.id);
      }
    }
  }

  useEffect(() => {
    if (props.location === 0) {
      setFormValue({
        city: "",
        contact: "",
        email: "",
        name: "",
        phone: "",
        street: "",
        website: "",
        zipCode: "",
      });
    } else {
      setFormValue({
        name: props.location.name,
        city: props.location.city,
        contact: props.location.contact,
        email: props.location.email,
        phone: props.location.phone,
        street: props.location.street,
        website: props.location.website,
        zipCode: props.location.zipCode,
      });
    }
  }, [props]);

  return (
    <div
      ref={nodeRef}
      style={{
        width: "100%",
        height: window.innerHeight - getOffset(nodeRef.current).top,
        //height: "50%",
        overflow: "auto",
        backgroundColor: "#F3F8F8",
      }}
    >
      <Form
        model={model}
        onCheck={setFormError}
        ref={formRef}
        onChange={setFormValue}
        formValue={formValue}
        fluid
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          marginLeft: "25%",
          marginRight: "25%",
        }}
      >
        <Form.Group controlId="name">
          <Form.ControlLabel>Standortname</Form.ControlLabel>
          <Form.Control autoComplete="off" name="name" value={formValue.name} />
        </Form.Group>
        <Form.Group controlId="contact">
          <Form.ControlLabel>Kontakt</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="contact"
            value={formValue.contact}
          />
        </Form.Group>

        <Form.Group controlId="street">
          <Form.ControlLabel>Straße</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="street"
            value={formValue.street}
          />
        </Form.Group>
        <Form.Group controlId="zipCode">
          <Form.ControlLabel>Postleitzahl</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="zipCode"
            value={formValue.zipCode}
          />
        </Form.Group>
        <Form.Group controlId="city">
          <Form.ControlLabel>Stadt</Form.ControlLabel>
          <Form.Control autoComplete="off" name="city" value={formValue.city} />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.ControlLabel>Telefonnummer</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="phone"
            value={formValue.phone}
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.ControlLabel>E-Mailadresse</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="email"
            value={formValue.email}
          />
        </Form.Group>
        <Form.Group controlId="website">
          <Form.ControlLabel>Homepage</Form.ControlLabel>
          <Form.Control
            autoComplete="off"
            name="website"
            value={formValue.website}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              color="green"
              type="submit"
              onClick={onSubmitHandler}
            >
              Speichern
            </Button>
            <Button appearance="primary" color="red" onClick={props.onCancel}>
              Abbrechen
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
}

export default LocationForm;
*/

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Divider,
  Schema,
  Form,
  SelectPicker,
  Panel,
  IconButton,
  Checkbox,
} from "rsuite";
import coachingPlanApi from "../../services/CoachingPlanApi";
import practiceApi from "../../services/PracticeApi";
import Textarea from "../Layout/Textarea";
import { Plus } from "@rsuite/icons";
import PosNegTable from "../Layout/PosNegTable";
import ParaModal from "../Layout/ParaModal";
import userApi from "../../services/UserApi";
import createToast from "../Layout/Toast";
import { getOffset } from "rsuite/esm/DOMHelper";

function CoachingplanBaseData(props) {
  const practiceId = localStorage.getItem("practiceId");
  const role = localStorage.getItem("role");
  const formRef = useRef();
  const nodeRef = useRef();
  const [modal, setModal] = useState(false);
  const [practiceList, setPracticeList] = useState();
  const [selectedPractice, setSelectedPractice] = useState();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    tileTitle: "",
    tileSubtitle: "",
    tileImageUrl: "",
    title: "",
    text: "",
    practiceId: "",
    coachingPlanCategory: "",
    isPublic: "",
  });
  const [userList, setUserList] = useState([]);
  const model = Schema.Model({
    tileTitle: Schema.Types.StringType(),
    tileSubtitle: Schema.Types.StringType(),
    tileImageUrl: Schema.Types.StringType(),
    title: Schema.Types.StringType().isRequired(
      "Der Name ist ein Pflichtfeld."
    ),
    text: Schema.Types.StringType(),
    practiceId: Schema.Types.StringType().isRequired(
      "Der Coachingplan muss einer Praxis zugeordnet sein."
    ),
    coachingPlanCategory: Schema.Types.StringType().isRequired(
      "Der Coachingplan muss einer Kategorie zugeordnet sein."
    ),
  });

  useEffect(() => {
    if (props.plan !== 0) {
      setFormValue({
        tileTitle: props.plan.tileTitle,
        tileSubtitle: props.plan.tileSubtitle,
        tileImageUrl: props.plan.tileImageUrl,
        title: props.plan.title,
        text: props.plan.text,
        practiceId: props.plan.practiceId,
        coachingPlanCategory: props.plan.coachingPlanCategory,
        isPublic: props.plan.isPublic,
      });
    } else {
      setFormValue({
        tileTitle: "",
        tileSubtitle: "",
        tileImageUrl: "",
        title: "",
        text: "",
        practiceId: role == 3 ? "" : practiceId,
        coachingPlanCategory: "",
        isPublic: false,
      });
    }
  }, [props.plan]);

  useEffect(() => {
    if (role == 3) {
      getPracticeData();
    }
    getUserInCoachinplan();
  }, []);

  async function getPracticeData() {
    const practiceData = await practiceApi.apiPracticeGet();
    const temp = [{ value: "", label: "Bitte Standort auswählen" }];
    practiceData.data.data.forEach((practice) => {
      temp.push({ label: practice.name, value: practice.id });
    });

    try {
      if (props.plan.practiceId) {
        const coachingPlanData = await coachingPlanApi.apiCoachingPlanIdGet(
          props.plan.id
        );

        setSelectedPractice(coachingPlanData.data.practiceId);
        setFormValue((prev) => ({
          ...prev,
          practiceId: coachingPlanData.data.practiceId,
        }));
      } else {
        setSelectedPractice("");
        setFormValue((prev) => ({
          ...prev,
          practiceId: 0,
        }));
      }
    } catch (err) {
      setSelectedPractice(0);
      setFormValue((prev) => ({
        ...prev,
        practiceId: 0,
      }));
    }
    setPracticeList(temp);
  }

  async function getUserInCoachinplan() {
    const userInPlan = await coachingPlanApi.apiCoachingPlanIdUsersGet(
      props.plan.id
    );
    setUserList(userInPlan.data);
  }
  //useEffect(() => {getPlanData()}, []);

  function onSubmitHandler(e) {
    if (!formRef.current.check()) {
      console.log(formValue);
      return;
    } else {
      if (props.plan == 0) {
        props.onSubmitNew(formValue);
      } else {
        props.onEdit(formValue, props.plan.id, selectedPractice);
      }
    }
  }

  function handleCreateClick() {
    setModal(true);
  }

  async function onDelete(datax) {
    var del = await userApi.apiUserIdCoachingPlanCoachingPlanIdDelete(
      datax,
      props.plan.id
    );
    if (del.status == 200) {
      createToast("Benutzer erfolgreich aus Coachingplan entfernt.");
      getUserInCoachinplan();
    }
  }
  return (
    <React.Fragment>
      <div
        ref={nodeRef}
        style={{
          width: "100%",
          height: window.innerHeight - getOffset(nodeRef.current).top,
          overflow: "auto",
          backgroundColor: "#F3F8F8",
        }}
      >
        <Form
          fluid
          model={model}
          onCheck={setFormError}
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            marginLeft: "25%",
            marginRight: "25%",
          }}
        >
          <Form.Group controlId="title">
            <Form.ControlLabel>Name</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="title"
              value={formValue.title}
            />
          </Form.Group>
          <Form.Group controlId="text">
            <Form.ControlLabel>Beschreibungstext</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="text"
              value={formValue.text}
              accepter={Textarea}
            />
          </Form.Group>
          <Form.Group controlId="tileTitle">
            <Form.ControlLabel>Kacheltitel</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="tileTitle"
              value={formValue.tileTitle}
            />
          </Form.Group>
          <Form.Group controlId="tileSubtitle">
            <Form.ControlLabel>Kacheluntertitel</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="tileSubtitle"
              value={formValue.tileSubtitle}
            />
          </Form.Group>
          <Form.Group controlId="tileImageUrl">
            <Form.ControlLabel>Kachelbild URL</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="tileImageUrl"
              value={formValue.tileImageUrl}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Kategorie</Form.ControlLabel>
            <Form.Control
              accepter={SelectPicker}
              block
              searchable={false}
              placement="topEnd"
              data={[
                { value: "", label: "Bitte Kategorie auswählen" },
                { value: "mental", label: "Mental" },
                { value: "social", label: "Social" },
                { value: "movement", label: "Movement" },
              ]}
              cleanable={false}
              name={"coachingPlanCategory"}
              value={
                formValue.coachingPlanCategory != null
                  ? formValue.coachingPlanCategory
                  : ""
              }
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>öffentlicher Coachingplan</Form.ControlLabel>
            <Form.Control
              accepter={Checkbox}
              block
              name="isPublic"
              value={formValue.isPublic}
              checked={formValue.isPublic}
              //defaultChecked={formValue.isPublic}
              onChange={(e) => {
                setFormValue({
                  coachingPlanCategory: formValue.coachingPlanCategory,
                  isPublic: formValue.isPublic === true ? false : true,
                  practiceId: formValue.practiceId,
                  text: formValue.text,
                  tileImageUrl: formValue.tileImageUrl,
                  tileSubtitle: formValue.tileSubtitle,
                  tileTitle: formValue.tileTitle,
                  title: formValue.title,
                });
              }}
            ></Form.Control>
          </Form.Group>
          {props.plan == 0 && role == 3 && (
            <Form.Group controlId="practiceId">
              <Form.ControlLabel>Stanndortzugehörigkeit</Form.ControlLabel>
              <Form.Control
                accepter={SelectPicker}
                block
                searchable={false}
                placement="topEnd"
                data={practiceList}
                name="practiceId"
                cleanable={false}
              ></Form.Control>
            </Form.Group>
          )}

          <Form.Group>
            <ButtonToolbar>
              <Button appearance="primary" onClick={onSubmitHandler}>
                Speichern
              </Button>
              <Button appearance="default" onClick={props.onCancel}>
                Abbrechen
              </Button>
            </ButtonToolbar>
          </Form.Group>
          <Form.Group controlId="practice"></Form.Group>
          {props.plan != 0 && userList && (
            <Panel
              header={
                <React.Fragment>
                  Zugewiesene Benutzer:
                  <IconButton
                    icon={<Plus></Plus>}
                    color="green"
                    appearance="primary"
                    style={{ marginLeft: 10, marginBottom: 2 }}
                    size="xs"
                    onClick={() => handleCreateClick()}
                  >
                    Benutzer zuweisen
                  </IconButton>
                </React.Fragment>
              }
              bordered
              bodyFill
              defaultExpanded={true}
              style={{
                marginTop: "25px",
                marginBottom: "25px",
              }}
            >
              {
                <PosNegTable
                  canEdit={true}
                  posNegData={userList}
                  onDelete={(data) => onDelete(data)}
                  plan={props.plan}
                ></PosNegTable>
              }
            </Panel>
          )}
        </Form>
      </div>
      {
        <ParaModal
          modalOpen={modal}
          onClose={() => {
            getUserInCoachinplan();
            setModal(false);
          }}
          plan={props.plan}
          userInPlan={userList}
        ></ParaModal>
      }
    </React.Fragment>
  );
}
export default CoachingplanBaseData;

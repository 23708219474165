import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableRow = React.memo(({ canEdit, children, onDrag, rowData }) => {
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "row",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item) {
      onDrag && onDrag(item.id, rowData.id);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "row",
    item: () => ({ id: rowData.id }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isActive = canDrop && isOver;

  drag(drop(ref));

  const styles = {
    width: "100%",
    height: "100%",
    cursor: "grab",
    opacity: isDragging ? 0.4 : 1,
    borderTop: isActive ? "2px solid #8e8e93" : "",
    padding: isActive ? ".35rem 0 0 0" : "",
    transition: "padding .1s ease-out",
  };
  if (rowData.parameter) {
    if (rowData.parameter.indexOf("Sequenz") == -1) {
      return (
        <div ref={ref} style={styles}>
          {children}
        </div>
      );
    } else {
      return <div>{children}</div>;
    }
  } else {
    if (canEdit) {
      return (
        <div ref={ref} style={styles}>
          {children}
        </div>
      );
    }
    else {
      return <div>{children}</div>;
    }
  }
});
export default DraggableRow;

import { Uploader } from "rsuite";

function ExerciseUpload(props) {
  function onSuccessHandler(e) {
    console.log(e);
    props.setVideoNew(e.videoUrl, e.thumbUrl);
    props.setActiveTab("exists");
  }

  return (
    <div
      id={props.id}
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Uploader
        accept="video/mp4"
        action={"https://physiodev.salestv.de/upload"}
        onSuccess={onSuccessHandler}
      ></Uploader>
    </div>
  );
}
export default ExerciseUpload;

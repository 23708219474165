import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Table } from "rsuite";
import { getOffset } from "rsuite/esm/DOMHelper";
import CheckCell from "../Layout/CheckCell";
import TextCell from "../Layout/TextCell";
import exerciseApi from "../../services/ExerciseApi";
import coachingPlanExerciseApi from "../../services/CoachingPlanExerciseApi";
import createToast from "../Layout/Toast";
import practiceExerciseApi from "../../services/PracticeExerciseApi";
function CoachingplanAddDialog({
  plan,
  handleCheckUpper,
  send,
  cleanupp,
  handleDrawClose,
}) {
  const [data, setData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({});

  const nodeRefs = useRef();
  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }

  useEffect(() => {
    async function sendData() {
      var counter = 0;
      for (var i = 0; i < checkedKeys.length; i++) {
        const sender =
          await coachingPlanExerciseApi.apiCoachingPlanExercisePost({
            coachingPlanId: plan.id,
            exerciseId: checkedKeys[i],
            order: 99,
            iterations: 0,
            durationInSeconds: 0,
            pauseInSeconds: 0,
            repetitions: 0,
            sets: 0,
          });

        counter++;
        if (counter === checkedKeys.length) {
          createToast("Einträge erfolgreich hinzugefügt.");
          handleDrawClose();
        }
      }
    }
    if (send === true) {
      sendData();
    }
    return function cleanup() {
      cleanupp();
    };
  }, [send]);

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data.map((item) => item.id) : [];
    handleCheckUpper(keys);

    setCheckedKeys(keys);
  };

  function rowClickHandler(e) {
    const checkedStatus = checkedKeys.some((item) => item === e.id);
    handleCheck(e.id, !checkedStatus);
  }
  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    handleCheckUpper(keys);

    setCheckedKeys(keys);
  };

  useEffect(() => {
    if (plan.id) {
      getData();
    }
  }, []);

  async function getData() {
    const exEntries = await exerciseApi.apiExerciseGet();
    const avaibleExercises = await practiceExerciseApi.apiPracticeExerciseGet();
    const tempEntries = [];
    exEntries.data.data.map((exercise) => {
      for (let i = 0; i < avaibleExercises.data.data.length; i++) {
        if (
          exercise.id == avaibleExercises.data.data[i].exerciseId &&
          plan.practiceId == avaibleExercises.data.data[i].practiceId
        ) {
          tempEntries.push(exercise);
        }
      }
    });

    setData(tempEntries);
  }
  const ImageCell = ({ rowData, dataKey, ...cellData }) => {
    return (
      <Table.Cell {...cellData}>
        {
          <img
            alt="preview"
            src={rowData[dataKey]}
            height="50px"
            /*onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = url.brokenUrl;
            }}*/
          />
        }
      </Table.Cell>
    );
  };

  function getTimeData(data) {
    setFormValue((prev) => ({
      ...prev,
      timestart: data.startTime,
      timeend: data.endTime,
    }));
  }

  function getDaysData(data) {
    setFormValue((prev) => ({
      ...prev,
      valid_days: data.days,
    }));
  }

  return (
    <React.Fragment>
      <div
        ref={nodeRefs}
        style={{
          overflow: "hidden",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "100%",
        }}
      >
        <Table
          virtualized
          className="striped-table"
          showHeader={true}
          data={data}
          id="table"
          rowHeight={75}
          height={window.innerHeight - getOffset(nodeRefs.current).top}
        >
          {data.length > 0 && (
            <Table.Column width={50} align="center">
              <Table.HeaderCell style={{ padding: 0 }}>
                <div style={{ lineHeight: "40px" }}>
                  <Checkbox
                    inline
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={handleCheckAll}
                  />
                </div>
              </Table.HeaderCell>
              <CheckCell
                dataKey="id"
                checkedKeys={checkedKeys}
                onChange={handleCheck}
              />
            </Table.Column>
          )}
          <Table.Column>
            <Table.HeaderCell>Vorschau</Table.HeaderCell>
            <ImageCell dataKey="videoThumbnailUrl" />
          </Table.Column>
          <Table.Column flexGrow={2}>
            <Table.HeaderCell>Player</Table.HeaderCell>
            <TextCell rowClickHandler={rowClickHandler} dataKey="headline" />
          </Table.Column>
        </Table>
      </div>
    </React.Fragment>
  );
}
export default CoachingplanAddDialog;

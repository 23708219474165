import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Divider,
  InputPicker,
  Modal,
  TagPicker,
  Input,
} from "rsuite";
import userApi from "../../services/UserApi";
import createToast from "./Toast";
function ParaModal(props) {
  const [paraData, setParaData] = useState(false);
  const [selected, setSelected] = useState();
  console.log(props.plan);

  useEffect(() => {
    if (props.modalOpen) getAvaibleUser();
  }, [props.modalOpen]);
  async function getAvaibleUser() {
    const userData = await userApi.apiUserGet();

    const temp = [];

    for (var i = 0; i < userData.data.data.length; i++) {
      var userAlreadyInList = 0;
      for (var j = 0; j < props.userInPlan.data.length; j++) {
        if (props.userInPlan.data[j].id === userData.data.data[i].id) {
          userAlreadyInList = 1;
        }
      }

      if (
        userAlreadyInList === 0 &&
        props.plan.practiceId === userData.data.data[i].practiceId
      ) {
        temp.push({
          label:
            userData.data.data[i].firstName +
            " " +
            userData.data.data[i].lastName +
            " - " +
            userData.data.data[i].practice.name,
          value: userData.data.data[i].id,
        });
      }
    }
    setParaData(temp);
  }
  function cleanup() {
    setSelected();
    setParaData(false);
  }

  function onSelectHandler(data) {
    setSelected(data);
  }
  function onChangeWindowName(data) {
    setSelected(data);
  }
  async function onSubmitHandler() {
    if (selected) {
      var counter = 0;
      for (var i = 0; i < selected.length; i++) {
        const u = await userApi.apiUserIdCoachingPlanCoachingPlanIdPost(
          selected[i],
          props.plan.id
        );

        if (u.status === 200) {
          counter++;
          if (counter === selected.length) {
            createToast("Benutzer erfolgreich zugewiesen.");
            setSelected();
            setParaData(false);
            props.onClose();
          }
        }
      }
    }
  }

  return (
    <Modal
      size="md"
      backdrop="static"
      open={props.modalOpen}
      onClose={() => {
        cleanup();
        props.onClose();
      }}
    >
      <Modal.Header>
        <Modal.Title>Benutzer zuweisen</Modal.Title>
        <ButtonToolbar
          style={{ float: "right", marginRight: 25, marginTop: -22 }}
        >
          <Button appearance="primary" onClick={onSubmitHandler}>
            Speichern
          </Button>
        </ButtonToolbar>
      </Modal.Header>
      <Modal.Body>
        <div>
          {paraData && (
            <React.Fragment>
              <br></br>
              <TagPicker
                placement="auto"
                onSelect={(data) => onSelectHandler(data)}
                data={paraData}
                block
              />{" "}
              <br></br>
            </React.Fragment>
          )}
        </div>{" "}
      </Modal.Body>
    </Modal>
  );
}

export default ParaModal;

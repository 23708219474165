import { Message, toaster } from "rsuite";

const createToast = (message) => {
  toaster.push(
    <Message closable showIcon type="success">
      {message}
    </Message>,
    "topCenter"
  );
};
export default createToast;



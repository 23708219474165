import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Carousel,
  Drawer,
  Form,
  IconButton,
  Input,
  Message,
  Modal,
  Panel,
  Placeholder,
  Schema,
  Stack,
  Uploader,
  toaster,
} from "rsuite";
import startTileApi from "../../services/StartTile";
import { ImageApi } from "../../openapi";
import imageApi from "../../services/ImageApi";
import startPageImageTileApi from "../../services/StartPageImage";
import TrashIcon from "@rsuite/icons/Trash";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
var Font = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ["arial", "roboto", "raleway", "montserrat", "lato", "rubik"];
Quill.register(Font, true);

const fontSizeArr = [
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
var Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const modules = {
  toolbar: [
    [{ size: fontSizeArr }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "video"],
    ["clean"],
  ],
};
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} rows={6} />
));
function StartTileEditDrawer(props) {
  let firstRender = 1;

  const images = [];
  const formRef = useRef();
  const nodeRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    tileCaption: "",
    tileBackgroundImageUrl: "",
    headline: "",
    text: "",
    externalUrl: "",
    practiceId: "",
    images: [],
  });

  const model = Schema.Model({
    externalUrl: Schema.Types.StringType(),
    headline: Schema.Types.StringType(),
    text: Schema.Types.StringType(),
    tileBackgroundImageUrl: Schema.Types.StringType(),
  });

  async function getTileData() {
    const data = await startTileApi.apiStartPageTileIdGet(props.tile);

    setFormValue({
      externalUrl: data.data.externalUrl,
      headline: data.data.headline,
      images: data.data.images,
      practiceId: data.data.practiceId,
      text: data.data.text,
      tileBackgroundImageUrl: data.data.tileBackgroundImageUrl,
      tileCaption: data.data.tileCaption,
    });

    console.log(data);
  }

  useEffect(() => {
    if (props.tile != "0") getTileData();
    else
      setFormValue({
        externalUrl: "",
        headline: "",
        images: "",
        practiceId: props.location,
        text: "text",
        tileBackgroundImageUrl:
          "https://www.w3schools.com/images/colorpicker2000.png",
        tileCaption: "",
      });
  }, []);
  function onSuccessHandler(e) {
    setFormValue({ ...formValue, tileBackgroundImageUrl: e.fileUrl });
  }

  async function onMultipleUploadSuccessHandler(e) {
    const imageCreator = await imageApi.apiImagePost({
      url: e.fileUrl,
      caption: "",
    });

    console.log(props);
    if (props.tile != 0) {
      const tileImageCreator =
        await startPageImageTileApi.apiStartPageTileImagePost({
          imageId: imageCreator.data.id,
          startPageTileId: props.tile,
        });
    } else {
      images.push(imageCreator.data.id);
    }

    console.log(images);
  }

  function onSubmitHandler(e) {
    if (!formRef.current.check()) {
      console.error("Form Error");
      return;
    } else {
      generateVideo(formValue.text);

      if (props.tile == 0) {
        onSubmitNew(formValue);
      } else {
        onEdit(formValue, props.tile);
      }
    }
  }
  function generateVideo(asd) {
    let videoUrl = asd.split("<iframe");
    if (videoUrl.length > 1) {
      asd = "";
      for (let i = 0; i < videoUrl.length; i++) {
        if (videoUrl[i].indexOf("<") != 0) {
          videoUrl[i] =
            '<video preload="none" controls ' +
            videoUrl[i].split("iframe").join("video");

          let posterurltemp = videoUrl[i].split("src=");

          let posterurl = posterurltemp[1].split(">");

          videoUrl[i] = videoUrl[i]
            .split("<video ")
            .join(
              "<video  poster=" +
                posterurl[0]
                  .split("/uploads")
                  .join("/uploads/thumbs/")
                  .split('.mp4"')
                  .join('_1080.jpg" ')
            )
            .split('frameborder="0"')
            .join("")
            .split('allowfullscreen="true"')
            .join("");
        }
        asd += videoUrl[i];
      }
    } else {
      // alert("no vidoe");
    }
    formValue.text = asd;
  }

  // alert(props.tile)
  async function onSubmitNew(data) {
    const c = await startTileApi.apiStartPageTilePost({
      isLeading: false,
      practiceId: formValue.practiceId,
      text: formValue.text,
      tileBackgroundImageUrl: formValue.tileBackgroundImageUrl,
      tileCaption: formValue.tileCaption,
      externalUrl: formValue.externalUrl,
      headline: formValue.headline,
      tileSlot: props.slot,
      tileOrder: 0,
    });

    if (props.tile == 0) {
      for (let i = 0; i < images.length; i++) {
        const tileImageCreator =
          await startPageImageTileApi.apiStartPageTileImagePost({
            imageId: images[i],
            startPageTileId: c.data.id,
          });
      }
    }
    console.log(c);
    if (c.status == 201) {
      toaster.push(
        <Message showIcon type="success">
          Standort erfolgreich angelegt.
        </Message>,
        "topCenter"
      );
      props.handleClose();
    }
  }
  async function onEdit(data, id) {
    const u = await startTileApi.apiStartPageTileIdPut(id, {
      isLeading: false,
      practiceId: formValue.practiceId,
      text: formValue.text,
      tileBackgroundImageUrl: formValue.tileBackgroundImageUrl,
      tileCaption: formValue.tileCaption,
      externalUrl: formValue.externalUrl,
      headline: formValue.headline,
      tileSlot: props.slot,
      tileOrder: 0,
    });

    if (u.status == 200) {
      toaster.push(
        <Message showIcon type="success">
          Kachel erfolgreich editiert.
        </Message>,
        "topCenter"
      );
      props.handleClose();
    }
  }

  return (
    <Modal size="lg" open={true} onClose={props.handleClose} backdrop="static">
      <Modal.Header>
        <Modal.Title>Kachel bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          model={model}
          onCheck={setFormError}
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          fluid
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            marginLeft: "25%",
            marginRight: "25%",
          }}
        >
          <Form.Group controlId="tileCaption">
            <Form.ControlLabel>Kachel Beschriftung</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="tileCaption"
              value={formValue.tileCaption}
            />
          </Form.Group>
          <Form.Group controlId="headline">
            <Form.ControlLabel>Überschrift</Form.ControlLabel>
            <Form.Control
              autoComplete="off"
              name="headline"
              value={formValue.headline}
            />
          </Form.Group>
          <Form.Group controlId="text">
            <Form.ControlLabel>Text</Form.ControlLabel>

            <ReactQuill
              theme="snow"
              modules={modules}
              value={formValue.text}
              onChange={(asd) => {
                if (firstRender == 1) {
                  firstRender = 0;
                } else {
                  /*let videoUrl = asd.split("<iframe");
                  if (videoUrl.length > 1) {
                    asd = "";
                    for (let i = 0; i < videoUrl.length; i++) {
                      if (videoUrl[i].indexOf("<") != 0) {
                        videoUrl[i] =
                          '<video preload="none" controls ' +
                          videoUrl[i].split("iframe").join('video');

                        let posterurltemp = videoUrl[i].split("src=");

                        let posterurl = posterurltemp[1].split(">");

                        videoUrl[i] = videoUrl[i]
                          .split("<video ")
                          .join(
                            "<video  poster=" +
                              posterurl[0]
                                .split("/uploads")
                                .join("/uploads/thumbs/")
                                .split('.mp4"')
                                .join('_1080.jpg" ')
                          )
                          .split('frameborder="0"')
                          .join("")
                          .split('allowfullscreen="true"')
                          .join("");
                      }
                      asd += videoUrl[i];
                    }
                  } else {
                   // alert("no vidoe");
                  }*/
                  //console.log(asd);
                  setFormValue({
                    ...formValue,
                    text: asd,
                  });
                }
              }}
            />
          </Form.Group>
          <Form.Group controlId="externalUrl">
            <Form.ControlLabel>URL</Form.ControlLabel>
            <Form.HelpText>
              Durch einen Klick auf die Kachel gelangt man in der App auf die
              eingetragene URL. Überschrift, Text und Bilder müssen hierbei
              nicht gepflegt werden.
            </Form.HelpText>
            <Form.Control
              autoComplete="off"
              name="externalUrl"
              value={formValue.externalUrl}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Kachelbild</Form.ControlLabel>
            <Form.HelpText>
              Wenn Sie ein neues Bild hochladen, ersetzen Sie somit das alte
              sobald Sie speichern drücken.
            </Form.HelpText>
            <Form.Control
              accepter={Uploader}
              accept="image/png, image/jpeg"
              action={"https://physiodev.salestv.de/uploadBlogPostImage"}
              // action={"http://localhost:8888/uploadBlogPostImage"}
              onSuccess={onSuccessHandler}
            />
            <img
              src={formValue.tileBackgroundImageUrl}
              width={200}
              alt=""
            ></img>
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Bilder</Form.ControlLabel>
            <Form.HelpText>
              Neu hochgeladene Bilder werden erst nach dem Speichervorgang
              angezeigt.
            </Form.HelpText>

            {formValue.images.length > 0 && (
              <>
                <Stack>
                  {formValue.images.map((image) => {
                    return (
                      <Panel
                        bordered
                        header={
                          <Stack justifyContent="space-between">
                            <span></span>
                            <IconButton
                              icon={<TrashIcon />}
                              circle
                              color="red"
                              appearance="primary"
                              size="sm"
                              onClick={async () => {
                                let imageData =
                                  await startPageImageTileApi.apiStartPageTileImageGet();
                                for (
                                  let i = 0;
                                  i < imageData.data.data.length;
                                  i++
                                ) {
                                  if (
                                    imageData.data.data[i].imageId == image.id
                                  ) {
                                    let d =
                                      await startPageImageTileApi.apiStartPageTileImageIdDelete(
                                        imageData.data.data[i].id
                                      );
                                    getTileData();
                                    break;
                                  }
                                }
                                // console.log(d)
                              }}
                            />
                          </Stack>
                        }
                      >
                        <img
                          src={image.url}
                          style={{ maxHeight: 150, maxWidth: 150 }}
                        ></img>
                      </Panel>
                    );
                  })}
                </Stack>
              </>
            )}

            <Uploader
              action={"https://physiodev.salestv.de/uploadBlogPostImage"}
              draggable
              onSuccess={onMultipleUploadSuccessHandler}
              accept="image/png, image/jpeg"
            >
              <div
                style={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Klick oder ziehen Sie die Dateien in diesen Bereich.
                </span>
              </div>
            </Uploader>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button appearance="primary" color="green" onClick={onSubmitHandler}>
            Speichern
          </Button>
          <Button appearance="primary" color="red" onClick={props.handleClose}>
            Abbrechen
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}
export default StartTileEditDrawer;

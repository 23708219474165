import { Table, IconButton, Divider } from "rsuite";
import { Edit, Trash } from "@rsuite/icons";
import React from "react";
import { getOffset } from "rsuite/esm/DOMHelper";

function UserList(props) {
  const data = props.data;
  const nodeRef = React.useRef();
  const canDelete = true;

  function selectUser(userId) {
    props.selectUser(userId);
  }
  function deleteUser(userId, name) {
    props.deleteUser(userId, name);
  }

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    function handleAction() {
      selectUser(rowData[dataKey]);
    }

    function handleDelete() {
      console.log(rowData.username);
      deleteUser(rowData[dataKey], rowData.username);
    }
    return (
      <Table.Cell {...props} className="link-group">
        <IconButton
          appearance="primary"
          color="green"
          size="sm"
          onClick={handleAction}
          icon={<Edit />}
        />

        <React.Fragment>
          <Divider vertical={true}></Divider>
          <IconButton
            appearance="primary"
            color="red"
            size="sm"

            onClick={handleDelete}
            icon={<Trash />}
          />
        </React.Fragment>
      </Table.Cell>
    );
  };

  return (
    <div
      id={props.id}
      ref={nodeRef}
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
      }}
    >
      <Table
       loading={props.loading}
        height={window.innerHeight - getOffset(nodeRef.current).top}
        showHeader={true}
        data={data}
        id="table"
        virtualized
        rowHeight={56}
        className="striped-table"
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.Cell dataKey="username" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Vorname</Table.HeaderCell>
          <Table.Cell dataKey="firstname" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Project</Table.HeaderCell>
          <Table.Cell dataKey="pname" />
        </Table.Column>
        <Table.Column width={canDelete ? 130 : 65}>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <ActionCell dataKey="id" />
        </Table.Column>
      </Table>
    </div>
  );
  /*
  const canDelete=props.canDeleteUser
  

  const currentUser = props.currentUser;





  */
}

export default UserList;

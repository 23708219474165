import { async } from "@firebase/util";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Message, toaster } from "rsuite";
import DeleteModal from "../Components/Layout/DeleteModal";
import SearchTree from "../Components/Layout/SearchTree";
import UserForm from "../Components/User/UserForm";
import UserList from "../Components/User/UserList";
import practiceApi from "../services/PracticeApi";
import userApi from "../services/UserApi";
import UserNavigation from "../Components/User/UserNavigation";

function User(props) {
  const currentUser = props.user;
  const role = JSON.parse(localStorage.getItem("role"));
  const navigation = useLocation();
  const [firstTime, setFirstTime] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);

  let temp = [];
  let temp2 = [];
  let counter = 0;
  const practiceId = localStorage.getItem("practiceId");

  async function getUserData() {
    setLoading(true);
    const locationData = await practiceApi.apiPracticeGet();
    const userData = await userApi.apiUserGet();
    setFirstTime(true);

    locationData.data.data.push({ id: null, name: "Nicht zugeordnet" });
    for (let i = 0; i < locationData.data.data.length; i++) {
      let users = [];
      for (let j = 0; j < userData.data.data.length; j++) {
        if (
          userData.data.data[j].practiceId === locationData.data.data[i].id &&
          role == 3
        ) {
          temp2.push({
            project_id: locationData.data.data[i].id,
            pname: locationData.data.data[i].name,
            username: userData.data.data[j].email,
            firstname: userData.data.data[j].firstName,
            name: userData.data.data[j].lastName,
            id: userData.data.data[j].id,
          });
          users.push({
            label: userData.data.data[j].email,
            value: counter,
            child_id: userData.data.data[j].id,
          });
          counter++;
          //  user.
        } else if (
          userData.data.data[j].practiceId === locationData.data.data[i].id &&
          practiceId == userData.data.data[j].practiceId
        ) {
          temp2.push({
            project_id: locationData.data.data[i].id,
            pname: locationData.data.data[i].name,
            username: userData.data.data[j].email,
            firstname: userData.data.data[j].firstName,
            name: userData.data.data[j].lastName,
            id: userData.data.data[j].id,
          });
          users.push({
            label: userData.data.data[j].email,
            value: counter,
            child_id: userData.data.data[j].id,
          });
          counter++;
        }
      }
      if (role == 3) {
        temp.push({
          label: locationData.data.data[i].name + " (" + users.length + ")",
          name: locationData.data.data[i].name,
          value: counter,
          parent_id: locationData.data.data[i].id,
          children: users,
        });
        counter++;
      } else if (practiceId == locationData.data.data[i].id) {
        temp.push({
          label: locationData.data.data[i].name + " (" + users.length + ")",
          name: locationData.data.data[i].name,
          value: counter,
          parent_id: locationData.data.data[i].id,
          children: users,
        });
        counter++;
      }
    }
    setLoading(false);
    setUserList(temp);
    setSelectedUserList(temp2);
    setSelectedUser(null);
  }

  useEffect(() => {
    setShowAdd(false);
    getUserData();
  }, [navigation.state.from]);

  function deleteUser(userId, name) {
    console.log(name);
    async function onDeleteUser() {
      var d = await userApi.apiUserIdDelete(userId);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Benutzer erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
        handleClose();
        getUserData();
      }
    }
    setOpen(true);
    let text =
      "Sind Sie sich sicher, dass sie den Benutzer " +
      name +
      " löschen möchten?";
    setModal(
      <DeleteModal
        header="Benutzer löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={onDeleteUser}
      ></DeleteModal>
    );
  }

  async function onSelect(userId) {
    const user = await userApi.apiUserIdGet(userId);
    if (user.status === 200) {
      setFirstTime(false);
      setSelectedUserList(null);
      setSelectedUser(user.data);
    }
  }

  async function onChangeHandler(activeNode, value, event) {
    if (activeNode.children) {
      setFirstTime(false);
      let users = [];

      const userData = await userApi.apiUserGet();
      for (let j = 0; j < userData.data.data.length; j++) {
        if (userData.data.data[j].practiceId === activeNode.parent_id) {
          temp2.push({
            project_id: activeNode.parent_id,
            pname: activeNode.parent_id.name,
            username: userData.data.data[j].email,
            firstname: userData.data.data[j].firstName,
            name: userData.data.data[j].lastName,
            id: userData.data.data[j].id,
          });
          counter++;
        }
      }
      setSelectedUserList(temp2);
      setSelectedUser(null);
    } else {
      onSelect(activeNode.child_id);
    }
  }

  function onCreateNew() {
    setFirstTime(false);
    setSelectedUserList(null);
    setSelectedUser(0);
  }

  function onClean() {
    setFirstTime(true);
    setSelectedUserList(null);
    setSelectedUser(null);

    getUserData();
  }
  async function onEdit(data, userId, perms) {
    const u = await userApi.apiUserIdPut(userId, data);
    console.log(u);

    if (u.status == 200) {
      toaster.push(
        <Message showIcon type="success">
          Benutzer erfolgreich editiert.
        </Message>,
        "topCenter"
      );
      getUserData();
    }
  }

  async function onSubmitNew(data, createStuff) {
    var u;
    if (createStuff == true) {
      u = await userApi.apiUserCreateStaffPost(data);
    } else {
      u = await userApi.apiUserPost(data);
    }

    if (u.status == 201) {
      toaster.push(
        <Message showIcon type="success">
          Benutzer erfolgreich erstellt.
        </Message>,
        "topCenter"
      );
      getUserData();
    } else {
      toaster.push(
        <Message showIcon type="success">
          {u.statusText}
        </Message>,
        "topCenter"
      );
      getUserData();
    }
  }
  function handleOnAdd() {
    setOpenWithHeader(true);
  }
  return (
    <React.Fragment>
      <Divider></Divider>
      <SearchTree
        selectedItem={selectedUser}
        currentUser={currentUser}
        dataList={userList}
        selectedList={selectedUserList}
        onChangeHandler={onChangeHandler}
        canEdit={true}
        onCreateNew={onCreateNew}
        onClean={onClean}
        firstTime={firstTime}
        mode="children"
        showAdd={showAdd}
        onAddClick={handleOnAdd}
      ></SearchTree>
      {selectedUserList && (
        <UserList
          data={selectedUserList}
          loading={loading}
          canDeleteUser={true}
          selectUser={(userId) => onSelect(userId)}
          deleteUser={(userId, name) => deleteUser(userId, name)}
        ></UserList>
      )}
      {selectedUser != null && (
        <UserNavigation
          onCancel={onClean}
          currentUser={currentUser}
          user={selectedUser}
          onSubmitNew={(data, perms) => onSubmitNew(data, perms)}
          onEdit={(data, userId, perms) => onEdit(data, userId, perms)}
          showAddB={(value) => setShowAdd(value)}
          openWithHeader={openWithHeader}
          onCloseModal={() => setOpenWithHeader(false)}
        ></UserNavigation>
        /*
        <UserForm
          onCancel={onClean}
          currentUser={currentUser}
          user={selectedUser}
          onSubmitNew={(data, perms) => onSubmitNew(data, perms)}
          onEdit={(data, userId, perms) => onEdit(data, userId, perms)}
        ></UserForm>*/
      )}
      {open && modal}
    </React.Fragment>
  );
}
export default User;

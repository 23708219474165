import React from "react";
import { Divider, IconButton, Table } from "rsuite";
import { Edit, Trash } from "@rsuite/icons";
import { getOffset } from "rsuite/esm/DOMHelper";

function CouchingplanList(props) {
    const data = props.data;
    const nodeRef = React.useRef();
  
    function onSelect(data) {
      props.onSelect(data);
    }
  
    function onDelete(id, name) {
      props.onDelete(id, name);
    }
  
    const ActionCell = ({ rowData, dataKey, ...props }) => {
      function handleAction() {
        onSelect(rowData[dataKey]);
      }
      function handleDelete() {
        onDelete(rowData[dataKey], rowData.label);
      }
      return (
        <Table.Cell {...props} className="link-group">
          <IconButton
            size="sm"
            appearance="primary"
            color="green"
            onClick={handleAction}
            icon={<Edit />}
          />
          <Divider vertical={true}></Divider>
          <IconButton
            size="sm"
            appearance="primary"
            color="red"
            onClick={handleDelete}
            icon={<Trash />}
          />
        </Table.Cell>
      );
    };
    return (
      <div
        id={props.id}
        ref={nodeRef}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Table
          loading={props.loading}
          height={window.innerHeight - getOffset(nodeRef.current).top}
          bordered={true}
          virtualized
          showHeader={true}
          className="striped-table"
          data={data}
          id="table"
          rowHeight={56}
         // onRowClick={(data) => onSelect(data.parent_id)}
        >
          <Table.Column flexGrow={2}>
            <Table.HeaderCell>Coachingplan</Table.HeaderCell>
            <Table.Cell dataKey="text" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Anzahl übungen</Table.HeaderCell>
            <Table.Cell dataKey="exercises" />
          </Table.Column>
  
  
          <Table.Column width={131}>
            <Table.HeaderCell>Aktion</Table.HeaderCell>
            <ActionCell dataKey="parent_id" />
          </Table.Column>
        </Table>
      </div>
    );
  }
export default CouchingplanList;

import { createSlice } from "@reduxjs/toolkit";
import { Message, toaster } from "rsuite";

const initialState = {};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return {
        message: toaster.push(
          <Message showIcon type="error">
            {action.payload}
          </Message>,
          "bottomCenter"
        ),
      };
    },
    clearMessage: () => {
      return { message: "" };
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;

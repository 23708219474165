import url from "./url";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { Message, toaster } from "rsuite";
import { setBaseOptions } from "./http_service";
import createToast from "../Components/Layout/Toast";
import createErrorToast from "../Components/Layout/ErrorToast";
import userApi from "./UserApi";

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
  apiKey: "AIzaSyA5AW2crC0NTRyG-RblTd8JFnTmbuzfKmc",
  authDomain: "lb-physio-test.firebaseapp.com",
  projectId: "lb-physio-test",
  storageBucket: "lb-physio-test.appspot.com",
  messagingSenderId: "453762483999",
  appId: "1:453762483999:web:1eea2a5dc975ad46eb522a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const login = async (email, password) => {
  await setPerstance();
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const tokenresult = await userCredential.user.getIdTokenResult(true);
      const role = tokenresult.claims.role;
      // Signed in
      //console.log(userCredential);

      //1=normal
      //2=praxis
      //3=global

      if (role > 1) {
        const user = userCredential.user;
        if (user) {
          const userData = await userApi.apiUserCurrentGet();
          localStorage.setItem("practiceId", userData.data.practiceId);

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("role", JSON.stringify(role));
        }

        return user;
      } else {
        toaster.push(
          <Message showIcon type="error">
            Sie verfügen nicht über die nötigen Rechte!
          </Message>
        );
        return;
      }

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};

const signUp = async (email, password) => {
  var signUpData = createUserWithEmailAndPassword(auth, email, password).catch(
    function (error) {
      if (error.code == "auth/email-already-in-use")
        createErrorToast("Email bereits in Benutzung");
      return;
    }
  );
  return signUpData;
};

const logout = () => {
  auth.signOut();
};
async function setPerstance() {
  return setPersistence(auth, browserLocalPersistence);
}
async function getIdResult(userCredential) {
  const tokenresult = await userCredential.getIdTokenResult(true);
  const role = tokenresult.claims.role;

  return role;
}

const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await auth.currentUser.getIdToken(user);
        resolve(token);
      } else {
        resolve(null);
      }
      unsub();
    });
  });
};

const getUser = async () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
};
async function resetPasswort(email) {
  return await sendPasswordResetEmail(auth, email);
}
const authService = {
  login,
  logout,
  signUp,
  getIdResult,
  setPerstance,
  getUserToken,
  getUser,
  resetPasswort,
};

export default authService;

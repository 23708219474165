import { Button, Modal } from "rsuite";

function DeleteModal(props) {


  return (
    <Modal size="xs" open={true} onClose={props.handleClose}>
      <Modal.Header>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onConfirm} color="red" appearance="primary">
          Löschen
        </Button>
        <Button onClick={props.handleClose} appearance="subtle">
          Abbrechen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteModal;

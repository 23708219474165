import { useEffect, useRef, useState } from "react";
import documentApi from "../../services/DocumentApi";
import documentCategoryApi from "../../services/DocumentCategoryApi";
import { getOffset } from "rsuite/esm/DOMHelper";
import { Divider, IconButton, Message, Modal, SelectPicker, Table, toaster, Uploader } from "rsuite";
import helper from "../../services/helper";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import UserDocumentUpload from "./UserDocumentUpload";
import TrashIcon from "@rsuite/icons/Trash";
import DeleteModal from "../Layout/DeleteModal";
function UserDocuments(props) {
  const [documentData, setDocumentData] = useState();
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(null);

  const nodeRef = useRef();
  const handleClose = () => setOpen(false);

  async function getDocuments() {
    const query = await documentApi.apiDocumentUserUserIdGet(props.user.id);
    const cats = await documentCategoryApi.apiDocumentCategoryGet();

    let data = [];
    for (let i = 0; i < query.data.data.length; i++) {
      let temp = {};
      for (let j = 0; j < cats.data.data.length; j++) {
        if (query.data.data[i].documentCategoryId == cats.data.data[j].id) {
          temp = { ...query.data.data[i], cat: cats.data.data[j].name };
          data.push(temp);
        }
      }
    }
    setDocumentData(data);
  }

  useEffect(() => {
    getDocuments();
  }, []);

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    function handleDelete() {
      onDelete(rowData[dataKey], rowData.documentName);
    }
    return (
      <Table.Cell {...props} className="link-group">
        <IconButton
          appearance="primary"
          color="green"
          size="sm"
          onClick={async () => {
            const asd = await documentApi.apiDocumentDownloadDocumentIdGet(
              rowData.id,
              { responseType: "blob" }
            );

            const url = window.URL.createObjectURL(new Blob([asd.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", rowData.filename);
            // / Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }}
          icon={<FileDownloadIcon />}
        />
          <Divider vertical={true}></Divider>
        <IconButton
          appearance="primary"
          color="red"
          size="sm"
          onClick={handleDelete}
          icon={<TrashIcon />}
          /*          onClick={async () => {


            
        //    const asd = await documentApi.apiDocumentIdDelete(rowData.id);

          }}
         }*/
        />
      </Table.Cell>
    );
  };

  async function onDelete(id, name) {
    async function deletePlan() {
      var d = await documentApi.apiDocumentIdDelete(id);
      if (d.status == 204) {
        toaster.push(
          <Message showIcon type="success">
            Dokument erfolgreich gelöscht.
          </Message>,
          "topCenter"
        );
         handleClose();
         getDocuments()
      }
    }
    setOpen(true);
    let text =
      "Sind Sie sich sicher, dass sie das Dokument " +
      name +
      " löschen möchten?";
    setModal(
      <DeleteModal
        header="Dokument löschen"
        body={text}
        handleClose={handleClose}
        onConfirm={deletePlan}
      ></DeleteModal>
    );
  }

  return (
    <>
      <div
        ref={nodeRef}
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "100%",
        }}
      >
        {" "}
        <Table
          height={window.innerHeight - getOffset(nodeRef.current).top}
          showHeader={true}
          data={documentData}
          id="table"
          virtualized
          rowHeight={56}
          className="striped-table"
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Dateiname</Table.HeaderCell>
            <Table.Cell dataKey="documentName" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Kategorie</Table.HeaderCell>
            <Table.Cell dataKey="cat" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Uploaddatum</Table.HeaderCell>
            <Table.Cell dataKey="createdAt">
              {(rowData) => {
                return helper.getDateISO(rowData.createdAt);
              }}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={135}>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <ActionCell dataKey="id" />
          </Table.Column>
        </Table>
      </div>
      {props.openWithHeader && (
        <UserDocumentUpload
          user={props.user.id}
          onCloseModal={props.onCloseModal}
          reload={() => {
            props.onCloseModal();
            getDocuments();
          }}
        ></UserDocumentUpload>
      )}
        {open && modal}
    </>
  );
}
export default UserDocuments;

import { Table } from "rsuite";

function TextCell({ rowData, dataKey, rowClickHandler, ...cellValues }) {
  function onRowClick(e) {
    rowClickHandler(rowData);
  }
  let toShow;
  if (dataKey === "exercise.headline") {
    toShow = rowData.exercise.headline;
  }
  else{
    toShow=rowData[dataKey]===null?0:rowData[dataKey]
  }
 /* if(dataKey === "exercise.set") {
    toShow = rowData.exercise.headline;
  }*/
  return (
    <Table.Cell {...cellValues} onClick={onRowClick}>
      {toShow}
    </Table.Cell>
  );
}
export default TextCell;

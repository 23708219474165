

function timeFormat(time) {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;

  let newTime = "";
  if (String(hours).length === 1) {
    newTime = "0" + String(hours);
  } else {
    newTime = String(hours);
  }

  if (String(minutes).length === 1) {
    newTime += ":0" + String(minutes);
  } else {
    newTime += ":" + String(minutes);
  }

  return newTime;
}
function weekdaysFormat(days) {
  let weekdays = "";
  if (days.indexOf("1") !== -1) {
    weekdays += "Mo ";
  } else {
    weekdays += "   ";
  }

  if (days.indexOf("2") !== -1) {
    weekdays += "Di ";
  } else {
    weekdays += "   ";
  }
  if (days.indexOf("3") !== -1) {
    weekdays += "Mi ";
  } else {
    weekdays += "   ";
  }
  if (days.indexOf("4") !== -1) {
    weekdays += "Do ";
  } else {
    weekdays += "   ";
  }
  if (days.indexOf("5") !== -1) {
    weekdays += "Fr ";
  } else {
    weekdays += "   ";
  }
  if (days.indexOf("6") !== -1) {
    weekdays += "Sa ";
  } else {
    weekdays += "   ";
  }
  if (days.indexOf("7") !== -1) {
    weekdays += "So ";
  } else {
    weekdays += "   ";
  }
  return weekdays;
}

function getDate(text) {
  const hilfe=  text.split(".")
  const date = new Date(hilfe[1]+"-"+hilfe[0]+"-"+hilfe[2]);
  console.log(date)
  return date;
}
function getDate2(text) {
  console.log(text)
  const hilfe=  text.split("-")
  const date = new Date(text);
  console.log(date)
  return date;
}
function getDateISO(text) {
  console.log(text)
  const hilfe=  text.split("-")
  const date = new Date(text);
  console.log(date)
  return date.toLocaleDateString();
}
function getTime(text) {
  const date = new Date();
  if (text === 1440) {
    date.setHours(24);
    date.setMinutes(0);
  } else {
    const hours = text / 60;
    const min = text % 60;
    date.setHours(hours);
    date.setMinutes(min);
  }
  return date;
}

function timeStampToDate(timestamp) {
  var date = new Date(timestamp * 1000);
  // Hours part from the timestamp
  var hours = "0" + date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  var formattedTime =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).substr(-2) +
    "/" +
    ("0" + date.getDate()).substr(-2) +
    " - " +
    hours.substr(-2) +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);
  return formattedTime;
}

function getNowTimeStamp() {
  return Date.now() / 1000;
}
function getDateFormated(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (String(month).length === 1) {
    month = "0" + month;
  }
  let day = date.getDate();
  if (String(day).length === 1) {
    day = "0" + day;
  }

  return year + "/" + month + "/" + day;
}
const sort = (source, sourceId, targetId) => {
  const nextData = source.filter((item) => item.id !== sourceId);
  const dragItem = source.find((item) => item.id === sourceId);
  const index = nextData.findIndex((item) => item.id === targetId);

  const sourceIndex = source.findIndex((item) => item.id === sourceId);
  const targetIndex = nextData.findIndex((item) => item.id === targetId);

  let increment;

  if (sourceIndex > targetIndex) {
    increment = 0;
  } else {
    increment = 1;
  }

  nextData.splice(index + increment, 0, dragItem);
  return nextData;
};

const sortPlaylist = (source, sourceId, targetId) => {
  const nextData = source.filter((item) => item.positionId !== sourceId);
  const dragItem = source.find((item) => item.positionId === sourceId);
  const index = nextData.findIndex((item) => item.positionId === targetId);

  const sourceIndex = source.findIndex((item) => item.positionId === sourceId);
  const targetIndex = nextData.findIndex(
    (item) => item.positionId === targetId
  );

  let increment;

  if (sourceIndex > targetIndex) {
    increment = 0;
  } else {
    increment = 1;
  }

  nextData.splice(index + increment, 0, dragItem);
  return nextData;
};

function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}

function getData(data, sortColumn, sortType) {
  if (sortColumn && sortType) {
    return data.sort((a, b) => {
      let x = a[sortColumn];
      let y = b[sortColumn];
      if (typeof x === "string") {
        x = x.charCodeAt();
      }
      if (typeof y === "string") {
        y = y.charCodeAt();
      }
      if (sortType === "asc") {
        return x - y;
      } else {
        return y - x;
      }
    });
  }
  return data;
}
const helper = {
  timeFormat: timeFormat,
  weekdaysFormat: weekdaysFormat,
  getDateISO:getDateISO,
  getDate: getDate, getDate2: getDate2,
  getTime: getTime,
  timeStampToDate: timeStampToDate,
  getNowTimeStamp: getNowTimeStamp,
  getDateFormated: getDateFormated,
  sort: sort,
  getRandom: getRandom,
  sortPlaylist: sortPlaylist,
  getData: getData,
};
export default helper;

import { Checkbox, Table } from "rsuite";

function CheckCell({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  lineheight,
  ...cellValues
}) {
  return (
    <Table.Cell {...cellValues} style={{ padding: 0 }}>
      <div
        style={{
          lineHeight: lineheight ? lineheight : "75px",
          marginTop: "auto",
        }}
      >
        <Checkbox
          value={rowData[dataKey]}
          inline
          onChange={onChange}
          checked={checkedKeys.some((item) => item === rowData[dataKey])}
        />
      </div>
    </Table.Cell>
  );
}

export default CheckCell;

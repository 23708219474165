import axios from "axios";
import { Configuration } from "../openapi";
import authService from "./auth.service";
import createErrorToast from "../Components/Layout/ErrorToast";
const user = JSON.parse(localStorage.getItem("user"));
const openApiConfig = new Configuration();
openApiConfig.basePath = "https://physiodev.salestv.de";


axios.interceptors.request.use(async (req) => {
  const token = await authService.getUserToken();

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  } else {
  }
  return req;
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    createErrorToast(error);
    return Promise.reject(error);
  }
);





export default openApiConfig;
